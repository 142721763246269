<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">แก้ไขข้อมูล</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="12"
            >
              <v-text-field
                v-model="data.stockNumber"
                label="เลขที่ใบเสร็จ"
                color="teal"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="12"
            >
              <v-text-field
                v-model="data.price"
                label="ราคา"
                color="teal"
                type="number"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          dark
          @click="close()"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="teal"
          dark
          @click="Confirm()"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import { sync, call } from 'vuex-pathify'
export default {
  name: 'DialogEditStock',
  computed: {
    dialog: sync('stockDetail/dialogEditStock'),
    data: sync('stockDetail/stockDetail'),
    snackbars: sync('product/snackbar')
  },
  created() {
    this.checkinit()
  },
  mounted() {

  },
  methods: {
    ...call('stockDetail', ['saveEditStock', 'initEditStock', 'getStock', 'getStockList']),
    Confirm() {
      this.saveEditStock().then((response) => {
        if (response != null) {
          this.getStockList()
          this.dialog = false
          this.snackbars = true
          this.$router.push('/manageStock')
        }
      })
      this.initEditStock()
    },
    close() {
      this.dialog = false
      this.initEditStock()
    },
    async checkinit() {
      await this.initEditStock()
      // await this.getStock()
    }
  }
}
</script>
