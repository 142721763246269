<template>
  <v-dialog v-model="dialog" max-width="350px">
    <v-card>
      <v-card-title class="text-h5">คุณต้องการลบข้อมูลหรือไม่</v-card-title>
      <v-card-actions>
        <v-spacer />

        <v-btn color="grey" dark @click="closeDelete">ยกเลิก</v-btn>
        <v-btn color="red darken-4" dark @click="deleteItemConfirm">ลบ</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync, call } from 'vuex-pathify'
export default {
  name: 'DialogDelete',
  computed: {
    dialog: sync('product/dialogDelete'),
    snackbar: sync('stock/snackbar')
  },
  methods: {
    ...call('product', ['deleteProduct', 'getProducts']),
    deleteItemConfirm() {
      this.deleteProduct().then((response) => {
        if (response.id !== 0) {
          this.getProducts()
          this.dialog = false
          this.snackbar = true
        }
      })
    },
    closeDelete() {
      this.dialog = false
    }
  }
}
</script>
