import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ManageProduct from '../views/manageProduct/ProductView.vue'
import ManageStock from '../views/manageStock/StockView.vue'
import OrderView from '../views/order/OrderView.vue'
import SetupMenusView from '../views/setup/SetupMenusView.vue'
import CreateOrderForm from '../components/orders/CreateOrderForm.vue'
import PickingList from '../components/picking-list/PickingList.vue'
import SummaryReport from '../components/report/SummaryReport.vue'
import ReportView from '../views/report/ReportView.vue'
import SetupCompany from '../views/setup/menu/SetupCompany.vue'
import SetupDepartment from '../views/setup/menu/SetupDepartment.vue'
import SetupUnit from '../views/setup/menu/SetupUnit.vue'
import EditStockForm from '../components/stock/EditStockForm.vue'
import ApproveOrderForm from '../components/orders/ApproveOrderForm.vue'
import CreateStockForm from '../components/stock/CreateStockForm.vue'
import EditOrderForm from '../components/orders/EditOrderForm.vue'
import ProductReport from '../components/report/ProductReport.vue'
import ProductReportMonthly from '../components/report/ProductReportMonthly.vue'
import InventoryReport from '../components/report/InventoryReport.vue'
import CompleteOrderForm from '../components/orders/CompleteOrderForm.vue'
import SumaryOrderForm from '../components/orders/SumaryOrderForm.vue'
import LoginPage from '../views/LoginPage.vue'
import DefultLayout from '../layout/default-layout'
import Repack from '../components/repack/repack.vue'
import RepackList from '../components/repack/repackList.vue'
import SummaryDepartmentReport from '../components/report/SummaryDepartmentReport.vue'
import jwt_decode from 'jwt-decode'

export const isAuthenticated = () => {
  return !!(
    localStorage.getItem('id_token') && localStorage.getItem('access_token')
  )
}

export const getUser = () => {
  const user = jwt_decode(localStorage.getItem('id_token') ?? '')
  return user
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
    component: DefultLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: '/manageProduct',
        name: 'manageProduct',
        component: ManageProduct
      }, {
        path: '/manageStock',
        name: 'manageStock',
        component: ManageStock
      }, {
        path: '/setup',
        name: 'setupMenus',
        component: SetupMenusView
      }, {
        path: '/orderView',
        name: 'OrderView',
        component: OrderView
      }, {
        path: '/actionOrder/:id?/:role?',
        name: 'actionOrder',
        component: CreateOrderForm,
        alias: '/actionOrder/:id/:role'
      }, {
        path: '/approveOrderForm/:id?',
        name: 'ApproveOrderForm',
        component: ApproveOrderForm,
        alias: '/approveOrderForm/:id'
      }, {
        path: '/completeOrderForm/:id?',
        name: 'CompleteOrderForm',
        component: CompleteOrderForm,
        alias: '/completeOrderForm/:id'
      }, {
        path: '/sumaryOrderForm/:id?',
        name: 'SumaryOrderForm',
        component: SumaryOrderForm,
        alias: '/sumaryOrderForm/:id'
      }, {
        path: '/pickingList',
        name: 'pickingList',
        component: PickingList
      }, {
        path: '/summaryReport',
        name: 'summaryReport',
        component: SummaryReport
      }, {
        path: '/setupCompany',
        name: 'SetupCompany',
        component: SetupCompany
      }, {
        path: '/setupDepartment',
        name: 'SetupDepartment',
        component: SetupDepartment
      }, {
        path: '/setupUnit',
        name: 'SetupUnit',
        component: SetupUnit
      }, {
        path: '/editStockForm/:id?',
        name: 'EditStockForm',
        component: EditStockForm,
        alias: '/editStockForm/:id'
      }, {
        path: '/createStockForm',
        name: 'CreateStockForm',
        component: CreateStockForm
      }, {
        path: '/editOrderForm/:id',
        name: 'EditOrderForm',
        component: EditOrderForm
      }, {
        path: '/report',
        name: 'Report',
        component: ReportView
      }, {
        path: '/productReport',
        name: 'ProductReport',
        component: ProductReport
      }, {
        path: '/productReportMonthly',
        name: 'ProductReportMonthly',
        component: ProductReportMonthly
      }, {
        path: '/inventoryReport',
        name: 'InventoryReport',
        component: InventoryReport
      }, {
        path: '/repack',
        name: 'Repack',
        component: Repack
      }, {
        path: '/repackList',
        name: 'RepackList',
        component: RepackList
      },
      {
        path: '/summaryDepartmentReport',
        name: 'SummaryDepartmentReport',
        component: SummaryDepartmentReport
      }
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, __, next) => {
  if (to.name !== 'login' && !isAuthenticated()) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
