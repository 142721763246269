import request from '@/util/request'

const api = {
  getStockDetails: (billId) => {
    return request({
      url: `/StockDetails?billId=${billId}`,
      method: 'get'
    })
  },
  getUnits: () => {
    return request({
      url: '/StockDetails/unit',
      method: 'get'
    })
  },
  getStockDetailInBill: (productId, stockNumber) => {
    return request({
      url: `/StockDetails/InBill?productId=${productId}&BillNumber=${stockNumber}`,
      method: 'get'
    })
  },
  getStockDetailType: (id) => {
    return request({
      url: `/StockDetails/checkStockDetailType/${id}`,
      method: 'get'
    })
  },
  getStockDetail: (productId, stockNumber) => {
    return request({
      url: `/StockDetails/StockDetail?productId=${productId}&BillNumber=${stockNumber}`,
      method: 'get'
    })
  },
  postStockDetails: (data) => {
    return request({
      url: '/StockDetails',
      method: 'post',
      data: data
    })
  },
  putStockDetails: (id, data) => {
    return request({
      url: `/StockDetails/${id}`,
      method: 'put',
      data: data
    })
  },
  deleteStockDetail: (id) => {
    return request({
      url: `/StockDetails/deleteStockDetails/${id}`,
      method: 'put'
    })
  },
  saveEditStockDetail: (stockDetail) => {
    return request({
      url: `/StockDetails/SaveEditStockDetail/`,
      method: 'put',
      data: stockDetail
    })
  },
  getInventoryReport: (keyWord) => {
    return request({
      url: `/Stocks/getInventory?keyWord=${keyWord}`,
      method: 'get'
    })
  }
}
export default api
