import request from '@/util/request'

const api = {
  checkOrderDetail: (id, data) => {
    return request({
      url: `/OrderDetails/checkOrderDetail/${id}`,
      method: 'put',
      data: data
    })
  },
  approveOrderDetail: (id, data) => {
    return request({
      url: `/OrderDetails/approveOrderDetail/${id}`,
      method: 'put',
      data: data
    })
  },
  approveAllOrderDetail: (data) => {
    return request({
      url: `/OrderDetails/approveAllOrderDetail`,
      method: 'put',
      data: data
    })
  }
}
export default api
