import { make } from 'vuex-pathify'
import api from '@/api/stock'

const state = {
  keyword: '',
  stocks: [],
  dialogDelete: false,
  dialogCreateForm: false,
  dialogEditForm: false,
  id: 0,
  snackbar: false,
  loading: false,
  stock: [],
  stockDate: null,
  stockDetails: [],
  type: 2,
  stockNumber: '',
  date: [],
  lastModifiedBy: '',
  item: null,
  status: 0

}

const mutations = make.mutations(state)

const actions = {
  initStock({ state }) {
    state.stock = {
      companyId: 0,
      totalPrice: 0,
      vat: false
    }
  },
  getStockList({ state }, payload) {
    const data = {
      startDate: state.date[0] + ',00:00:00',
      endDate: state.date[1] + ',23:59:59'
    }
    state.loading = true
    api.getStocks(state.keyword, data.startDate, data.endDate, payload.page, payload.itemsPerPage, payload.status).then((response) => {
      state.stocks = response.data
      state.loading = false
    })
  },
  getStock({ state }, id) {
    return new Promise((resolve) => {
      api.getStock(id).then((response) => {
        state.stock = response.data
        resolve(response.data)
      })
    })
  },
  deleteStock({ state }) {
    return new Promise((resolve) => {
      api.deleteStock(state.id).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  saveStock({ state }) {
    const data = {
      companyId: state.stock.companyId,
      stockNumber: state.stock.stockNumber,
      totalPrice: state.stock.totalPrice,
      stockDate: state.stockDate,
      vat: state.stock.vat,
      stockDetails: state.stockDetails,
      status: state.status
    }
    return new Promise((resolve) => {
      api.postStocks(data).then((response) => {
        resolve(response.data)
      })
    })
  },
  editStock({ state }) {
    const data = {
      id: state.id,
      companyId: state.stock.companyId,
      stockNumber: state.stock.stockNumber,
      totalPrice: state.stock.totalPrice,
      stockDate: state.stockDate,
      vat: state.stock.vat,
      stockDetails: state.stockDetails,
      status: state.status
    }
    return new Promise((resolve) => {
      api.putStocks(state.id, data).then((response) => {
        resolve(response.data)
      })
    })
  },
  // getStock({ state }) {
  //   api.getStock(state.productId, state.stockNumber).then((response) => {
  //     state.stockDetail = response.data
  //   })
  // },
  getStockInStock({ state }) {
    api.getStockInStock(state.productId, state.stockNumber).then((response) => {
      state.stockInStock = response.data
    })
  },
  saveEditStock({ state }) {
    return new Promise((resolve) => {
      api.saveEditStock(state.stockDetail).then((response) => {
        resolve(response.data)
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
