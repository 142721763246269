<template>
  <v-container>
    <v-card-title>
      <div class="text-h5">
        เบิกวัสดุการแพทย์
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="teal"
          class="ma-2 dark--text"
          dark
          @click="createOrder()"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          สร้างใบเบิกใหม่
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-row>
        <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          label="ค้นหา"
          single-line
          hide-details
          color="teal"
          class="pr-5 mr-15"
        />
      </v-row>

      <v-row>
        <v-col
          cols="15"
          lg="4"
          class="d-flex align-center"
        >
          <v-menu
            ref="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="วันที่"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                color="teal"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              color="teal"
              no-title
              locale="th"
              range
              @change="onSearch()"
            />
          </v-menu>
        </v-col>
        <v-col
          class="d-flex align-center "
          lg="3"
        >
          <v-autocomplete
            v-model="departmentId"
            color="teal"
            :items="departments"
            label="แผนก"
            item-text="departmentName"
            item-value="id"
            @change="onSearch()"
          />
        </v-col>
        <v-col
          class="d-flex align-center"
          lg="3"
        >
          <v-select
            v-model="statusId"
            color="teal"
            :items="status"
            label="สถานะ"
            item-text="statusName"
            item-value="id"
            @change="onSearch()"
          />
        </v-col>
        <v-col
          class="d-flex align-center justify-end"
          lg="2"
        >
          <v-btn
            color="teal"
            dark
            @click="onInit()"
          >ล้างการค้นหา</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <order-table />
    <dialog-delete-order />
  </v-container>

</template>
<script>
import { sync, call } from 'vuex-pathify'
import OrderTable from '../../components/tables/OrderTable'
// import { ls } from '../../util/ls'
import DialogDeleteOrder from '../../components/dialog/DialogDeleteOrder'
export default {
  name: 'OrderView',
  components: {
    OrderTable,
    DialogDeleteOrder
  },
  data: () => ({
    items: ['ทั้งหมด', '0', '1']
  }),
  computed: {
    ...sync('status', ['status']),
    ...sync('order', ['statusId', 'departmentId', 'date', 'keyword', 'payload']),
    ...sync('department', ['departments']),
    dateRangeText() {
      return this.date.join(' ~ ')
    }
  },
  created() {
    this.onCreate()
  },
  methods: {
    ...call('status', ['getStatusList']),
    ...call('order', ['getOrderList', 'initPayload']),
    ...call('department', ['getDepartmentList']),
    onCreate() {
      this.getDepartmentList()
      this.getStatusList()
    },
    onInit() {
      this.keyword = ''
      this.statusId = 0
      this.departmentId = 0
      this.initDate()
      this.initPayload()
      this.onSearch()
    },
    initDate() {
      var eDate = null
      var dDate = null
      this.date = [dDate, eDate]
    },
    createOrder() {
      this.$router.push({ path: `/actionOrder` })
    },
    onSearch() {
      this.getOrderList(this.payload)
    }
  }
}
</script>
