<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="12" md="8">
          <div class="text-h4 ml-15 my-5">
            ใบเบิกวัสดุแพทย์ โรงพยาบาลอุ้มผาง
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div class="d-flex justify-end">
            <v-btn class="ma-1 mr-5" to="/orderView">กลับ</v-btn>
            <v-menu
              offset-y
              buttom
            >
              <template #activator="{ on, attrs }">
                <div class="split-btn mr-3">
                  <v-btn class="mt-1 px-3 main-btn" color="teal" dark @click="onSave(rows)">บันทึกร่าง</v-btn>
                  <v-btn class="mt-1 px-1 actions-btn" color="teal " dark v-bind="attrs" v-on="on"><v-icon left>mdi-menu-down</v-icon></v-btn>
                </div>
              </template>
              <v-list>
                <v-list-item @click="onSend(rows)">
                  <v-list-item-title>ส่งใบเบิก</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu></div>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card v-if="!$vuetify.breakpoint.xs">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <status-send-order />
                  </v-col>
                  <v-col>
                    <status-approve-order />
                  </v-col>
                  <v-col>
                    <status-complete-order />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-row>
              <v-col cols="12" md="6">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="order.departmentId"
                    label="หน่วยงาน"
                    :items="departmentList"
                    item-text="departmentName"
                    item-value="id"
                    color="teal"
                    :rules="[v => !!v || 'กรุณาเลือกหน่วยงาน']"
                  >
                    <template #item="data">
                      <v-col>
                        <div class="text">
                          {{ data.item.departmentName }}
                        </div>
                      </v-col>
                      <div class="text " style="font-size: 13px">
                        หน่วยงาน {{ data.item.departmentTypeName }}
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-model="order.note"
                    label="หมายเหตุ"
                    :rules="rules"
                    hide-details="auto"
                    color="teal"
                  />
                </v-col>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="4">เลขที่เอกสาร</v-col>
                  <v-col cols="12" md="6" />
                  <v-col cols="12" md="4">วันที่เอกสาร</v-col>
                  <v-col cols="12" md="6"> {{ showDate }} </v-col>
                  <v-col cols="12" md="4">สถานะเอกสาร</v-col>
                  <v-col cols="12" md="6">
                    <span class="status-box">
                      <v-chip
                        color="#374151"
                        dark
                        outlined
                        style="background-color: #f9fafb !important"
                        @click="history()"
                      >
                        สร้างเอกสาร
                      </v-chip>
                    </span>
                  </v-col>
                </v-row></v-col>
            </v-row>

          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-simple-table>
              <thead>
                <tr class="header">
                  <th style="width: 50px">
                    ลำดับ
                  </th>
                  <th>
                    รายการ
                  </th>
                  <th style="width: 150px;">
                    หน่วยนับ
                  </th>
                  <th style="width: 150px;">
                    จำนวนคงเหลือ
                  </th>
                  <th style="width: 150px;">
                    จำนวนเบิก
                  </th>
                  <th style="width: 100px;">
                    action
                    <v-btn
                      color="teal"
                      dark
                      absolute
                      bottom
                      right
                      fab
                      @click="addRows()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                >
                  <td align="center">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="row.productId"
                      class="pt-6"
                      color="teal"
                      outlined
                      :items="productItem"
                      item-text="productName"
                      item-value="id"
                      item-disabled="disable"
                      dense
                      :rules="[v => !!v || 'กรุณาเลือกรายการ']"
                      @change="choseProduct(row)"
                    >
                      <template #item="data">
                        <v-row>
                          <v-col>
                            <div class="text">
                              {{ data.item.productName }}
                            </div>
                          </v-col>
                        </v-row>
                        <div class="text">
                          {{ data.item.unitName }}
                        </div>
                      </template>
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="row.unitId"
                      class="pt-6"
                      color="teal"
                      :items="unitList"
                      item-text="unitName"
                      item-value="id"
                      readonly
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="row.remainQty"
                      color="teal"
                      outlined
                      class="pt-6"
                      dense
                      :rules="[v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) || 'กรุณากรอกจำนวนคงเหลือ']"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="row.requestQty"
                      color="teal"
                      outlined
                      class="pt-6"
                      dense
                      :rules="[v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) || 'กรุณากรอกจำนวนเบิก']"
                    />
                  </td>
                  <td>
                    <v-icon
                      color="red darken-4"
                      @click="deleteRow(row)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- <div class="text-center" style="margin-bottom: 2rem; padding-bottom: 1rem;">
              <v-btn
                color="teal"
                dark
                bottom
                rounded
                width="500px"
                @click="addRows()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div> -->
          </v-card>
        </v-col>

      </v-row>
    </v-form>
    <dialog-create-and-send-order />
  </v-container>

</template>
<script>
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
import StatusSendOrder from '../statusOrders/StatusSendOrder'
import StatusCompleteOrder from '../statusOrders/StatusCompleteOrder'
import StatusApproveOrder from '../statusOrders/StatusApproveOrder'
import { formatISO, parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import { ls } from '../../util/ls'
import DialogCreateAndSendOrder from '../dialog/DialogCreateAndSendOrder'
export default {
  name: 'CreateOrderForm',
  components: {
    StatusSendOrder,
    StatusCompleteOrder,
    StatusApproveOrder,
    DialogCreateAndSendOrder
  },
  data: function() {
    return {
      valid: true,
      date: null,
      statusText: null,
      rows: [
        {
          productId: null,
          unitId: null,
          remainQty: 0.00,
          requestQty: 0.00,
          approvedQty: 0.00,
          pickStockQty: 0.00,
          reusedQty: 0.00,
          factQty: 0.00
        }
      ],
      rules: {
        remainning: [val => (val || '').length > 0 || 'กรุณากรอกจำนวนคงเหลือ'],
        quantity: [val => (val || '').length > 0 || 'กรุณากรอกจำนวนเบิก']
      }
    }
  },
  computed: {
    order: sync('order/order'),
    item: sync('order/item'),
    snackbars: sync('product/snackbar'),
    id: sync('order/id'),
    stockList: sync('stock/stocks'),
    detail: sync('order/orderDetails'),
    departmentList: sync('department/departments'),
    search: sync('department/search'),
    unitList: sync('unit/units'),
    unitId: sync('product/unitId'),
    productItem: sync('product/productItem'),
    orderDate: sync('order/orderDate'),
    statusList: sync('status/status'),
    status: sync('order/status'),
    employees: sync('employee/employees'),
    crBy: sync('order/crBy'),
    dialogConfirmSendOrder: sync('order/dialogCreateAndSendOrder'),
    canSave() {
      // if (this.$refs.form) { return this.$refs.form.validate() }
      return true
    },
    showDate() {
      return this.date !== null ? format(parseISO(this.date), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  watch: {
    '$refs.form': function(newValue) {
    },
    'row.stockId': function(newValue) {
      if (newValue !== null) {
        this.getUnitId(newValue).then((response) => {
          this.row.unitId = response
        })
      }
    }
  },

  async created() {
    await this.onCreate()
  },
  mounted() {

  },
  methods: {
    ...call('product', ['getProductList', 'getUnitId']),
    ...call('order', ['getOrderList', 'saveOrder', 'initOrder', 'getOrder', 'updateStatus']),
    ...call('department', ['getDepartmentList']),
    ...call('unit', ['getUnitList']),
    ...call('status', ['getStatus']),
    addRows() {
      const rowItem = _.find(this.rows, function(o) { return o.productId === null })
      if (!rowItem) {
        this.rows.push({
          productId: null,
          unitId: null,
          remainQty: 0,
          requestQty: 0,
          approvedQty: 0,
          pickStockQty: 0,
          reusedQty: 0,
          factQty: 0
        })
      }
    },
    deleteRow(item) {
      const indexItem = this.rows.indexOf(item)
      if (item.productId !== null) {
        const Item = _.find(this.productItem, function(o) { return o.id === item.productId })
        Item.disable = false
        this.rows.splice(indexItem, 1)
      } else {
        this.rows.splice(indexItem, 1)
      }
    },
    initDate() {
      var eDate = formatISO(new Date(), { representation: 'date' })
      this.date = eDate
    },
    onBack() {
      this.getProductList(1)
    },
    async onSave(rows) {
      const rowItem = _.find(this.rows, function(o) { return o.productId === null })
      if (rowItem) {
        const indexItem = this.rows.indexOf(rowItem)
        this.rows.splice(indexItem, 1)
        this.id = this.$route.params.id
        this.detail = rows
        this.crBy = ls.get('id')
        this.saveOrder().then((response) => {
          this.$router.push({ path: `/EditOrderForm/${response}` })
        })
      }

      if (this.$refs.form.validate()) {
        this.id = this.$route.params.id
        this.detail = rows
        this.crBy = ls.get('id')
        this.saveOrder().then((response) => {
          this.$router.push({ path: `/EditOrderForm/${response}` })
        })
      }
    },
    async onSend(rows) {
      const rowItem = _.find(this.rows, function(o) { return o.productId === null })
      if (rowItem) {
        const indexItem = this.rows.indexOf(rowItem)
        this.rows.splice(indexItem, 1)
        this.dialogConfirmSendOrder = true
        this.orderDate = this.data
        this.detail = rows
        const od = this.order
        this.item = {
          departmentName: _.find(this.departmentList, function(d) { return d.id === od.departmentId }).departmentName,
          employeeName: od.employeeName,
          orderCount: Object.keys(rows).length
        }
        this.crBy = ls.get('id')
      }

      if (this.$refs.form.validate() === true) {
        this.dialogConfirmSendOrder = true
        this.orderDate = this.data
        this.detail = rows
        const od = this.order
        this.item = {
          departmentName: _.find(this.departmentList, function(d) { return d.id === od.departmentId }).departmentName,
          employeeName: od.employeeName,
          orderCount: Object.keys(rows).length
        }
        this.crBy = ls.get('id')
      }
    },
    async onCreate() {
      if (this.$route.params.id === undefined) {
        // const empId = ls.get('id')
        this.initOrder().then((response) => {
        })
        this.getDepartmentList()
        this.getUnitList()
        this.getProductList(1).then((response) => {
          this.productItem = response
        })
        this.initDate()
        this.status = 0
      } else {
        this.id = this.$route.params.id
        this.getOrder().then((response) => {
          this.order = response
          this.rows = response.detail
          this.date = response.date
        })
        this.getDepartmentList()
        this.getUnitList()
        this.getProductList(1).then((response) => {
          this.productItem = response
          const itemProduct = this.productItem
          _.forEach(itemProduct, function(p) { p.disable = false })
          _.forEach(this.rows, function(r) {
            const itemInRows = _.find(itemProduct, function(f) { return f.id === r.productId })
            itemInRows.disable = true
          })
        })
      }
    },
    choseProduct(item) {
      // create disable
      const itemProduct = this.productItem
      const rowItem = _.find(this.rows, function(o) { return o.productId === item.productId })
      if (item.productId === rowItem.productId) {
        // set unit
        const unitItem = _.find(itemProduct, function(o) { return o.id === item.productId })
        rowItem.unitId = unitItem.unitId
        // set disable switch
        _.forEach(itemProduct, function(p) { p.disable = false })
        _.forEach(this.rows, function(r) {
          const itemInRows = _.find(itemProduct, function(f) { return f.id === r.productId })
          if (itemInRows) {
            itemInRows.disable = true
          }
        })
        // const disable = _.find(this.rows, function(o) { return o.productId === item.productId })
      }

      // const rowItem = this.rows.indexOf(item)'
    },
    getColor(status) {
      if (status === 0) {
        this.statusText = 'สร้างเอกสาร'
        return '#2A6B63'
      } else if (status === 1) {
        this.statusText = 'แบบร่าง'
        return '#2A6B63'
      } else if (status === 2 || status === 3) {
        this.statusText = 'ยื่นเอกสารแล้ว'
        return '#45E1BD'
      // } else if (status === 3) {
      //   this.statusText = 'ตรวจสอบ'
      //   return '#D09344'
      } else if (status === 4) {
        this.statusText = 'อนุมัติแล้ว'
        return '#9E5FD5'
      } else {
        this.statusText = 'เสร็จสิ้นแล้ว'
        return '#4157E6'
      }
    },
    history() {
      this.dialogHistory = true
    }
  }

}

</script>
<style lang="scss">
.main-btn{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 2px !important;
  }
  .actions-btn{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 !important;
    min-width: 35px !important;
    margin-left: -3.5px;
  }
</style>
