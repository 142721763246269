<template>
  <v-card
    class="mx-10 px-10 pb-10 "
  >
    <v-card-title>
      <div class="text-h4 mr-9">
        สรุปประจำเดือน
      </div>
      <div class="mr-9">
        <v-autocomplete
          v-model="month"
          :items="months"
          item-text="text"
          item-value="value"
        />
      </div>
      <div class="text-h4 mr-9">
        ปี
      </div>
      <div class="mr-9">
        <v-autocomplete
          v-model="year"
          :items="yesrs"
          item-text="yearTh"
          item-value="year"
        />
      </div>
      <div class="mr-2">
        <v-btn
          color="teal"
          dark
          @click="search()"
        >
          ค้นหา
        </v-btn>
      </div>
      <div>
        <v-btn
          color="grey"
          dark
          @click="clear()"
        >
          ล้างการค้นหา
        </v-btn>
      </div>
      <v-spacer />
      <div class="mx-2">
        <v-btn
          color="teal"
          dark
          @click="downloadReport()"
        >
          พิมพ์รายงาน
        </v-btn>
      </div>
      <div>
        <v-btn
          color="grey"
          dark
          to="/report"
        >
          กลับ
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-row>
        <v-col cols="6">
          <v-data-table
            :headers="header"
            :items="summarize"
            hide-default-footer
            class="elevation-1"
          >
            <template #item.value="{ item }">
              <div class="text-right">
                {{ formatNumber(item.value) }}
              </div>
            </template>
            <template #item.list="{ item }">
              <div v-if="item.number == 3 ">
                <div class="text-center">
                  {{ item.list }}
                </div>
              </div>
              <div v-else-if="item.number == 6 ">
                <div class="text-center">
                  {{ item.list }}
                </div>
              </div>
              <div v-else>
                {{ item.list }}
              </div>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">
          <summarize-chart />
        </v-col>
      </v-row>

    </v-card-title>
    <v-card-text>
      <v-card-title>
        <div class="text-h5 mr-9">
          รายการเบิกจ่าย
        </div>
      </v-card-title>
      <v-card-title>การเบิกจ่ายภายในโรงพยาบาล</v-card-title>
      <v-row>
        <v-col cols="6">
          <v-data-table
            :headers="headers"
            :items="summarizeInHos"
            :items-per-page="5"
            class="elevation-1"
          >
            <template #item="{item, index}">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.departmentName }}</td>
                <td class="text-right">{{ formatNumber(item.price) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">
          <div>
            <in-hos-chart />
          </div>
        </v-col>
      </v-row>
      <v-card-title>การเบิกจ่ายหน่วยงานอื่นๆ</v-card-title>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="summarizeOutHos"
            :items-per-page="5"
            class="elevation-1"
          >
            <template #item="{item, index}">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.departmentName }}</td>
                <td class="text-right">{{ formatNumber(item.price) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">
          <div>
            <out-hos-chart />
          </div>
        </v-col>
      </v-row>

    </v-card-text>

  </v-card>
</template>
<script>
// import { formatISO } from 'date-fns'
import InHosChart from '../chart/InHosChart'
import SummarizeChart from '../chart/SummarizeChart'
import OutHosChart from '../chart/OutHosChart'
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
import { getMonthCurrent, getYearNow, getSetYear } from '../../util/index'
export default {
  name: 'SummaryReport',
  components: { InHosChart, SummarizeChart, OutHosChart },
  data() {
    return {
      headers: [
        { text: 'ลำดับ', value: 'number' },
        { text: 'หน่วยงาน', value: 'departmentName' },
        { text: 'มูลค่ารวม', value: 'price', align: 'right' }
      ],
      header: [
        { text: 'รายการ', value: 'list', sortable: false },
        { text: 'มูลค่า', value: 'value', sortable: false, align: 'right' }
      ],
      months: [
        { text: 'มกราคม', value: 1 },
        { text: 'กุมภาพันธ์', value: 2 },
        { text: 'มีนาคม', value: 3 },
        { text: 'เมษายน', value: 4 },
        { text: 'พฤษภาคม', value: 5 },
        { text: 'มิถุนายน', value: 6 },
        { text: 'กรกฎาคม', value: 7 },
        { text: 'สิงหาคม', value: 8 },
        { text: 'กันยายน', value: 9 },
        { text: 'ตุลาคม', value: 10 },
        { text: 'พฤศจิกายน', value: 11 },
        { text: 'ธันวาคม', value: 12 }
      ],
      yesrs: getSetYear()

    }
  },
  computed: {
    ...sync('report', ['summarizeInHos', 'summarizeOutHos', 'summarize', 'month', 'year', 'onSearch'])
  },
  created() {
    this.initSearch()
    this.search()
  },

  methods: {
    ...call('report', ['getSummarizeInHos', 'getSummarizeOutHos', 'getSummarize', 'exportSummaryReport']),
    initSearch() {
      this.month = getMonthCurrent()
      this.year = getYearNow()
      getSetYear()
    },
    async clear() {
      this.initSearch()
      this.getSummarize()
      this.getSummarizeInHos().then((response) => {
        this.summarizeInHos = response
      })
      this.getSummarizeOutHos().then((response) => {
        this.summarizeOutHos = response
      })
      this.onSearch = true
    },
    async search() {
      await this.getSummarize()
      await this.getSummarizeInHos().then((response) => {
        this.summarizeInHos = response
      })
      await this.getSummarizeOutHos().then((response) => {
        this.summarizeOutHos = response
      })
      this.onSearch = true
    },
    async downloadReport() {
      this.exportSummaryReport().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ExportSummaryReport.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
    formatNumber(value) {
      if (_.isNaN(value)) {
        return '0'
      }
      const n = value.toFixed(2)
      const ns = n.split('.')
      const num = ns[1] === '00'
        ? ns[0]
        : n.charAt(n.length - 1) === '0'
          ? n.slice(0, n.length - 1)
          : n
      if (_.isNaN(num)) {
        return '0'
      }
      return (String(num)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}

</script>
