import request from '@/util/request'
const api = {
  login: (user) => {
    return request({
      url: `/Authenticate/login`,
      method: 'post',
      data: user
    })
  },
  logout: (userName) => {
    return request({
      url: `/Authenticate/logout/${userName}`,
      method: 'get'
    })
  },
  getRole: () => {
    return request({
      url: `/Authenticate/getRole`,
      method: 'get'
    })
  }
}
export default api
