<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
  >
    <v-card style="height: 100%;">
      <v-card-title>
        <span class="text-h5">เพิ่มวัสดุการแพทย์</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-combobox
                v-model="data.productName"
                :search-input.sync="search"
                label="ชื่อ"
                color="teal"
                :items="productList"
                item-text="productName"
                item-disabled="disable"
                required
                :rules="[v => !!v || 'กรุณากรอกชื่อวัสดุแพทย์']"
              >
                <template #no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        "<strong>{{ search }}</strong>". เป็นวัสดุแพทย์ใหม่ กด <kbd>enter</kbd> เพื่อใช้วัสดุนี้
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="data.unitId"
                :items="unitList"
                item-text="unitName"
                item-value="id"
                label="หน่วยนับ"
                color="teal"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="data.price"
                label="ราคา"
                color="teal"
              />
            </v-col>
            <v-col>
              สถานะ
              <v-switch
                v-model="data.isActive"
                inset
                :false-value="0"
                :true-value="1"
                color="teal"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          dark
          @click="close()"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="teal"
          dark
          @click="Confirm()"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import { sync, call } from 'vuex-pathify'
export default {
  name: 'CreateProductForm',
  computed: {
    dialog: sync('product/dialogCreateForm'),
    data: sync('product/product'),
    snackbars: sync('product/snackbar'),
    typeList: sync('type/types'),
    companyList: sync('company/companies'),
    id: sync('product/id'),
    unitList: sync('unit/units'),
    item: sync('product/productItem'),
    productList: sync('product/productList'),
    search: sync('product/search')
  },
  created() {
    this.checkinit()
  },
  mounted() {

  },
  methods: {
    ...call('product', ['getProducts', 'initProduct', 'saveProduct']),
    Confirm() {
      this.saveProduct().then((response) => {
        const payload = {
          page: 1,
          itemsPerPage: 10
        }
        this.getProducts(payload).then((response) => {
          this.item = response
        })
        this.dialog = false
        if (response != null) {
          this.snackbars = true
        }
      })
      this.initProduct()
    },
    close() {
      this.dialog = false
      this.initProduct()
    },
    checkinit() {
      if (this.id === 0) {
        this.initProduct()
      }
    }
  }
}
</script>
