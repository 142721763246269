<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">เพิ่มข้อมูลหน่วยนับ</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="12"
            >
              <v-text-field
                v-model="data.unitName"
                label="ชื่อหน่วยนับ"
                color="teal"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          dark
          @click="close()"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="teal"
          dark
          @click="Confirm()"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import { sync, call } from 'vuex-pathify'
export default {
  name: 'SetupUnitForm',
  computed: {
    dialog: sync('unit/dialogCreateForm'),
    data: sync('unit/unit'),
    snackbars: sync('product/snackbar'),
    id: sync('unit/id')
  },
  created() {
    this.checkinit()
  },
  mounted() {

  },
  methods: {
    ...call('unit', ['getUnitList', 'initUnit', 'saveUnit']),
    Confirm() {
      this.saveUnit().then((response) => {
        if (response != null) {
          this.getUnitList()
          this.dialog = false
          this.snackbars = true
        }
      })
      this.initUnit()
    },
    close() {
      this.dialog = false
      this.initUnit()
    },
    checkinit() {
      if (this.id == null) {
        this.initUnit()
      }
    }
  }
}
</script>
