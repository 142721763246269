<template>
  <v-snackbar
    v-model="snackbars"
    :multi-line="multiLine"
  >
    {{ text }}
  </v-snackbar>
</template>
<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'Success',
  data: () => ({
    multiLine: false,
    text: `บันทึกเสร็จสิ้น`
  }),
  computed: {
    snackbars: sync('product/snackbar')
  }
}
</script>
