import { make } from 'vuex-pathify'
import api from '@/api/product'
import _ from 'lodash'
// import { ls } from '../../util/ls'

const state = {
  keyword: '',
  products: [],
  productList: [],
  id: 0,
  dialogDelete: false,
  dialogCreateForm: false,
  product: null,
  snackbar: false,
  typeId: 0,
  unitId: 0,
  productItem: [],
  loading: false,
  search: ''

}

const mutations = make.mutations(state)

const actions = {
  initProduct({ state }) {
    state.product = {
      id: null,
      productName: null,
      unitId: null,
      unitName: null,
      price: null,
      isActive: 1
    }
  },
  getProductList({ state }, type) {
    return new Promise((resolve) => {
      api.getProductMaster().then((response) => {
        resolve(response.data)
        state.productList = response.data
        if (type === 1) {
          _.forEach(response.data, function(p) {
            p.disable = false
          })
          console.log(1)
        } else if (type === 2) {
          _.forEach(response.data, function(p) {
            p.disable = true
          })
          console.log(2)
        }
      }
      )
    })
  },
  getProducts({ state }, payload) {
    return new Promise((resolve) => {
      api.getProducts(state.keyword, payload.page, payload.itemsPerPage).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  deleteProduct({ state }) {
    // api.deleteProduct(state.id)
    return new Promise((resolve) => {
      api.deleteProduct(state.id).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  saveProduct({ state }) {
    if (state.id === 0) {
      return new Promise((resolve) => {
        api.postProduct(state.product).then((response) => {
          resolve(response.data)
        })
      })
    } else {
      return new Promise((resolve) => {
        api.putProduct(state.id, state.product).then((response) => {
          resolve(response.data)
        })
      })
    }
  },
  getProduct({ state }) {
    api.getProduct(state.id).then((response) => {
      state.product = response.data
    })
  },
  getUnitId({ state }, id) {
    return new Promise((resolve) => {
      api.getUnitId(id).then((response) => {
      // state.unitId = response.data
        resolve(response.data)
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
