<template>
  <div
    class="main pa-5"
  >
    <v-card-title>
      <div class="text-h5">
        จัดวัสดุแพทย์ใหม่
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="teal"
          class="ma-2 dark--text"
          dark
        >
          บันทึก
        </v-btn>
      </div>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-5">
          <v-simple-table>
            <thead>
              <tr class="header">
                <th style="width: 50px">
                  ลำดับ
                </th>
                <th>
                  วัสดุแพทย์(เก่า)
                </th>
                <th style="width: 150px;">
                  จำนวน(เก่า)
                </th>
                <th>
                  วัสดุแพทย์(ใหม่)
                </th>
                <th style="width: 150px;">
                  จำนวน(ใหม่)
                </th>
                <th style="width: 150px;">
                  หน่วยนับ
                </th>
                <th>
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in rows"
                :key="index"
              >
                <td align="center">
                  {{ index + 1 }}
                </td>
                <td>
                  <v-autocomplete
                    v-model="row.productId1"
                    class="pt-6"
                    color="teal"
                    outlined
                    :items="productItem"
                    item-text="productName"
                    item-value="id"
                    item-disabled="disable"
                    dense
                    :rules="[v => !!v || 'กรุณาเลือกรายการ']"
                    @change="choseProduct(row)"
                  >
                    <template #item="data">
                      <v-row>
                        <v-col>
                          <div class="text">
                            {{ data.item.productName }}
                          </div>
                        </v-col>
                      </v-row>
                      <div class="text">
                        {{ data.item.unitName }}
                      </div>
                    </template>
                  </v-autocomplete>
                </td>
                <td>
                  <v-text-field
                    v-model="row.qty1"
                    class="pt-6"
                    color="teal"
                    outlined
                    dense
                  />
                </td>
                <td>
                  <v-autocomplete
                    v-model="row.productId2"
                    class="pt-6"
                    color="teal"
                    outlined
                    :items="productItem"
                    item-text="productName"
                    item-value="id"
                    item-disabled="disable"
                    dense
                    :rules="[v => !!v || 'กรุณาเลือกรายการ']"
                    @change="choseProduct(row)"
                  >
                    <template #item="data">
                      <v-row>
                        <v-col>
                          <div class="text">
                            {{ data.item.productName }}
                          </div>
                        </v-col>
                      </v-row>
                      <div class="text">
                        {{ data.item.unitName }}
                      </div>
                    </template>
                  </v-autocomplete>
                </td>
                <td>
                  <v-text-field
                    v-model="row.qty2"
                    color="teal"
                    outlined
                    class="pt-6"
                    dense
                    :rules="[v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) || 'กรุณากรอกจำนวนคงเหลือ']"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="row.newPrice"
                    color="teal"
                    outlined
                    class="pt-6"
                    dense
                    :rules="[v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) || 'กรุณากรอกจำนวนเบิก']"
                  />
                </td>
                <td>
                  <v-icon
                    color="red darken-4"
                    @click="deleteRow(row)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="text-center" style="margin-bottom: 2rem; padding-bottom: 1rem;">
            <v-btn
              color="teal"
              dark
              bottom
              rounded
              width="500px"
              @click="addRows()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>

    </v-row>
  </div>

</template>
<script>
import { call, sync } from 'vuex-pathify'
export default {
  name: 'Repack',
  components: { },
  data: function() {
    return {
      rows: [
        {
          productId1: null,
          qty1: 0,
          productId2: null,
          qty2: 0,
          newPrice: 0,
          unitId: null
        }
      ]
    }
  },
  computed: {
    productItem: sync('product/productItem')
  },
  created() {
    // this.onCreate()
  },
  methods: {
    ...call('repack', ['getRepacks']),
    addRows() {
      this.rows.push({
        productId1: null,
        qty1: 0,
        productId2: null,
        qty2: 0,
        newPrice: 0,
        unitId: null
      })
    }
    // onCreate() {
    //   const authority = `https://notify-bot.line.me/oauth/authorize`
    //   const response_type = 'code'
    //   const scope = `notify`
    //   const state = Date.now() + '' + Math.random()
    //   localStorage.setItem('state', state)
    //   const client_id = 'Agha089QAMbp45bIJk5rkM'
    //   const redirect_uri = 'https://localhost:5556'
    //   const url =
    //     authority +
    //     '?' +
    //     'response_type=' +
    //     encodeURI(response_type) +
    //     '&' +
    //     'client_id=' +
    //     encodeURI(client_id) +
    //     '&' +
    //     'redirect_uri=' +
    //     encodeURI(redirect_uri) +
    //     '&' +
    //     'scope=' +
    //     encodeURI(scope) +
    //     '&' +
    //     'state=' +
    //     encodeURI(state)
    //   window.location.href = url
    // }
  }
}
</script>
<style lang="scss">

</style>
