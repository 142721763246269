<template>
  <div>
    <div v-if="status == 'DRAFTED' || status == ''">
      <div class="status d-flex justify-center align-center">
        <v-icon x-large>
          mdi-file-send
        </v-icon>
      </div>
      <v-row
        justify="center"
        align="center"
      >
        <v-chip color="grey">ยื่นเอกสารแล้ว</v-chip>
      </v-row>
    </div>
    <div v-if="status == 'APPROVED' || status == 'SENDED' || status == 'FINISHED'">
      <div class="status-active d-flex justify-center align-center">
        <v-icon x-large color="teal lighten-2">
          mdi-file-send
        </v-icon>
      </div>
      <v-row
        justify="center"
        align="center"
      >
        <v-chip color="teal lighten-2" dark>ยื่นเอกสารแล้ว</v-chip>
      </v-row>
    </div>

  </div>

</template>
<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'StatusSendOrder',
  computed: {
    ...sync('order', ['status'])
  }
}
</script>
<style lang="scss" >
.status {
  height: 80px;
  width: 80px;
  border: solid grey;
  border-radius: 50%;
  margin: auto;
}
</style>
