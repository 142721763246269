<template>
  <v-card>
    <v-app-bar

      color="grey lighten-3"
      app
      class="v-bar--underline"
    >
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = !drawer" />
      <v-list-item-title class="text-h5 ">
        ระบบจัดการครุภัณฑ์
      </v-list-item-title>
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-avatar
            class="ml-2"
            size="36"
            v-on="on"
          >
            <img :src="getPicture()">
          </v-avatar>
        </template>
        <v-card class="pa-5">
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                class="ml-2"
                size="36"
              >
                <img :src="getPicture()">
              </v-avatar>
              <h4>คุณ {{ getFullName() }}</h4>
              <p class="text-caption mt-1">{{ getPosition() }}</p>
              <v-divider class="my-3" />
              <v-btn
                depressed
                rounded
                text
                @click="signOut"
              >
                ออกจากระบบ
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      class="mx-auto"
      color="grey lighten-3"
      re
    >

      <v-list-item-group v-model="group" class="mb-2" style="margin-top: 20px" color="teal">
        <v-list-item-title class="ml-3"> เมนูหลัก </v-list-item-title>
        <v-list-item
          v-for="mainMenu in menus"
          :key="mainMenu.name"
          :to="mainMenu.link"
        >
          <v-list-item-icon>
            <v-icon> {{ mainMenu.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="mainMenu.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group v-if="getRole() === 'admin'" color="teal">
        <v-list-item-title class="mt-5 ml-3"> เมนูจัดการข้อมูล </v-list-item-title>
        <v-list-item
          v-for="menu in manageMenus"
          :key="menu.name"
          :to="menu.link"
        >
          <v-list-item-icon>
            <v-icon> {{ menu.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="menu.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <!-- <v-list->
      <v-list-item-content>
        sad
      </v-list-item-content>
      <v-list-item-icon @click="sa">
        <v-icon> mdi-logout </v-icon>
      </v-list-item-icon>
    </v-list-> -->
      <template #append>
        <div class="pa-2">
          V
          {{ version }}
        </div>
        <div class="text-xs leading-5 text-gray-500">&copy; 2023 รอบรั้วโรงพยาบาล</div>
      </template>
    </v-navigation-drawer>
  </v-card>

</template>
<script>
import { sync, call } from 'vuex-pathify'
// import { ls } from '../util/ls'
import jwt_decode from 'jwt-decode'
import liff from '@line/liff'
export default {
  name: 'AppBar',
  components: {},
  data: () => ({
    identityUrl: process.env.VUE_APP_IDENTITY_URL,
    postLogout: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
    menus: [
      {
        icon: 'mdi-home',
        text: 'หน้าแรก',
        link: '/'
      },
      {
        icon: 'mdi-hand-heart',
        text: 'ใบเบิกวัสดุการแพทย์',
        link: '/orderView'
      }
    ],
    manageMenus: [
      {
        icon: 'mdi-file-document-edit',
        text: 'รายการเบิก',
        link: '/pickingList'
      },
      {
        icon: 'mdi-archive-arrow-down',
        text: 'รับเข้าคลัง',
        link: '/manageStock'
      },
      {
        icon: 'mdi-semantic-web',
        text: 'repack(ยังไม่พร้อมใช้งาน)',
        link: '/'
        // link: '/repackList'
      },
      {
        icon: 'mdi-chart-areaspline',
        text: 'รายงาน',
        link: '/report'
      },
      {
        icon: 'mdi-cog',
        text: 'จัดการระบบ',
        link: '/setup'
      }
    ],
    drawer: true,
    group: null,
    version: process.env.VUE_APP_VERSION
  }),
  computed: {
    dialog: sync('app/dialogLogin'),
    role: sync('app/roleId'),
    ...sync('authen', ['userName', 'password', 'roleId'])

  },
  created() {
  },
  methods: {
    ...call('authen', ['logout']),
    ...call('order', ['initPayload']),
    signOut() {
      liff.ready.then(async() => {
        const endSessionUrl = this.identityUrl +
        '/connect/endsession' +
        '?' +
        'id_token_hint=' +
        localStorage.getItem('id_token') +
        '&' +
        'post_logout_redirect_uri=' + this.postLogout
        liff.logout()
        localStorage.removeItem('access_token')
        localStorage.removeItem('id_token')

        window.location.href = endSessionUrl
      })
    },
    initPage() {
      this.initPayload()
    },
    getUser() {
      const user = jwt_decode(localStorage.getItem('access_token') ?? '')
      return user
    },
    getFullName() {
      const user = this.getUser()
      return user.firstname + ' ' + user.lastname
    },
    getRole() {
      const user = this.getUser()
      return user.role
    },
    getPicture() {
      const user = this.getUser()
      return user.provider_user_picture
    },
    getPosition() {
      const user = this.getUser()
      return user.position_name
    }
  }
}
</script>
