<template>
  <div
    v-if="stock"
    class="main pa-5"
  >
    <v-row>
      <v-col>
        <div class="text-h4">
          รับเข้าคลัง
        </div>
      </v-col>
      <!-- <v-col>
        <v-radio-group
          v-model="type"
          class="my-5"
          row
          @change="choseType(type)"
        >
          <v-radio
            label="บริจาค"
            :value="1"
          />
          <v-radio
            label="ซื้อ"
            :value="2"
          />
        </v-radio-group>
      </v-col> -->
      <v-spacer />
      <v-col class="d-flex justify-end">
        <v-btn class="ma-1" to="/manageStock">กลับ</v-btn>
        <v-btn
          class="ma-1 mr-1"
          color="teal"
          dark
          :loading="loading"
          @click="onSaveDraft()"
        >
          บันทึกร่าง
        </v-btn>
        <v-btn
          class="ma-1 mr-4"
          color="teal"
          dark
          :loading="loading"
          @click="onSave()"
        >
          บันทึก
        </v-btn>
      </v-col>

    </v-row>

    <v-card class="stock ma-4 pa-10" elevation="5">
      <v-form
        ref="formStock"
        v-model="validStock"
        lazy-validation
      >
        <v-row>
          <v-col cols="3">
            <div class="flex text-h5 text-align-center">
              ข้อมูลใบเสร็จ
            </div>
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="autoCalculate"
              label="คำนวณราคาอัตโนมัติ"
              inset
              color="teal"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="stock.stockNumber"
              color="teal"
              label="รหัสใบเสร็จ"
              :disabled="disable"
              :rules="[v => !!v || 'กรุณากรอกรหัสใบเสร็จ']"
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="stock.companyId"
              label="บริษัท"
              color="teal"
              :items="companies"
              item-text="companyName"
              item-value="id"
              :disabled="disable"
              :rules="[v => !!v || 'กรุณาเลือกจำนวนบริษัท']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="stock.totalPrice"
              color="teal"
              label="มูลค่าทั้งสิ้น"
              suffix="บาท"
              type="number"
              :disabled="autoCalculate"
            />
          </v-col>
          <v-col>

            <v-menu
              ref="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="showDate"
                  label="วันที่"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  color="teal"
                  :disabled="disable"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="stockDate"
                color="teal"
                no-title
                locale="th"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card class="product ma-4 pa-10" elevation="5">
      <v-form
        ref="formProduct"
        v-model="validProduct"
        lazy-validation
      >
        <v-col>
          <div class="text-h5">
            รายการที่รับเข้า
          </div>
        </v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-center text-h6" style="width: 50px;">ลำดับ</th>
              <th class="text-center text-h6">ชื่อรายการ</th>
              <th class="text-center text-h6" style="width: 150px;">หน่วยนับ</th>
              <th class="text-center text-h6" style="width: 200px;">จำนวน</th>
              <th class="text-center text-h6" style="width: 200px;">ราคาต่อหน่วย</th>
              <th class="text-center text-h6" style="width: 200px;">ราคาทั้งหมด</th>
              <th class="text-h6">ลบ</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in rows"
              :key="index"
            >
              <td align="center">{{ index + 1 }}</td>
              <td><v-autocomplete
                v-model="row.productId"
                class="pt-6"
                color="teal"
                :items="productItem"
                item-text="productName"
                item-value="id"
                item-disabled="disable"
                :return-object="false"
                :rules="[v => !!v || 'กรุณาเลือกรายการ']"
                @change="choseProduct(row)"
              /></td>
              <td><v-autocomplete
                v-model="row.unitId"
                class="pt-6"
                color="teal"
                :items="units"
                item-text="unitName"
                item-value="id"
                readonly
              /></td>
              <td><v-text-field
                v-model="row.quantity"
                class="pt-6"
                color="teal"
                type="number"
                @blur="sumPrices(row)"
              /></td>
              <td><v-text-field
                v-model="row.price"
                class="pt-6"
                type="number"
                color="teal"
                suffix="บาท"
                @blur="sumPrices(row)"
              /></td>
              <td><v-text-field
                v-model="row.sumPrice"
                class="pt-6"
                type="number"
                color="teal"
                suffix="บาท"
                :disabled="autoCalculate"
                @blur="sumPrices(row)"
              /></td>
              <td><v-icon
                color="red darken-4"
                @click="deleteRow(row)"
              >
                mdi-delete
              </v-icon></td>
            </tr>

          </tbody>
        </v-simple-table>
        <v-btn
          color="teal"
          dark
          absolute
          bottom
          right
          fab
          @click="addRows()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-form>
    </v-card>
    <dialog-edit-stock />
    <dialog-delete-stock />
    <dialog-confirm-create-stock />
  </div>

</template>
<script>
import { sync, call } from 'vuex-pathify'
import DialogEditStock from '../stock/DialogEditStock'
import DialogDeleteStock from '../dialog/DialogDeleteStock'
import DialogConfirmCreateStock from '../dialog/DialogConfirmCreateStock'
import { formatISO, parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import _ from 'lodash'
export default {
  name: 'CreateStockForm',
  components: { DialogEditStock, DialogDeleteStock, DialogConfirmCreateStock },
  data: function() {
    return {
      validStock: false,
      validProduct: false,
      rows: [
        {
          productId: null,
          unitId: 0,
          quantity: 0,
          price: 0,
          sumPrice: 0
        }
      ],
      disable: false,
      autoCalculate: true,
      loading: false
    }
  },
  computed: {
    ...sync('stock', ['stock', 'stockDate', 'id', 'stockDetails', 'dialogCreateForm', 'item', 'status']),
    ...sync('unit', ['units']),
    ...sync('product', ['productItem', 'products']),
    ...sync('company', ['companies']),
    idP: sync('product/id'),
    showDate() {
      return this.stockDate !== null ? format(parseISO(this.stockDate), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  created() {
    this.onCreate()
  },
  methods: {
    ...call('stock', ['getStock', 'initStock', 'saveStock']),
    ...call('unit', ['getUnitList']),
    ...call('company', ['getCompanyList']),
    ...call('product', ['getProductList']),
    addRows() {
      const rowItem = _.find(this.rows, function(o) { return o.productId === null })
      if (!rowItem) {
        this.rows.push({
          productId: null,
          unitId: 0,
          quantity: 0,
          price: 0,
          sumPrice: 0
        })
      }
    },
    deleteRow(item) {
      const indexItem = this.rows.indexOf(item)
      if (item.productId !== null) {
        const Item = _.find(this.productItem, function(o) { return o.id === item.productId })
        Item.disable = false
        this.rows.splice(indexItem, 1)
      } else {
        this.rows.splice(indexItem, 1)
      }
    },
    onCreate() {
      this.getUnitList()
      this.initStock()
      this.getCompanyList()
      this.initDate()
      this.getProductList(1).then((response) => {
        this.productItem = response
      })
      this.type = 2
    },
    choseProduct(item) {
      const itemProduct = this.productItem
      const rowItem = _.find(this.rows, function(o) { return o.productId === item.productId })
      if (item.productId === rowItem.productId) {
        const unitItem = _.find(itemProduct, function(o) { return o.id === item.productId })
        rowItem.unitId = unitItem.unitId
        rowItem.price = unitItem.price
        // set disable switch
        _.forEach(itemProduct, function(p) {
          p.disable = false
        })
        _.forEach(this.rows, function(r) {
          const itemInRows = _.find(itemProduct, function(f) { return f.id === r.productId })
          itemInRows.disable = true
        })
      }
    },
    onBlack() {
    },
    initDate() {
      var eDate = formatISO(new Date(), { representation: 'date' })
      this.stockDate = eDate.toString()
    },
    onSave() {
      if (this.$refs.formProduct.validate() === true) {
        this.status = 2
        this.stockDetails = this.rows
        this.dialogCreateForm = true
        const od = this.stock
        this.item = {
          companyName: _.find(this.companies, function(d) { return d.id === od.companyId }).companyName,
          stockCount: Object.keys(this.rows).length
        }
        // this.saveStock().then(() => {
        //   this.$router.push({ path: `/manageStock` })
        // })
      }
    },
    onSaveDraft() {
      if (this.$refs.formProduct.validate() === true) {
        this.status = 1
        this.stockDetails = this.rows
        this.dialogCreateForm = true
        const od = this.stock
        this.item = {
          companyName: _.find(this.companies, function(d) { return d.id === od.companyId }).companyName,
          stockCount: Object.keys(this.rows).length
        }
        // this.saveStock().then(() => {
        //   this.$router.push({ path: `/manageStock` })
        // })
      }
    },
    sumPrices(item) {
      // this.row.sumPrice = (quantity * price)
      if (this.autoCalculate === true) {
        if (item) {
          const sum = ((item.quantity) * (item.price))
          item.sumPrice = sum
        }
        this.totalPrice()
      }
    },
    totalPrice() {
      const countList = []
      _.forEach(this.rows, function(item) {
        countList.push(item.sumPrice)
      })
      this.stock.totalPrice = _.sum(countList)
    }
  }
}
</script>
<style lang="scss">

</style>
