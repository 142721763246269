import { make } from 'vuex-pathify'
import api from '@/api/inventory'
const state = {
  keyword: '',
  inventory: []
}

const mutations = make.mutations(state)

const actions = {
  getInventories({ state }) {
    return new Promise((resolve) => {
      api.getInventories(state.keyword).then((response) => {
        state.inventory = response.data
        resolve()
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
