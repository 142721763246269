<template>
  <v-data-table
    :headers="header"
    :items="stocks.items"
    :items-per-page="10"
    class="elevation-1"
    :loading="loading"
    :options.sync="options"
    :server-items-length="stocks.totalCount"
    :footer-props="{'items-per-page-options':[10,20, 30, 50, 100]}"
  >
    <template #item.actions="{ item }">
      <v-icon
        color="teal"
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-file-document
      </v-icon>
    </template>
    <template #item.status="{ item }">
      <div>
        <v-chip
          :color="Statuss(item.status)"
          dark
          outlined
          :style="{'background-color':
            getBgColor(item.status)+
            '!important'
          }"
        >
          {{ getStatusName(item.status) }}
        </v-chip>
      </div>
    </template>
    <template #item.totalPrice="{ item }">
      <div>
        {{ formatNumber(item.totalPrice) }}
      </div>
    </template>
    <template #item.stockDate="{ item }">
      {{ item.stockDate | showStockDate }}
    </template>
  </v-data-table>

</template>
<script>

import { sync, call } from 'vuex-pathify'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import _ from 'lodash'
export default {
  name: 'StockTable',
  filters: {
    showStockDate: function(value) {
      return value !== null ? format(parseISO(value), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  data: () => ({
    options: {
      'page': 1,
      'itemsPerPage': 10,
      'sortBy': [],
      'sortDesc': [],
      'groupBy': [],
      'groupDesc': [],
      'mustSort': false,
      'multiSort': false
    },
    header: [
      {
        text: 'วันที่',
        value: 'stockDate',
        sortable: true,
        align: 'center'
      },
      {
        text: 'รหัสใบเสร็จ',
        value: 'billNumber',
        sortable: true
      },
      {
        text: 'บริษัท',
        value: 'companyName',
        sortable: true,
        align: 'center'
      },
      {
        text: 'สถาณะ',
        value: 'status',
        sortable: true,
        align: 'center'
      },
      {
        text: 'ราคาทั้งหมด',
        value: 'totalPrice',
        sortable: false,
        align: 'center'
      },
      {
        text: 'จำนวนไอเทม',
        value: 'itemNumber',
        sortable: true,
        align: 'center'
      },
      {
        text: 'Action',
        width: '8rem',
        align: 'center',
        value: 'actions'
      }

    ]
  }),

  computed: {
    ...sync('stock', ['stocks', 'keyword', 'loading', 'id']),
    idP: sync('product/id')
  },
  watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getStock()
      } else if (value.length === 0) this.getStock()
    },
    options: {
      handler() {
        this.getStock()
      },
      deep: true
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    ...call('stock', ['getStockList']),
    ...call('product', ['getProductList']),
    editItem(item) {
      this.$router.push(`/editStockForm/${item.id}`)
    },
    getStock() {
      const { page, itemsPerPage } = this.options
      const payload = {
        page: page,
        itemsPerPage: itemsPerPage
      }
      this.getStockList(payload)
    },
    formatNumber(value) {
      if (_.isNaN(value)) {
        return '0'
      }
      const n = value.toFixed(2)
      const ns = n.split('.')
      const num = ns[1] === '00'
        ? ns[0]
        : n.charAt(n.length - 1) === '0'
          ? n.slice(0, n.length - 1)
          : n
      if (_.isNaN(num)) {
        return '0'
      }
      return (String(num)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Statuss(value) {
      switch (value) {
        case 1:return '#374151'
        case 2:return '#1d4ed8'
      }
    },
    getBgColor(value) {
      switch (value) {
        case 1:return '#f9fafb'
        case 2:return '#eff6ff'
      }
    },
    getStatusName(value) {
      switch (value) {
        case 1:return 'บันทึกร่าง'
        case 2:return 'ส่งแล้ว'
      }
    }
  }

}
</script>

