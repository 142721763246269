<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
import { getYearNow } from '../../util/index'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'SummarizeChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          },
          {
            label: '',
            backgroundColor: '',
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    ...sync('report', ['summarizeChart', 'year', 'onSearch'])
  },
  watch: {
    onSearch(value) {
      if (value === true) {
        this.onCreate()
      }
    }
  },
  async created() {
    await this.initSearch()
    await this.onCreate()
  },
  methods: {
    ...call('report', ['getSummarizeChart']),
    setData() {
      const label = []
      const data1 = []
      const data2 = []
      _.forEach(this.summarizeChart, function(value) {
        label.push(value.month)
      })
      _.forEach(this.summarizeChart, function(value) {
        data1.push(value.admit)
      })
      _.forEach(this.summarizeChart, function(value) {
        data2.push(value.disburse)
      })
      this.chartData.labels = label
      this.chartData.datasets = [
        {
          label: 'รวมมูลค่า',
          backgroundColor: '#f87979',
          data: data1
        },
        {
          label: 'รวมเบิกจ่ายทั้งหมด',
          backgroundColor: '#00D8FF',
          data: data2
        }
      ]
      // this.setDatas = data
    },
    onCreate() {
      this.getSummarizeChart().then((response) => {
        this.summarizeChart = response
        this.setData()
        this.onSearch = false
      })
    },
    initSearch() {
      this.year = getYearNow()
    }
  }
}
</script>
