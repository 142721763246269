<template>
  <v-container>
    <v-row class="pt-3">
      <v-col cols="8">
        <div class="text-h4 ml-15 my-5">
          ใบเบิกวัสดุแพทย์ โรงพยาบาลอุ้มผาง
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn class="ma-1 mr-5" to="/pickingList">กลับ</v-btn>
          <v-menu
            offset-y
            buttom
          >
            <template #activator="{ on, attrs }">
              <div class="split-btn mr-3">
                <v-btn class="mt-1 px-3 main-btn" color="teal" dark @click="onSave(rows)">อนุมัติ</v-btn>
                <v-btn class="mt-1 px-1 actions-btn" color="teal " dark v-bind="attrs" v-on="on"><v-icon left>mdi-menu-down</v-icon></v-btn>
              </div>
            </template>
            <v-list>
              <v-list-item @click="printDoc(item)">
                <v-list-item-title>พิมพ์เอกสาร</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu></div>
      </v-col>
    </v-row>
    <v-card class="statusCard ma-5 px-10 pb-6">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <status-send-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-approve-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-complete-order />
        </v-col>
      </v-row>
    </v-card>
    <v-form
      v-if="item"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="6">
          <v-card class="infomation ma-5 pa-4" height="155px">
            <v-row>
              <div class="col-3  text-left text-h5">หน่วยงาน</div>
              <div class="col-1  text-h5">:</div>
              <div class="col-6  text-h5"> {{ item.departmentName }} </div>
              <div class="col-3  text-left">ชื่อผู้เบิก</div>
              <div class="col-1 ">:</div>
              <div class="col-6  ">{{ item.employeeName }}</div>
              <div class="col-3   text-left">หมายเหตุ</div>
              <div class="col-1 ">:</div>
              <div class="col-6  ">{{ item.note }}</div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="my-5 mr-5 pa-4" height="155px">
            <v-row>
              <div class="col-4 ">เลขที่เอกสาร</div>
              <div class="col-6 ">{{ item.documentNumber }}</div>
              <div class="col-4 ">วันที่เอกสาร</div>
              <div class="col-6 "> {{ showDate }} </div>
              <div class="col-4 ">สถานะเอกสาร</div>
              <div class="col-6 ">
                <span class="status-box">
                  <v-chip
                    :color="getColor(item.statusCode)"
                    dark
                    outlined
                    :style="{'background-color':
                      getBgColor(status)+
                      '!important'
                    }"
                    @click="history()"
                  >
                    {{ statusText }}
                  </v-chip>
                </span>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-5 mb-2 pb-12">
            <v-simple-table>
              <thead>
                <tr class="header">
                  <th style="width: 80px;">
                    ลำดับ
                  </th>
                  <th>
                    รายการ
                  </th>
                  <th style="width: 160px;">
                    หน่วยนับ
                  </th>
                  <th style="width: 140px;">
                    จำนวนคงเหลือ
                  </th>
                  <th style="width: 140px;">
                    จำนวนเบิก
                  </th>
                  <th style="width: 140px;">
                    จำนวนอนุมัติ
                  </th>
                  <th style="width: 100px;">
                    คงคลัง
                  </th>
                  <th style="width: 200px;">
                    สถานะ
                  </th>
                  <th style="width: 100px;">
                    <v-icon v-if="approveAll()" color="secondary">mdi-check-circle</v-icon>
                    <v-icon v-if="!approveAll()" @click="addApproveAll()">mdi-check-circle-outline</v-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  style="height: 30px;"
                >
                  <td align="center">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="row.productId"
                      color="teal"
                      outlined
                      :items="productItem"
                      item-text="productName"
                      item-value="id"
                      :rules="[v => !!v || 'กรุณาเลือกรายการ']"
                      readonly
                      dense
                      hide-details
                      @change="choseProduct(row)"
                    >
                      <template #item="data">
                        <v-row>
                          <v-col>
                            <div class="text">
                              {{ data.item.productName }}
                            </div>
                          </v-col>
                        </v-row>

                        <div class="text">
                          {{ data.item.unitName }}
                        </div>

                      </template>
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="row.unitId"
                      color="teal"
                      outlined
                      :items="unitList"
                      item-text="unitName"
                      item-value="id"
                      readonly
                      dense
                      hide-details
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="row.remainQty"
                      color="teal"
                      outlined
                      type="number"
                      readonly
                      dense
                      hide-details
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="row.requestQty"
                      color="teal"
                      outlined
                      type="number"
                      readonly
                      dense
                      hide-details
                    />
                  </td>
                  <td class="d-flex align-center">
                    <v-text-field
                      v-if="row.isApproved"
                      v-model="row.approvedQty"
                      color="teal"
                      filled
                      outlined
                      type="number"
                      dense
                      hide-details
                      append-icon=" mdi-pencil-outline"
                      readonly
                      @click:append="approveItem(row.id)"
                    />
                    <v-text-field
                      v-if="!row.isApproved"
                      v-model="row.approvedQty"
                      color="teal"
                      outlined
                      type="number"
                      dense
                      hide-details
                    />
                  </td>
                  <td>
                    {{ row.stockRemainning }}
                  </td>
                  <td align="center">
                    <template v-if="parseInt(row.approvedQty) === parseInt(row.requestQty)">
                      <v-chip
                        color="success"
                        outlined
                        small
                      >
                        ครบตามจำนวน
                      </v-chip>
                    </template>
                    <template
                      v-if="parseInt(row.approvedQty) > parseInt(row.requestQty)"
                    >
                      <v-chip
                        color="warning"
                        outlined
                        small
                      >
                        เกินจำนวนเบิก
                      </v-chip>
                    </template>
                    <template
                      v-if="parseInt(row.approvedQty) < parseInt(row.requestQty)"
                    >
                      <v-chip
                        color="warning"
                        outlined
                        small
                      >
                        ต่ำกว่าจำนวนเบิก
                      </v-chip>
                    </template>
                  </td>
                  <td>
                    <template v-if="row.isApproved == true">
                      <v-icon
                        color="teal"
                        class="mr-4"
                        @click="approveItem(row.id)"
                      >
                        mdi-checkbox-marked-circle

                      </v-icon>
                    </template>
                    <template v-if="row.isApproved == false">
                      <v-icon
                        class="mr-4"
                        @click="approveItem(row.id)"
                      >
                        mdi-check-circle-outline

                      </v-icon>
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

      </v-row>
    </v-form>
    <dialog-check-approve />
    <dialog-history />
    <dialog-confirm-approve-order />
    <loading-page />
  </v-container>
</template>
<script>
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
import StatusSendOrder from '../statusOrders/StatusSendOrder'
import StatusCompleteOrder from '../statusOrders/StatusCompleteOrder'
import StatusApproveOrder from '../statusOrders/StatusApproveOrder'
import DialogCheckApprove from '../orders/DialogCheckApprove'
import { formatISO, parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import DialogHistory from '../dialog/DialogHistory'
import DialogConfirmApproveOrder from '../dialog/DialogConfirmApproveOrder'
import LoadingPage from '../dialog/loadingPage'
import { ls } from '../../util/ls'
import { getColor, getBgColor } from '@/util'
export default {
  name: 'ApproveOrderForm',
  components: {
    StatusSendOrder,
    StatusCompleteOrder,
    StatusApproveOrder,
    DialogCheckApprove,
    DialogHistory,
    DialogConfirmApproveOrder,
    LoadingPage
  },
  data: function() {
    return {
      valid: true,
      date: null,
      statusText: null,
      rows: [
        {
          productId: null,
          unitId: null,
          remainQty: 0.00,
          requestQty: 0.00,
          approvedQty: 0.00,
          pickStockQty: 0.00,
          reusedQty: 0.00,
          factQty: 0.00,
          typeId: 0
        }
      ],
      rules: {
        remainning: [val => (val || '').length > 0 || 'กรุณากรอกจำนวนคงเหลือ'],
        quantity: [val => (val || '').length > 0 || 'กรุณากรอกจำนวนเบิก']
      }
    }
  },
  computed: {
    dialog: sync('stock/dialogCreateForm'),
    item: sync('order/order'),
    snackbars: sync('product/snackbar'),
    id: sync('order/id'),
    stockList: sync('stock/stocks'),
    detail: sync('order/orderDetails'),
    withdraws: sync('order/withdraws'),
    withdrawId: sync('withdrawDetail/withdrawId'),
    departmentList: sync('department/departments'),
    search: sync('department/search'),
    unitList: sync('unit/units'),
    unitId: sync('product/unitId'),
    productItem: sync('product/productItem'),
    dialogCheck: sync('order/dialogCheck'),
    dialogCheckComplete: sync('order/dialogCheckComplete'),
    dialogConfirmOrder: sync('order/dialogConfirmOrder'),
    statusList: sync('status/status'),
    status: sync('order/status'),
    checkNumber: sync('order/checkNumber'),
    dialogHistory: sync('order/dialogHistory'),
    HistoryItems: sync('order/history'),
    orderDetailId: sync('orderDetail/id'),
    orderDetail: sync('orderDetail/orderDetail'),
    loading: sync('app/loading'),
    ...sync('stock', ['dialogTypePurchase', 'dialogTypeDonate']),
    ...sync('order', ['approver', 'approveDate']),
    showDate() {
      return this.date !== null ? format(parseISO(this.date), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  watch: {
    'row.stockId': function(newValue) {
      if (newValue !== null) {
        this.getUnitId(newValue).then((response) => {
          this.row.unitId = response
        })
      }
    }
  },

  async created() {
    await this.onCreate()
  },
  mounted() {

  },
  methods: {
    ...call('product', ['getProductList', 'getUnitId']),
    ...call('order', ['completeOrder', 'editOrder', 'getOrderList', 'checkOrderApprove', 'initOrder', 'getOrder', 'updateStatus', 'approveOrder', 'getHistory']),
    ...call('department', ['getDepartmentList']),
    ...call('unit', ['getUnitList']),
    ...call('status', ['getStatusList']),
    ...call('stock', ['getStockType']),
    ...call('orderDetail', ['approveOrderDetail', 'approveAllOrderDetail']),
    addApproveRow(row) {
      const rowItem = _.find(this.rows, function(o) { return o.productId === row.productId })

      rowItem.approvedQty = row.requestQty
    },
    addApproveAll() {
      const id = this.$route.params.id
      const rowItems = _.filter(this.rows, function(o) { return o.isApproved === false })
      _.forEach(rowItems, function(p) {
        p.approvedQty = p.requestQty
        p.isApproved = true
      })
      const data = {
        OrderId: id,
        Details: rowItems
      }
      this.approveAllOrderDetail(data)
    },
    initDate() {
      var eDate = formatISO(new Date(), { representation: 'date' })
      this.date = eDate
    },
    onBack() {
      this.getProductList(1)
    },
    async onSave() {
      if (this.$refs.form.validate() === true) {
        this.id = this.$route.params.id
        this.detail = this.rows
        this.approver = ls.get('id')
        this.dialogConfirmOrder = true
        // await this.editOrder()
        // await this.approveOrder()
        // await this.updateStatus()
        // await this.getOrderList()
        // await this.$router.push('/pickingList')
        // this.checkOrderApprove(this.rows).then((response) => {
        //   if (response) {
        //     this.checkNumber = response
        //     this.dialogCheck = true
        //   }
        // })
      }
    },
    async onCreate() {
      this.loading = true
      this.getStatusList()
      this.id = this.$route.params.id
      this.getUnitList()
      this.getDepartmentList()
      await this.getOrder().then((response) => {
        this.loading = false
        if (response.statusCode === 'SENDED') {
          this.item = response
          this.rows = response.detail
          this.date = response.date
          this.status = response.statusCode
          this.statusText = response.statusName
        } else if (response.statusCode === 'APPROVED') {
          this.$router.push(`/completeOrderForm/${this.$route.params.id}`)
        } else if (response.statusCode === 'FINISHED') {
          this.$router.push(`/sumaryOrderForm/${this.$route.params.id}`)
        }
      })

      await this.getProductList(1).then((response) => {
        this.productItem = response
      })
    },
    choseProduct(item) {
      // create disable
      const itemProduct = this.productItem
      const rowItem = _.find(this.rows, function(o) { return o.productId === item.productId })
      if (item.productId === rowItem.productId) {
        // set unit
        const unitItem = _.find(itemProduct, function(o) { return o.id === item.productId })
        rowItem.unitId = unitItem.unitId
        // set disable switch
        // _.forEach(itemProduct, function(p) { p.disable = false })
        // _.forEach(this.rows, function(r) {
        //   const itemInRows = _.find(itemProduct, function(f) { return f.id === r.productId })
        //   itemInRows.disable = true
        // })
        // const disable = _.find(this.rows, function(o) { return o.productId === item.productId })
      }

      // const rowItem = this.rows.indexOf(item)'
    },
    getColor(status) {
      return getColor(status)
    },
    getBgColor(status) {
      return getBgColor(status)
    },
    onComplete() {
      this.id = this.$route.params.id
      this.detail = this.rows
      this.completeOrder()
      this.updateStatus()
      this.$router.push('/pickingList')
    },
    async printDoc(row) {
      const baseUrl = `${process.env.VUE_APP_BASE_URL_API}/GenerateDocument/GenerateDocument`
      const id = `/${row.id}`
      window.open(baseUrl + id, '_blank')
    },
    history() {
      this.HistoryItems = []
      this.getHistory(this.$route.params.id)
      this.dialogHistory = true
    },
    approveItem(id) {
      const row = _.find(this.rows, (r) => { return r.id === id })
      this.orderDetail = row
      this.orderDetailId = id
      row.isApproved = !row.isApproved
      this.approveOrderDetail()
    },
    approveAll() {
      const rowItems = _.filter(this.rows, function(o) { return o.isApproved === false })
      if (rowItems.length === 0) {
        return true
      } else return false
    },
    getBGColor(row) {
      if (row.approvedQty === 0) {
        return '#FFFFFF'
      } else if (row.approvedQty > 0 && row.approvedQty < row.requestQty) {
        return '#FFF9C4'
      } else if (parseInt(row.approvedQty) === parseInt(row.requestQty)) {
        return '#C8E6C9'
      } else {
        return '#FFEBEE'
      }
    }
  }

}

</script>
<style lang="scss">

</style>
