<template>
  <v-data-table
    :headers="header"
    :items="departments"
    :items-per-page="15"
    class="elevation-1"
    sort-by="id"
  >
    <template #item.actions="{ item }">
      <v-icon
        class="mr-2"
        color="teal"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon

        color="red darken-4"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>

</template>
<script>

import { sync, call } from 'vuex-pathify'
export default {
  name: 'DepartmentTable',
  data: () => ({
    header: [
      {
        text: 'หน่วยงาน',
        value: 'departmentName',
        sortable: true
      },
      {
        text: 'ประเภทหน่วยงาน',
        value: 'departmentTypeName',
        sortable: true,
        align: 'center'
      },
      // {
      //   text: 'สถานะ',
      //   value: 'isActive',
      //   sortable: true,
      //   width: '8rem',
      //   align: 'center'
      // },
      {
        text: 'Action',
        width: '8rem',
        align: 'center',
        value: 'actions'
      }

    ]
  }),

  computed: {
    ...sync('department', ['departments', 'dialogCreateForm', 'id', 'dialogDelete', 'keyword'])
  },
  watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getDepartmentList()
      } else if (value.length === 0) this.getDepartmentList()
    }
  },
  created() {
  },
  mounted() {
    this.getDepartmentList()
  },
  methods: {
    ...call('department', ['getDepartmentList', 'getDepartment']),
    ...call('product', ['getProductList']),
    ...call('departmentType', ['getDepartmentTypeList']),
    deleteItem(item) {
      this.dialogDelete = true
      this.id = item.id
    },
    editItem(item) {
      this.dialogCreateForm = true
      this.id = item.id
      this.getDepartment()
      this.getDepartmentTypeList()
    }
  }

}
</script>
