<template>
  <v-card
    class="mx-10 px-10 pb-10 "
  >
    <v-card-title>
      <div class="text-h4 mr-9">
        วัสดุแพทย์คงเหลือ
      </div>
      <v-spacer />
      <div class="mx-2">

        <div>
          <v-btn
            color="grey"
            dark
            to="/report"
          >
            กลับ
          </v-btn>
        </div>
      </div></v-card-title>
    <v-card-title>
      <v-text-field
        v-model="keyword"
        append-icon="mdi-magnify"
        label="ค้นหา"
        single-line
        hide-details
        color="teal"
      />
      <v-spacer />
      <v-col
        class="d-flex"

        sm="2"
      />
    </v-card-title>
    <v-card-text>
      <v-card>
        <v-data-table
          :headers="header"
          :items="inventory"
          :items-per-page="100"
          class="elevation-1"
          sort-by="id"
        >
          <template #item.price="{ item }">
            {{ formatNumber(item.price) }}
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
// import { formatISO } from 'date-fns'
import { sync, call } from 'vuex-pathify'
import { getYearNow, getSetYear, getMonthCurrent } from '../../util/index'
import _ from 'lodash'
export default {
  name: 'ProductReportMonthly',
  components: { },
  data() {
    return {
      header: [
        {
          text: 'ชื่อรายการ',
          value: 'productName',
          sortable: true,
          align: 'center',
          width: '1000px'
        },
        {
          text: 'จำนวนคงเหลือ',
          value: 'qty',
          sortable: true,
          align: 'center'
        },
        {
          text: 'หน่วยนับ',
          value: 'unitName',
          sortable: true,
          align: 'center'
        },
        {
          text: 'มูลค่า',
          value: 'price',
          sortable: true,
          align: 'center'
        }
      ],
      mounts: [
        { text: 'มกราคม', value: 1 },
        { text: 'กุมภาพันธ์', value: 2 },
        { text: 'มีนาคม', value: 3 },
        { text: 'เมษายน', value: 4 },
        { text: 'พฤษภาคม', value: 5 },
        { text: 'มิถุนายน', value: 6 },
        { text: 'กรกฎาคม', value: 7 },
        { text: 'สิงหาคม', value: 8 },
        { text: 'กันยายน', value: 9 },
        { text: 'ตุลาคม', value: 10 },
        { text: 'พฤศจิกายน', value: 11 },
        { text: 'ธันวาคม', value: 12 }
      ],
      yesrs: getSetYear(),
      items: []

    }
  },
  computed: {
    ...sync('inventory', ['inventory', 'keyword'])
  },
  watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getInventories()
      } else if (value.length === 0) {
        this.getInventories()
      }
    }
  },
  created() {
    // this.initSearch()
    this.search()
  },

  methods: {
    ...call('inventory', ['getInventories']),
    initSearch() {
      this.year = getYearNow()
      this.month = getMonthCurrent()
      getSetYear()
    },
    async clear() {
      this.initSearch()
      this.getInventory()
      this.onSearch = true
    },
    async search() {
      this.getInventories()
    },
    formatNumber(value) {
      if (_.isNaN(value)) {
        return '0'
      }
      const n = value.toFixed(2)
      const ns = n.split('.')
      const num = ns[1] === '00'
        ? ns[0]
        : n.charAt(n.length - 1) === '0'
          ? n.slice(0, n.length - 1)
          : n
      if (_.isNaN(num)) {
        return '0'
      }
      return (String(num)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}

</script>
