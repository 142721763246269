import { make } from 'vuex-pathify'
import api from '@/api/report'
// import fileDownload from 'js-file-download'
const state = {
  summarizeInHos: [],
  summarizeOutHos: [],
  summarize: [],
  summarizeDepartments: [],
  year: 0,
  month: 0,
  summarizeChart: [],
  onSearch: false

}

const mutations = make.mutations(state)

const actions = {

  getSummarizeInHos({ state }) {
    return new Promise((resolve) => {
      api.getSummarizeInHos(state.month, state.year).then((response) => {
        resolve(response.data)
      })
    })
  },
  getSummarizeOutHos({ state }) {
    return new Promise((resolve) => {
      api.getSummarizeOutHos(state.month, state.year).then((response) => {
        resolve(response.data)
      })
    })
  },
  getSummarize({ state }) {
    const data = {
      previousMonth: state.month - 1,
      month: state.month,
      year: state.year
    }
    api.getSummarize(data).then((response) => {
      state.summarize = response.data
    })
  },
  getSummerizeDepartment({ state }) {
    const data = {
      month: state.month,
      year: state.year
    }
    return new Promise((resolve) => {
      api.getSummerizeDepartment(data).then((response) => {
        state.summarizeDepartments = response.data
        resolve(response.data)
      })
    })
  },
  getSummarizeChart({ state }) {
    const data = {
      month: state.month,
      year: state.year
    }
    return new Promise((resolve) => {
      api.getSummarizeChart(data).then((response) => {
        resolve(response.data)
      })
    })
  },
  getProductReport({ state }) {
    return new Promise((resolve) => {
      api.getProductReport(state.year).then((response) => {
        resolve(response.data)
      })
    })
  },
  getProductReportMonthly({ state }) {
    return new Promise((resolve) => {
      api.getProductReportMonthly(state.month, state.year).then((response) => {
        resolve(response.data)
      })
    })
  },
  exportProductReport({ state }) {
    return new Promise((resolve) => {
      api.exportProductReport(state.year).then((response) => {
        resolve(response.data)
      })
    })
  },
  exportSummaryReport({ state }) {
    return new Promise((resolve) => {
      api.exportSummaryReport(state.month, state.year).then((response) => {
        resolve(response.data)
      })
    })
  },
  exportSummaryDepartmentReport({ state }) {
    return new Promise((resolve) => {
      api.exportSummaryDepartmentReport(state.month, state.year).then((response) => {
        resolve(response.data)
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
