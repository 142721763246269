<template>
  <v-app>
    <app-bar />

    <v-main class="grey lighten-3">
      <v-container>
        <router-view />
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import appBar from './appBar.vue'
export default {
  name: 'DefaultLayout',

  components: {
    appBar

  },
  data: () => ({
    //
  }),
  created() {
  },
  mounted() {
  }
}
</script>

