v-col<template>
  <div>
    <v-row class="pt-3">
      <v-col cols="8">
        <div class="text-h3 ml-15 my-5">
          ใบเบิกวัสดุแพทย์ โรงพยาบาลอุ้มผาง(complete)
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn class="ma-1 mr-5" to="/pickingList">กลับ</v-btn>
          <v-menu
            offset-y
            buttom
          >
            <template #activator="{ on, attrs }">
              <div class="split-btn mr-3">
                <v-btn class="mt-1 px-3 main-btn" color="teal" dark @click="onComplete(rows)">เสร็จสิ้น</v-btn>
                <v-btn class="mt-1 px-1 actions-btn" color="teal " dark v-bind="attrs" v-on="on"><v-icon left>mdi-menu-down</v-icon></v-btn>
              </div>
            </template>
            <v-list>
              <v-list-item @click="printDoc(item)">
                <v-list-item-title>พิมพ์เอกสาร</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu></div>
      </v-col>
    </v-row>
    <v-card class="statusCard ma-5 px-10 pb-6">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <status-send-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-approve-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-complete-order />
        </v-col>
      </v-row>
    </v-card>
    <v-form
      v-if="item"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="6">
          <v-card class="infomation ma-5 pa-4" height="155px">
            <v-row>
              <div class="col-3  text-left text-h5">หน่วยงาน</div>
              <div class="col-1  text-h5">:</div>
              <div class="col-6  text-h5"> {{ item.departmentName }} </div>
              <div class="col-3  text-left">ชื่อผู้เบิก</div>
              <div class="col-1 ">:</div>
              <div class="col-6  ">{{ item.employeeName }}</div>
              <div class="col-3   text-left">หมายเหตุ</div>
              <div class="col-1 ">:</div>
              <div class="col-6  ">{{ item.note }}</div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="my-5 mr-5 pa-4" height="155px">
            <v-row>
              <div class="col-4 ">เลขที่เอกสาร</div>
              <div class="col-6 ">{{ item.documentNumber }}</div>
              <div class="col-4 ">วันที่เอกสาร</div>
              <div class="col-6 "> {{ showDate }} </div>
              <div class="col-4 ">สถานะเอกสาร</div>
              <div class="col-6 ">
                <span class="status-box">
                  <v-chip
                    :color="getColor(item.statusCode)"
                    dark
                    outlined
                    :style="{'background-color':
                      getBgColor(item.statusCode)+
                      '!important'
                    }"
                    @click="history()"
                  >
                    {{ statusText }}
                  </v-chip>
                </span>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-5 mb-2 pb-12">
            <v-simple-table>
              <thead>
                <tr class="header">
                  <th style="width: 100px;">
                    ลำดับ
                  </th>
                  <th>
                    รายการ
                  </th>
                  <th style="width: 150px;">
                    หน่วยนับ
                  </th>
                  <th style="width: 150px;">
                    จำนวนอนุมัติ
                  </th>
                  <th style="width: 150px;">
                    จำนวนของเบิกคลัง
                  </th>
                  <th style="width: 150px;">
                    จำนวนของ Reuse
                  </th>
                  <th style="width: 150px;">
                    จำนวนที่เบิกได้จริง
                  </th>
                  <th style="width: 100px;">
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                >
                  <td align="center">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="row.productId"
                      class="pt-6"
                      color="teal"
                      outlined
                      dense
                      :items="productItem"
                      item-text="productName"
                      item-value="id"
                      :rules="[v => !!v || 'กรุณาเลือกรายการ']"
                      readonly
                      @change="choseProduct(row)"
                    >
                      <template #item="data">
                        <v-row>
                          <v-col>
                            <div class="text">
                              {{ data.item.productName }}
                            </div>
                          </v-col>
                        </v-row>

                        <div class="text">
                          {{ data.item.unitName }}
                        </div>

                      </template>
                    </v-autocomplete>
                  </td>
                  <td style="width: 150px;">
                    <v-autocomplete
                      v-model="row.unitId"
                      class="pt-6"
                      color="teal"
                      outlined
                      dense
                      :items="unitList"
                      item-text="unitName"
                      item-value="id"
                      readonly
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="row.approvedQty"
                      color="teal"
                      outlined
                      dense
                      class="pt-6"
                      type="number"
                      readonly
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-if="!row.isConfirmed"
                      v-model="row.pickStockQty"
                      color="teal"
                      outlined
                      dense
                      class="pt-6"
                      type="number"
                      @blur="sumFactQty(row)"
                    />
                    <v-text-field
                      v-if="row.isConfirmed"
                      v-model="row.pickStockQty"
                      color="teal"
                      outlined
                      dense
                      filled
                      type="number"
                      hide-details
                      append-icon=" mdi-pencil-outline"
                      readonly
                      @blur="sumFactQty(row)"
                      @click:append="checkConfirm(row.id)"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-if="!row.isConfirmed"
                      v-model="row.reusedQty"
                      color="teal"
                      outlined
                      dense
                      class="pt-6"
                      type="number"
                      @blur="sumFactQty(row)"
                    />
                    <v-text-field
                      v-if="row.isConfirmed"
                      v-model="row.reusedQty"
                      color="teal"
                      outlined
                      dense
                      filled
                      class="pt-6"
                      type="number"
                      append-icon=" mdi-pencil-outline"
                      readonly
                      @blur="sumFactQty(row)"
                      @click:append="checkConfirm(row.id)"
                    />
                  </td>
                  <td class="text-center">
                    {{ parseInt(row.reusedQty) + parseInt(row.pickStockQty) }}
                  </td>
                  <td>
                    <v-btn
                      v-if="row.isConfirmed"
                      class="ma-2"
                      text
                      icon
                      @click="checkConfirm(row.id)"
                    >
                      <v-icon
                        dark
                        color="teal"
                      >
                        mdi-check-circle
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!row.isConfirmed"
                      class="ma-2"
                      text
                      icon
                      @click="checkConfirm(row.id)"
                    >
                      <v-icon
                        dark
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

      </v-row>
    </v-form>
    <dialog-history />
    <dialog-confirm-complete-order />
    <loading-page />
  </div>
</template>
<script>
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
import StatusSendOrder from '../statusOrders/StatusSendOrder'
import StatusCompleteOrder from '../statusOrders/StatusCompleteOrder'
import StatusApproveOrder from '../statusOrders/StatusApproveOrder'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import DialogHistory from '../dialog/DialogHistory'
import DialogConfirmCompleteOrder from '../dialog/DialogConfirmCompleteOrder'
import LoadingPage from '../dialog/loadingPage'
import { getColor, getBgColor } from '@/util'
export default {
  name: 'CompleteOrderForm',
  components: {
    StatusSendOrder,
    StatusCompleteOrder,
    StatusApproveOrder,
    DialogHistory,
    DialogConfirmCompleteOrder,
    LoadingPage
  },
  data: function() {
    return {
      valid: true,
      date: null,
      statusText: null,
      rows: [
        {
          checked: false,
          productId: null,
          unitId: null,
          remainQty: 0,
          requestQty: 0,
          approvedQty: 0,
          pickStockQty: 0,
          reusedQty: 0,
          factQty: 0,
          typeId: 0

        }
      ],
      unConfirmedList: [],
      confirmedList: []
    }
  },
  computed: {
    dialog: sync('stock/dialogCreateForm'),
    item: sync('order/order'),
    snackbars: sync('product/snackbar'),
    id: sync('order/id'),
    stockList: sync('stock/stocks'),
    detail: sync('order/orderDetails'),
    withdraws: sync('order/withdraws'),
    withdrawId: sync('withdrawDetail/withdrawId'),
    departmentList: sync('department/departments'),
    unitList: sync('unit/units'),
    unitId: sync('product/unitId'),
    productItem: sync('product/productItem'),
    statusList: sync('status/status'),
    status: sync('order/status'),
    employees: sync('employee/employees'),
    dialogHistory: sync('order/dialogHistory'),
    HistoryItems: sync('order/history'),
    dialogCompleteOrder: sync('order/dialogCompleteOrder'),
    orderDetail: sync('orderDetail/orderDetail'),
    orderDetailId: sync('orderDetail/id'),
    loading: sync('app/loading'),
    ...sync('stock', ['dialogTypePurchase', 'dialogTypeDonate']),
    ...sync('order', ['approver']),
    showDate() {
      return this.date !== null ? format(parseISO(this.date), 'dd MMMM yyyy', { locale: th }) : ''
    }
    // unConfirmedList() {
    //   return _.filter(this.rows, function(o) { return !o.isConfirm })
    // },
    // confirmedList() {
    //   return _.filter(this.rows, function(o) { return o.isConfirm })
    // }
  },
  watch: {},
  created() {
    this.onCreate()
  },
  mounted() {

  },
  methods: {
    ...call('product', ['getProductList', 'getUnitId']),
    ...call('order', ['completeOrder', 'getOrder', 'updateStatus', 'getHistory']),
    ...call('department', ['getDepartmentList']),
    ...call('unit', ['getUnitList']),
    ...call('status', ['getStatusList']),
    ...call('orderDetail', ['checkOrderDetail']),
    onCreate() {
      this.loading = true
      this.getStatusList()
      this.id = this.$route.params.id
      this.getUnitList()
      this.getDepartmentList()
      this.getOrder().then(async(response) => {
        this.loading = false
        if (response.statusCode === 'APPROVED') {
          this.item = response
          this.rows = response.detail
          this.date = response.date
          this.status = response.statusCode
          this.statusText = response.statusName
        } else if (response.statusCode === 'SENDED') {
          this.$router.push(`/approveOrderForm/${this.$route.params.id}`)
        } else if (response.statusCode === 'FINISHED') {
          this.$router.push(`/sumaryOrderForm/${this.$route.params.id}`)
        }
      })
      this.getProductList(1).then((response) => {
        this.productItem = response
      })
    },
    getColor(status) {
      return getColor(status)
    },
    getBgColor(status) {
      return getBgColor(status)
    },
    onComplete() {
      this.id = this.$route.params.id
      this.detail = this.rows
      this.dialogCompleteOrder = true
      // this.completeOrder().then((response) => {
      //   this.updateStatus().then(() => {
      //     this.$router.push('/pickingList')
      //   })
      // })
    },
    async printDoc(row) {
      const baseUrl = `${process.env.VUE_APP_BASE_URL_API}/GenerateDocument/GenerateDocument`
      const id = `/${row.id}`
      window.open(baseUrl + id, '_blank')
    },
    history() {
      this.HistoryItems = []
      this.getHistory(this.$route.params.id)
      this.dialogHistory = true
    },
    sumFactQty(item) {
      if (item) {
        const sum = ((parseFloat(item.pickStockQty)) + parseFloat(item.reusedQty))
        item.factQty = sum
      }
    },
    checkConfirm(id) {
      const row = _.find(this.rows, (r) => { return r.id === id })
      this.orderDetail = row
      this.orderDetailId = id
      row.isConfirmed = !row.isConfirmed
      this.checkOrderDetail().then((response) => {
      })
    }
  }

}

</script>
<style lang="scss">

</style>
