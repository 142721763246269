<template>
  <v-data-table
    :headers="header"
    :items="companies"
    :items-per-page="15"
    class="elevation-1"
    sort-by="id"
  >
    <template #item.actions="{ item }">
      <v-icon
        class="mr-2"
        color="teal"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon

        color="red darken-4"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>

</template>
<script>

import { sync, call } from 'vuex-pathify'
export default {
  name: 'CompanyTable',
  data: () => ({
    header: [
      {
        text: 'ชื่อ',
        value: 'companyName',
        sortable: true
      },
      {
        text: 'ติดต่อ',
        value: 'phoneNumber',
        sortable: false,
        align: 'center'
      },
      {
        text: 'อีเมล',
        value: 'email',
        sortable: false,
        align: 'center'
      },
      {
        text: 'ข้อมูลเพิ่มเติม',
        value: 'address',
        sortable: true,
        align: 'center'
      },
      // {
      //   text: 'สถานะ',
      //   value: 'isActive',
      //   sortable: true,
      //   width: '8rem',
      //   align: 'center'
      // },
      {
        text: 'Action',
        width: '8rem',
        align: 'center',
        value: 'actions'
      }

    ]
  }),

  computed: {
    ...sync('company', ['companies', 'dialogCreateForm', 'id', 'dialogDelete', 'keyword'])
  },
  watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getCompanyList()
      } else if (value.length === 0) this.getCompanyList()
    }
  },
  created() {
  },
  mounted() {
    this.getCompanyList()
  },
  methods: {
    ...call('company', ['getCompanyList', 'getCompany']),
    ...call('product', ['getProductList']),
    deleteItem(item) {
      this.dialogDelete = true
      this.id = item.id
    },
    editItem(item) {
      this.dialogCreateForm = true
      this.id = item.id
      this.getCompany()
    }
  }

}
</script>
