import { make } from 'vuex-pathify'
import api from '@/api/department'

const state = {
  departments: [],
  search: null,
  dialogCreateForm: false,
  id: null,
  dialogDelete: false,
  department: null,
  keyword: '',
  departmentType: null

}

const mutations = make.mutations(state)

const actions = {
  initDepartment({ state }) {
    state.department = {
      departmentName: null,
      departmentType: null

    }
  },
  getDepartmentList({ state }) {
    api.getDepartments(state.keyword).then((response) => {
      state.departments = response.data
    })
  },
  deleteDepartment({ state }) {
    // api.deleteProduct(state.id)
    return new Promise((resolve) => {
      api.deleteDepartment(state.id).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  saveDepartment({ state }) {
    if (state.id == null) {
      return new Promise((resolve) => {
        api.postDepartment(state.department).then((response) => {
          resolve(response.data)
        })
      })
    } else {
      return new Promise((resolve) => {
        api.putDepartment(state.id, state.department).then((response) => {
          resolve(response.data)
        })
      })
    }
  },
  getDepartment({ state }) {
    api.getDepartment(state.id).then((response) => {
      state.department = response.data
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
