import { make } from 'vuex-pathify'
import api from '@/api/order'
import _ from 'lodash'
const state = {
  keyword: '',
  orders: [],
  order: null,
  orderForAdmin: [],
  id: null,
  dialogDelete: false,
  withdrawItem: [],
  orderDetails: [],
  loading: false,
  checkNumber: [],
  dialogCheck: false,
  dialogConfirmOrder: false,
  dialogCreateAndSendOrder: false,
  dialogEditAndSendOrder: false,
  dialogSaveOrder: false,
  dialogCompleteOrder: false,
  statusId: 0,
  departmentId: 0,
  date: [],
  status: '',
  dialogCheckComplete: false,
  orderDate: '',
  dialogHistory: false,
  history: [],
  crBy: '',
  item: null,
  payload: {
    page: 1,
    itemsPerPage: 10
  },
  options: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDes: [],
    groupBy: [],
    groupDesc: [],
    mustSort: false,
    multiSort: false
  }
}

const mutations = make.mutations(state)

const actions = {
  initOrder({ state }) {
    state.order = {
      employeeId: null,
      departmentId: 0,
      statusId: 0
    }
  },
  initPayload({ state }) {
    state.payload = {
      page: 1,
      itemsPerPage: 10
    }
    state.options = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDes: [],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false
    }
  },
  getOrderList({ state }, payload) {
    state.loading = true
    const date = {
      startDate: state.date[0] + ',00:00:00',
      endDate: state.date[1] + ',23:59:59'
    }
    return new Promise((resolve) => {
      api.getOrders(state.keyword, state.statusId, state.departmentId, date.startDate, date.endDate, payload.page, payload.itemsPerPage).then((response) => {
        resolve(response.data)
        state.orders = response.data
        state.loading = false
      })
    })
  },
  getOrderForAdminList({ state }, payload) {
    const date = {
      startDate: state.date[0] + ',00:00:00',
      endDate: state.date[1] + ',23:59:59'
    }
    state.loading = true
    api.getOrderForAdmin(state.keyword, state.statusId, state.departmentId, date.startDate, date.endDate, payload.page, payload.itemsPerPage).then((response) => {
      state.orderForAdmin = response.data
      state.loading = false
    })
  },
  saveOrder({ state }) {
    const details = []
    _.forEach(state.orderDetails, function(d) {
      details.push({
        ProductId: d.productId,
        RequestedQty: parseFloat(d.requestQty),
        RemainQty: parseFloat(d.remainQty),
        ApprovedQty: 0
      })
    })
    const data = {
      departmentId: state.order.departmentId,
      note: state.order.note,
      details: details
    }
    return new Promise((resolve) => {
      api.postOrders(data).then((response) => {
        resolve(response.data)
      })
    })
  },
  editOrder({ state }) {
    const details = []
    _.forEach(state.orderDetails, function(d) {
      details.push({
        ProductId: d.productId,
        RequestedQty: parseFloat(d.requestQty),
        RemainQty: parseFloat(d.remainQty),
        ApprovedQty: 0
      })
    })
    const data = {
      id: state.id,
      departmentId: state.order.departmentId,
      documentNumber: state.order.documentNumber,
      fiscalYear: state.fiscalYear,
      orderDate: state.oorderDate,
      note: state.order.note,
      details: details
    }
    return new Promise((resolve) => {
      api.putOrders(state.id, data).then((response) => {
        resolve(response.data)
      })
    })
  },
  createAndSendOrder({ state }) {
    const details = []
    _.forEach(state.orderDetails, function(d) {
      details.push({
        ProductId: d.productId,
        RequestedQty: parseFloat(d.requestQty),
        RemainQty: parseFloat(d.remainQty),
        ApprovedQty: 0
      })
    })
    const data = {
      departmentId: state.order.departmentId,
      note: state.order.note,
      details: details
    }
    return new Promise((resolve) => {
      api.createAndSendOrders(data).then((response) => {
        resolve(response.data)
      })
    })
  },
  editAndSendOrder({ state }) {
    const details = []
    _.forEach(state.orderDetails, function(d) {
      details.push({
        ProductId: d.productId,
        RequestedQty: parseFloat(d.requestQty),
        RemainQty: parseFloat(d.remainQty),
        ApprovedQty: 0
      })
    })
    const data = {
      id: state.id,
      departmentId: state.order.departmentId,
      note: state.order.note,
      details: details
    }
    return new Promise((resolve) => {
      api.editAndSemdOrders(state.id, data).then((response) => {
        resolve(response.data)
      })
    })
  },
  getOrder({ state }) {
    return new Promise((resolve) => {
      api.getOrder(state.id).then((response) => {
        resolve(response.data)
      })
    }
    )
  },
  getHistory({ state }, params) {
    return new Promise((resolve) => {
      api.getHistory(params).then((response) => {
        resolve(response.data)
        state.history = response.data
      })
    }
    )
  },
  approveOrder({ state }) {
    const details = state.orderDetails
    // _.forEach(state.orderDetails, function(d) {
    //   details.push({
    //     ProductId: d.productId,
    //     RequestedQty: parseFloat(d.requestQty),
    //     RemainQty: parseFloat(d.remainQty),
    //     ApprovedQty: parseFloat(d.approvedQty)
    //   })
    // })
    const data = {
      details: details
    }
    return new Promise((resolve) => {
      api.approveOrder(state.id, data).then((response) => {
        resolve(response.data)
      })
    })
  },
  completeOrder({ state }) {
    return new Promise((resolve) => {
      api.completeOrder(state.id).then((response) => {
        resolve(response.data)
      })
    })
  },
  deleteOrder({ state }) {
    return new Promise((resolve) => {
      api.deleteOrder(state.id).then((response) => {
        resolve(response.data)
      })
    })
  },
  getSumaryOrder({ state }) {
    return new Promise((resolve) => {
      api.getSumaryOrder(state.id).then((response) => {
        resolve(response.data)
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
