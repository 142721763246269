<template>
  <div>
    <v-row class="pt-3">
      <v-col cols="8">
        <div class="text-h4 ml-15 my-5">
          ใบเบิกวัสดุแพทย์ โรงพยาบาลอุ้มผาง(sumary)
        </div>
      </v-col>
      <v-spacer />
      <v-col class="d-flex justify-end">
        <v-btn class="ma-1" to="/pickingList">กลับ</v-btn>
        <v-btn class="ma-1 mr-4" color="teal" dark @click="printDoc(item)">พิมพ์เอกสาร</v-btn>
      </v-col>

    </v-row>
    <!-- <v-card class="statusCard ma-5 px-10 pb-6">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <status-send-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-approve-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-complete-order />
        </v-col>
      </v-row>
    </v-card> -->
    <v-form
      v-if="item"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="6">
          <v-card class="infomation ma-5 pa-4" height="155px">
            <v-row>
              <div class="col-3  text-left text-h5">หน่วยงาน</div>
              <div class="col-1  text-h5">:</div>
              <div class="col-6  text-h5"> {{ item.departmentName }} </div>
              <div class="col-3  text-left">ชื่อผู้เบิก</div>
              <div class="col-1 ">:</div>
              <div class="col-6  ">{{ item.employeeName }}</div>
              <div class="col-3   text-left">หมายเหตุ</div>
              <div class="col-1 ">:</div>
              <div class="col-6  ">{{ item.note }}</div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="my-5 mr-5 pa-4" height="155px">
            <v-row>
              <div class="col-4 ">เลขที่เอกสาร</div>
              <div class="col-6 ">{{ item.documentNumber }}</div>
              <div class="col-4 ">วันที่เอกสาร</div>
              <div class="col-6 "> {{ showDate }} </div>
              <div class="col-4 ">สถานะเอกสาร</div>
              <div class="col-6 ">
                <span class="status-box">
                  <v-chip
                    color="#15803d"
                    dark
                    style="background-color: #f0fdf4 !important"
                    outlined
                    @click="history()"
                  >
                    จบกระบวนการแล้ว
                  </v-chip>
                </span>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-5 mb-2 ">
            <v-simple-table>
              <thead>
                <tr class="header">
                  <th class="text-center">
                    ลำดับ
                  </th>
                  <th class="text-center">
                    รายการ
                  </th>
                  <th class="text-center">
                    หน่วยนับ
                  </th>
                  <th class="text-center">
                    จำนวนอนุมัติ
                  </th>
                  <th class="text-center">
                    จำนวนของเบิกคลัง
                  </th>
                  <th class="text-center">
                    จำนวนของ Reuse
                  </th>
                  <th class="text-center">
                    จำนวนที่เบิกได้จริง
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                >
                  <td align="center">
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ row.productName }}
                  </td>
                  <td align="center">
                    {{ row.unitName }}
                  </td>
                  <td align="center">
                    {{ row.approvedQty }}
                  </td>
                  <td align="center">
                    {{ row.pickStoreQty }}
                  </td>
                  <td align="center">
                    {{ row.reusedQty }}
                  </td>
                  <td align="center">
                    {{ row.pickStoreQty + row.reusedQty }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <dialog-history />
    </v-form>

  </div>
</template>
<script>
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
// import StatusSendOrder from '../statusOrders/StatusSendOrder'
// import StatusCompleteOrder from '../statusOrders/StatusCompleteOrder'
// import StatusApproveOrder from '../statusOrders/StatusApproveOrder'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import DialogHistory from '../dialog/DialogHistory'
export default {
  name: 'CompleteOrderForm',
  components: {
    // StatusSendOrder,
    // StatusCompleteOrder,
    // StatusApproveOrder,
    DialogHistory
  },
  data: function() {
    return {
      valid: true,
      date: null,
      statusText: null,
      rows: [
        {
          productId: null,
          unitId: null,
          remainQty: 0,
          requestQty: 0,
          approvedQty: 0,
          pickStockQty: 0,
          reusedQty: 0,
          factQty: 0,
          typeId: 0
        }
      ]
    }
  },
  computed: {
    dialog: sync('stock/dialogCreateForm'),
    item: sync('order/order'),
    snackbars: sync('product/snackbar'),
    id: sync('order/id'),
    stockList: sync('stock/stocks'),
    detail: sync('order/orderDetails'),
    departmentList: sync('department/departments'),
    unitList: sync('unit/units'),
    unitId: sync('product/unitId'),
    productItem: sync('product/productItem'),
    statusList: sync('status/status'),
    status: sync('order/status'),
    employees: sync('employee/employees'),
    dialogHistory: sync('order/dialogHistory'),
    HistoryItems: sync('order/history'),
    ...sync('stock', ['dialogTypePurchase', 'dialogTypeDonate']),
    ...sync('order', ['approver', 'approveDate']),
    showDate() {
      return this.date !== null ? format(parseISO(this.date), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  watch: {
  },
  async created() {
    await this.onCreate()
  },
  mounted() {

  },
  methods: {
    ...call('product', ['getProductList', 'getUnitId']),
    ...call('order', ['completeOrder', 'getSumaryOrder', 'updateStatus', 'getHistory']),
    ...call('department', ['getDepartmentList']),
    ...call('unit', ['getUnitList']),
    ...call('status', ['getStatusList']),
    async onCreate() {
      this.id = this.$route.params.id
      await this.getSumaryOrder().then((response) => {
        this.item = response
        this.rows = response.details
        this.date = response.date
        this.status = response.statusId
        _.forEach(this.rows, function(p) {
          p.factQty = (p.reusedQty + 0) + (p.pickStoreQty + 0)
        })
      })
      await this.getProductList(1).then((response) => {
        this.productItem = response
      })
    },
    async printDoc(row) {
      const baseUrl = `${process.env.VUE_APP_BASE_URL_API}/GenerateDocument/GenerateDocument`
      const id = `/${row.id}`
      window.open(baseUrl + id, '_blank')
    },
    history() {
      this.HistoryItems = []
      this.getHistory(this.$route.params.id)
      this.dialogHistory = true
    },
    sumFactQty(item) {
      if (item) {
        const sum = ((parseFloat(item.pickStockQty)) + parseFloat(item.reusedQty))
        item.factQty = sum
      }
    },
    showDateText(date) {
      return date !== null ? format(parseISO(date), 'dd MMMM yyyy', { locale: th }) : ''
    }
  }

}

</script>
<style lang="scss">
</style>
