import request from '@/util/request'

const api = {
  getOrders: (keyword, status, department, sDate, eDate, page, itemsPerPage) => {
    return request({
      url: `/Orders?keyword=${keyword}&statusId=${status}&departmentId=${department}&startDate=${sDate}&endDate=${eDate}&pageNumber=${page}&pageSize=${itemsPerPage}`,
      method: 'get'
    })
  },
  getOrderForAdmin: (keyword, status, department, sDate, eDate, page, itemsPerPage) => {
    return request({
      url: `/Orders/GetOrderForAdmin?keyword=${keyword}&statusId=${status}&departmentId=${department}&startDate=${sDate}&endDate=${eDate}&pageNumber=${page}&pageSize=${itemsPerPage}`,
      method: 'get'
    })
  },
  getOrder: (id) => {
    return request({
      url: `/Orders/${id}`,
      method: 'get'
    })
  },
  getHistory: (id) => {
    return request({
      url: `/Orders/History/${id}`,
      method: 'get'
    })
  },
  postOrders: (data) => {
    return request({
      url: '/Orders',
      method: 'post',
      data: data
    })
  },
  putOrders: (id, data) => {
    return request({
      url: `/Orders/${id}`,
      method: 'put',
      data: data
    })
  },
  createAndSendOrders: (data) => {
    return request({
      url: '/Orders/createAndSendOrder',
      method: 'post',
      data: data
    })
  },
  editAndSemdOrders: (id, data) => {
    return request({
      url: `/Orders/editAndSendOrder/${id}`,
      method: 'put',
      data: data
    })
  },
  deleteOrder: (id) => {
    return request({
      url: `/Orders/deleteOrder/${id}`,
      method: 'get'
    })
  },
  getSumaryOrder: (id) => {
    return request({
      url: `/Orders/getSumaryOrder/${id}`,
      method: 'get'
    })
  },
  updateStatus: (id) => {
    return request({
      url: `/Orders/updateStatus/${id}`,
      method: 'put'
    })
  },
  pullBackOrder: (id) => {
    return request({
      url: `/Orders/pullBackOrder/${id}`,
      method: 'put'
    })
  },
  approveOrder: (id, params) => {
    return request({
      url: `/Orders/approveOrder/${id}`,
      method: 'put',
      data: params
    })
  },
  checkOrderApprove: (id, params) => {
    const data = {
      details: params
    }
    return request({
      url: `/Orders/checkOrderApprove/${id}`,
      method: 'put',
      data: data
    })
  },
  completeOrder: (id) => {
    return request({
      url: `/Orders/completeOrder/${id}`,
      method: 'put'
    })
  },
  checkOrder: (id, data) => {
    return request({
      url: `/Orders/checkOrder/${id}`,
      method: 'put',
      data: data
    })
  }
}
export default api
