import { make } from 'vuex-pathify'
import api from '@/api/repack'
const state = {
  employees: []
}

const mutations = make.mutations(state)

const actions = {
  getRepacks({ state }) {
    return new Promise((resolve) => {
      api.getRepacks().then((response) => {
        state.employees = response.data
        resolve()
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
