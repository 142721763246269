<template>
  <v-card>
    <v-card-title>
      <div class="text-h4 my-10">
        จัดการข้อมูลในระบบ
      </div>
    </v-card-title>
    <v-list-item-group>

      <v-btn
        v-for="mainMenu in menus"
        :key="mainMenu.name"
        :to="mainMenu.link"
        class="mx-8 my-4"
        width="300px"
        height="60px"
        :color="mainMenu.color"
        dark
      >

        <v-list-item-icon>
          <v-icon> {{ mainMenu.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="mainMenu.text" />
        </v-list-item-content>
      </v-btn>
    </v-list-item-group>
    <!-- <v-list-item-group>
      <v-list-item-title> เมนูจัดการข้อมูล </v-list-item-title>
      <v-list-item
        v-for="menu in manageMenus"
        :key="menu.name"
        :to="menu.link"
      >
        <v-list-item-icon>
          <v-icon> {{ menu.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="menu.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group> -->
  </v-card>
</template>
<script>
export default {
  name: 'SetupMenusView',
  data: () => ({
    menus: [
      {
        icon: 'mdi-view-dashboard-edit',
        text: 'จัดการวัสดุการแพทย์',
        link: '/manageProduct',
        color: 'cyan darken-4'
      },
      {
        icon: 'mdi-domain',
        text: 'จัดการบริษัท',
        link: '/setupCompany',
        color: 'cyan darken-4'
      },
      {
        icon: 'mdi-sitemap',
        text: 'จัดการหน่วยงาน',
        link: '/setupDepartment',
        color: 'cyan darken-4'
      },
      {
        icon: 'mdi-google-circles-communities',
        text: 'จัดการหน่วยนับ',
        link: '/setupUnit',
        color: 'cyan darken-4'
      }
    ]
  })
}
</script>
