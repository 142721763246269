<template>
  <v-card
    style="height: 100%;"
    class="mx-10 px-10 pb-10 "
  >
    <v-card-title>
      <div class="text-h4 mr-9">
        สรุปตามวัสดุแพทย์
      </div>
      <div class="text-h4 mr-9">
        เดือน
      </div>
      <div class="mr-9">
        <v-autocomplete
          v-model="month"
          :items="mounts"
          item-text="text"
          item-value="value"
        />
      </div>
      <div class="text-h4 mr-9">
        ปี
      </div>
      <div class="mr-9">
        <v-autocomplete
          v-model="year"
          :items="yesrs"
          item-text="yearTh"
          item-value="year"
        />
      </div>
      <div class="mr-2">
        <v-btn
          color="teal"
          dark
          @click="search()"
        >
          ค้นหา
        </v-btn>
      </div>
      <div>
        <v-btn
          color="grey"
          dark
          @click="clear()"
        >
          ล้างการค้นหา
        </v-btn>
      </div>
      <v-spacer />
      <div class="mx-2">
        <v-btn
          color="teal"
          dark
          @click="downloadReport()"
        >
          พิมพ์รายงาน
        </v-btn>
      </div>
      <div>
        <v-btn
          color="grey"
          dark
          to="/report"
        >
          กลับ
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-card-title>
        <div class="text-h5 mr-9">
          รายการเบิกจ่าย
        </div>
      </v-card-title>
      <v-card>
        <v-data-table
          :headers="header"
          :items="items"
          :items-per-page="100"
          class="elevation-1"
          sort-by="id"
        />
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
// import { formatISO } from 'date-fns'
import { sync, call } from 'vuex-pathify'
import { getYearNow, getSetYear, getMonthCurrent } from '../../util/index'
export default {
  name: 'ProductReportMonthly',
  components: { },
  data() {
    return {
      header: [
        {
          text: 'รายการ',
          value: 'productName',
          sortable: true,
          width: '400px'
        },
        {
          text: 'จำนวนเบิกทั้งหมด',
          value: 'totalQuantity',
          sortable: true,
          align: 'center',
          width: '200px'
        },
        {
          text: 'กู้ชีพ',
          value: 'd1',
          sortable: false,
          align: 'center',
          width: '100px'
        },
        {
          text: 'ชันสูตร',
          value: 'd2',
          sortable: false,
          align: 'center',
          width: '100px'
        },
        {
          text: 'ไตเทียม',
          value: 'd3',
          sortable: false,
          align: 'center',
          width: '100px'
        },
        {
          text: 'ผู้ป่วยนอก',
          value: 'd4',
          sortable: false,
          align: 'center',
          width: '100px'
        },
        {
          width: '100px',
          text: 'ผู้ป่วยหนัก',
          value: 'd5',
          sortable: false,
          align: 'center'
        },
        {
          width: '100px',
          text: 'เภสัช',
          value: 'd6',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'เยี่ยมบ้าน',
          value: 'd7',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'โรงครัว',
          value: 'd8',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'วิชาการ',
          value: 'd9',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'วิสัญญี',
          value: 'd10',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'ศัลยกรรม',
          value: 'd11',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'ส่งเสริม',
          value: 'd12',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'สุขา',
          value: 'd13',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'หน่วยจ่ายกลาง',
          value: 'd14',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'หลังคลอด',
          value: 'd15',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'ห้องคลอด',
          value: 'd16',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'ห้องผ่าตัด (OR)',
          value: 'd17',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'อายุรกรรม',
          value: 'd18',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'อุบัติเหตุฉุกเฉิน(ER)',
          value: 'd19',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'นุโพ',
          value: 'd20',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'เปิ่งเคลิ่ง',
          value: 'd21',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'แม่กลองคี',
          value: 'd22',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'แม่กลองใหม่',
          value: 'd23',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'แม่จัน',
          value: 'd24',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'แม่จันทะ',
          value: 'd25',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'ร่มเกล้า5',
          value: 'd26',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'เลตองคุ',
          value: 'd27',
          sortable: false,
          align: 'center'
        },
        { width: '100px',
          text: 'หนองหลวง',
          value: 'd28',
          sortable: false,
          align: 'center'
        }
      ],
      mounts: [
        { text: 'มกราคม', value: 1 },
        { text: 'กุมภาพันธ์', value: 2 },
        { text: 'มีนาคม', value: 3 },
        { text: 'เมษายน', value: 4 },
        { text: 'พฤษภาคม', value: 5 },
        { text: 'มิถุนายน', value: 6 },
        { text: 'กรกฎาคม', value: 7 },
        { text: 'สิงหาคม', value: 8 },
        { text: 'กันยายน', value: 9 },
        { text: 'ตุลาคม', value: 10 },
        { text: 'พฤศจิกายน', value: 11 },
        { text: 'ธันวาคม', value: 12 }
      ],
      yesrs: getSetYear(),
      items: []

    }
  },
  computed: {
    ...sync('report', ['year', 'month'])
  },
  created() {
    this.initSearch()
    this.search()
  },

  methods: {
    ...call('report', ['getProductReportMonthly', 'exportProductReport']),
    initSearch() {
      this.year = getYearNow()
      this.month = getMonthCurrent()
      getSetYear()
    },
    async clear() {
      this.initSearch()
      this.getProductReportMonthly().then((response) => {
        this.items = response
      })
      this.onSearch = true
    },
    async search() {
      this.getProductReportMonthly().then((response) => {
        this.items = response
      })
      this.onSearch = true
    },
    async downloadReport() {
      this.exportProductReport().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ExportProductReport.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}

</script>
