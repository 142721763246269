import axios from 'axios'
import { ls } from "@/util/ls";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
  onUploadProgress: function() {}
})

// request interceptor
service.interceptors.request.use(
  config => {
   // do something before request is sent
    if (ls.get("access_token")) {
      // config.headers["Content-Type"] = "application/json";
      // config.headers["Accept"] = "application/json";
      config.headers["Authorization"] = `Bearer ${ls.get("access_token")}`;
      // config.headers["Authorization"] = `sub ${ls.get("user").sub}`;
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText || 'Error'))
    } else {
      return response
    }
  },
  error => {
    console.log(error);
    if(error.response && error.response.status == 401){
      localStorage.clear()
      window.location.href="/login"
    }
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
