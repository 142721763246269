<template>
  <v-dialog
    v-model="dialog"
    max-width="150"
    persistent
  >
    <v-card>
      <v-card-text class="text-center">
        <v-progress-circular :size="70" indeterminate class="teal--text mt-3" />
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'LoadingPage',
  computed: {
    dialog: sync('app/loading')
  },
  methods: {
  }
}
</script>
