<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { sync, call } from 'vuex-pathify'
import { Doughnut } from 'vue-chartjs/legacy'
import _ from 'lodash'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
export default {
  name: 'OutHosChart',
  components: {
    Doughnut
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      setLabels: [],
      color: [],
      chartData: {
        labels: [],
        datasets: []
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    ...sync('report', ['summarizeOutHos', 'onSearch'])
    // setData() { return ['กู้ชีพ', 'ชันสูตร', 'ไตเทียม', 'ผู้ป่วยนอก', 'ผู้ป่วยหนัก'] }
  },
  watch: {
    onSearch(value) {
      if (value === true) {
        this.onCreate()
      }
    }
  },
  created() {
    this.onCreate()
  },
  mounted() {

  },
  methods: {
    ...call('report', ['getSummarizeOutHos']),
    setData() {
      const label = []
      const data = []
      _.forEach(this.summarizeOutHos, function(value) {
        label.push(value.departmentName)
      })
      _.forEach(this.summarizeOutHos, function(value) {
        data.push(value.price)
      })
      this.chartData.labels = label
      this.chartData.datasets = [{
        backgroundColor: this.pushColor(),
        data: data
      }]
      // this.setDatas = data
    },
    randomColor() {
      var letters = '789ABCD'.split('')
      var color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 6)]
      }
      return color
    },
    pushColor() {
      // var letters = '789ABCD'.split('')
      // var color = '#'
      // for (var i = 0; i < 6; i++) {
      //   color += letters[Math.round(Math.random() * 6)]
      // }
      const colors = []
      for (var j = 1; j <= this.summarizeOutHos.length; j++) {
        colors.push(this.randomColor())
      }
      return colors
    },
    onCreate() {
      this.getSummarizeOutHos().then((response) => {
        this.summarizeOutHos = response
        this.setData()
        this.onSearch = false
      })
    }
  }

}
</script>
