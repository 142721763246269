<template>
  <v-card>
    <v-card-title>
      <div class="text-h4 my-10">
        รายงาน
      </div>
    </v-card-title>
    <v-list-item-group>

      <v-btn
        v-for="mainMenu in menus"
        :key="mainMenu.name"
        :to="mainMenu.link"
        class="mx-7 my-4"
        width="450px"
        height="60px"
        :color="mainMenu.color"
        dark
      >

        <v-list-item-icon>
          <v-icon> {{ mainMenu.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="mainMenu.text" />
        </v-list-item-content>
      </v-btn>
    </v-list-item-group>
    <!-- <v-list-item-group>
      <v-list-item-title> เมนูจัดการข้อมูล </v-list-item-title>
      <v-list-item
        v-for="menu in manageMenus"
        :key="menu.name"
        :to="menu.link"
      >
        <v-list-item-icon>
          <v-icon> {{ menu.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="menu.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group> -->
  </v-card>
</template>
<script>
export default {
  name: 'ReportView',
  data: () => ({
    menus: [
      {
        icon: 'mdi-chart-box',
        text: 'รายงานสรุป',
        link: '/summaryReport',
        color: 'cyan darken-4'
      },
      {
        icon: 'mdi-chart-bar',
        text: '(ยังไม่พร้อมใช้)รายงานตามวัสดุแพทย์รายปี',
        link: '/report',
        // link: '/productReport',
        color: 'cyan darken-4'
      },
      {
        icon: 'mdi-chart-bar',
        text: '(ยังไม่พร้อมใช้)รายงานตามวัสดุแพทย์รายเดือน',
        link: '/report',
        // link: '/productReportMonthly',
        color: 'cyan darken-4'
      },
      {
        icon: 'mdi-store',
        text: 'รายงานวัสดุแพทย์คงเหลือ',
        link: '/inventoryReport',
        color: 'cyan darken-4'
      },
      {
        icon: 'mdi-office-building-outline',
        text: 'รายงานการเบิกวัสดุแพทย์แยกหน่วยงาน',
        link: '/summaryDepartmentReport',
        color: 'cyan darken-4'
      }
    ]
  })
}
</script>
