<template>
  <v-dialog v-if="stock && item" v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title v-if="status == 2" align="center" class="text-h4">คุณต้องการเพิ่มวัสดุแพทย์หรือไม่({{ stock.stockNumber }})</v-card-title>
      <v-card-title v-if="status == 1" align="center" class="text-h4">คุณต้องการบันทึกร่างวัสดุแพทย์หรือไม่({{ stock.stockNumber }})</v-card-title>
      <v-list-item
        two-line
        style="margin-left: 30px;"
      >
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            บริษัท : {{ item.companyName }}
          </v-list-item-title>
          <v-list-item-title>วันที่ : {{ showDate }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row align="center">
          <v-col
            align="center"
            class="text-h4"
          >
            รายการที่รับเข้า : {{ item.stockCount }} รายการ
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn color="grey" dark @click="closeDelete">ยกเลิก</v-btn>
        <v-btn
          color="teal"
          :loading="loading"
          dark
          @click="Confirm"
        >
          บันทึก</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
export default {
  name: 'DialogConfirmCreateStock',
  components: {
  },
  data: function() {
    return {
      date: null,
      loading: false
    }
  },
  computed: {
    dialog: sync('stock/dialogCreateForm'),
    ...sync('stock', ['stock', 'item', 'stockDate', 'status']),
    ...sync('department', ['departments']),
    ...sync('employee', ['employees']),
    showDate() {
      return this.stockDate !== null ? format(parseISO(this.stockDate), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  created() {
    this.onCreate()
  },
  methods: {
    ...call('stock', ['saveStock']),
    async Confirm() {
      this.loading = true
      this.saveStock().then(() => {
        this.dialog = false
        this.loading = false
        this.$router.push('/manageStock')
      })
    },
    closeDelete() {
      this.dialog = false
    },
    onCreate() {
    }
  }
}
</script>

  <style>
  .card-item{
    margin: 30px;

  }
  </style>

