import request from '@/util/request'
const api = {
  getDepartments: (keyword) => {
    return request({
      url: `/Departments?keyword=${keyword}`,
      method: 'get'
    })
  },
  getDepartmentType: () => {
    return request({
      url: '/Departments/Types',
      method: 'get'
    })
  },
  getDepartment: (id) => {
    return request({
      url: `/Departments/${id}`,
      method: 'get'

    })
  },
  postDepartment: (data) => {
    return request({
      url: '/Departments',
      method: 'post',
      data: data
    })
  },
  putDepartment: (id, data) => {
    return request({
      url: `/Departments/${id}`,
      method: 'put',
      data: data
    })
  },
  deleteDepartment: (id) => {
    return request({
      url: `/Departments/deleteDepartment/${id}`,
      method: 'put'
    })
  }
}
export default api
