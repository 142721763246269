import request from '@/util/request'
const api = {
  getSummarizeInHos: (month, year) => {
    return request({
      url: `Report/GetSummarizeInHos?month=${month}&year=${year}`,
      method: 'get'
    })
  },
  getSummarizeOutHos: (month, year) => {
    return request({
      url: `Report/GetSummarizeOutHos?month=${month}&year=${year}`,
      method: 'get'
    })
  },
  getSummarize: (data) => {
    return request({
      url: `Report?previousMonth=${data.previousMonth}&month=${data.month}&year=${data.year}`,
      method: 'get'
    })
  },
  getSummerizeDepartment: (data) => {
    return request({
      url: `Report/getSummerizeDepartment?year=${data.year}&month=${data.month}`,
      method: 'get'
    })
  },
  getSummarizeChart: (data) => {
    return request({
      url: `Report/Chart?year=${data.year}&month=${data.month}`,
      method: 'get'
    })
  },
  getProductReport: (year) => {
    return request({
      url: `Report/ProductReport?year=${year}`,
      method: 'get'
    })
  },
  getProductReportMonthly: (month, year) => {
    return request({
      url: `Report/ProductReportMonthly?year=${year}&month=${month}`,
      method: 'get'
    })
  },
  exportProductReport: (year) => {
    return request({
      url: `Report/exportProductReport?year=${year}`,
      method: 'get',
      responseType: 'blob'
    })
  },
  exportSummaryReport: (mount, year) => {
    return request({
      url: `Report/exportSummaryReport?mount=${mount}&year=${year}`,
      method: 'get',
      responseType: 'blob'
    })
  },
  exportSummaryDepartmentReport: (month, year) => {
    return request({
      url: `Report/exportSummaryDepartmentReport?month=${month}&year=${year}`,
      method: 'get',
      responseType: 'blob'
    })
  }
}
export default api
