import request from '@/util/request'
const api = {
  getRepacks: () => {
    const params = {
      response_type: 'code',
      client_id: 'Agha089QAMbp45bIJk5rkM',
      redirect_uri: 'https://localhost:5556/repack',
      scope: 'notify',
      state: '12345abcde'
    }
    return request({
      url: `/https://notify-bot.line.me/oauth/authorize?response_type=${params.response_type}&client_id=Agha089QAMbp45bIJk5rkM
      &redirect_uri=https://localhost:5556/repack&scope=notify&state=${params.state}`,
      method: 'get'
    })
  }
}
export default api
