import { make } from 'vuex-pathify'
import api from '@/api/status'
const state = {
  status: []

}

const mutations = make.mutations(state)

const actions = {

  getStatusList({ state }) {
    api.getStatus().then((response) => {
      state.status = response.data
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
