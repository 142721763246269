<template>
  <div>
    <div v-if="status == 'APPROVED' || status == 'SENDED' || status == 'DRAFTED' || status == ''">
      <div class="status d-flex justify-center align-center">
        <v-icon x-large>
          mdi-checkbox-marked-circle
        </v-icon>
      </div>
      <v-row
        justify="center"
        align="center"
      >
        <v-chip color="grey">เสร็จสิ้นแล้ว</v-chip>
      </v-row>
    </div>
    <div v-if="status == 'FINISHED'">
      <div class="status-active d-flex justify-center align-center">
        <v-icon x-large color="teal lighten-2">
          mdi-checkbox-marked-circle
        </v-icon>
      </div>
      <v-row
        justify="center"
        align="center"
      >
        <v-chip color="teal lighten-2" dark>เสร็จสิ้นแล้ว</v-chip>
      </v-row>
    </div>

  </div>
</template>
<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'StatusCompleteOrder',
  data: function() {
    return {
      eName: null
    }
  },
  computed: {
    ...sync('order', ['status', 'order']),
    ...sync('employee', ['employees'])
    // getEmployeeName() {
    //   var employeeList = this.employee
    // }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" >
.status{
  height: 80px;
  width: 80px;
  border: solid grey;
  border-radius: 50%;
}
</style>
