import request from '@/util/request'
const api = {
  getInventories: (keyword) => {
    return request({
      url: `/Inventories?keyword=${keyword}`,
      method: 'get'
    })
  }
}
export default api
