<template>
  <v-dialog v-if="order" v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title align="center" class="text-h5">คุณต้องการอนุมัติเอกสารหรือไม่({{ order.documentNumber }})</v-card-title>
      <v-list-item
        two-line
        style="margin-left: 30px;"
      >
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            หน่วยงาน : {{ order.departmentName }}
          </v-list-item-title>
          <v-list-item-subtitle>ชื่อผู้เบิก {{ order.employeeName }}</v-list-item-subtitle>
          <v-list-item-subtitle>วันที่ : {{ showDate }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row align="center">
          <v-col
            align="center"
            class="text-h4"
          >
            รายการเบิก : {{ order.orderCount }} รายการ
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn color="grey" dark @click="closeDelete">ยกเลิก</v-btn>
        <v-btn color="teal" dark @click="Confirm">บันทึก</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
export default {
  name: 'DialogConfirmApproveOrder',
  components: {
  },
  data: function() {
    return {
      date: null
    }
  },
  computed: {
    dialog: sync('order/dialogConfirmOrder'),
    ...sync('order', ['details', 'order', 'item']),
    ...sync('department', ['departments']),
    ...sync('employee', ['employees']),
    showDate() {
      return this.order.date !== null ? format(parseISO(this.order.date), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  created() {
    this.onCreate()
  },
  methods: {
    ...call('order', ['editOrder', 'approveOrder', 'updateStatus', 'getOrderList']),
    async Confirm() {
      this.approveOrder().then((response) => {
        this.getOrderList()
        this.dialog = false
        this.$router.push('/pickingList')
      })
    },
    closeDelete() {
      this.dialog = false
    },
    onCreate() {
    }
  }
}
</script>

<style>
.card-item{
  margin: 30px;

}
</style>
