import request from '@/util/request'
const api = {
  getUnits: (keyword) => {
    return request({
      url: `/Units?keyword=${keyword}`,
      method: 'get'
    })
  },
  getUnit: (id) => {
    return request({
      url: `/Units/${id}`,
      method: 'get'

    })
  },
  postUnit: (data) => {
    return request({
      url: '/Units',
      method: 'post',
      data: data
    })
  },
  putUnit: (id, data) => {
    return request({
      url: `/Units/${id}`,
      method: 'put',
      data: data
    })
  },
  deleteUnit: (id) => {
    return request({
      url: `/Units/deleteUnit/${id}`,
      method: 'put'
    })
  }
}
export default api
