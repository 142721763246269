<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',

  components: {

  },
  data: () => ({
    //
  }),
  created() {
  },
  mounted() {
  }
}
</script>
