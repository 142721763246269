<template>
  <v-card>
    <v-card-title>
      <div class="text-h5">
        จัดการข้อมูลบริษัท
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="grey"
          class="ma-2 dark--text"
          dark
          to="/setup"
        >
          <v-icon dark>
            mdi-arrow-left-bold
          </v-icon>
          กลับ
        </v-btn>
        <v-btn
          color="teal"
          class="ma-2"
          dark
          @click="createForm()"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          เพิ่ม
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-text-field
        v-model="keyword"
        append-icon="mdi-magnify"
        label="ค้นหา"
        single-line
        hide-details
        color="teal"
      />
      <v-spacer />
    </v-card-title>
    <company-table />
    <setup-company-form />
    <dialog-delete-company />
    <success />
    <delete-success />
  </v-card>

</template>

<script>
import SetupCompanyForm from '../../../components/setupForm/SetupCompanyForm'
import CompanyTable from '../../../components/tables/CompanyTable'
import DialogDeleteCompany from '../../../components/dialog/DialogDeleteCompany'
import Success from '../../../components/snackbar/Success'
import DeleteSuccess from '../../../components/snackbar/DeleteSuccess'
import { sync, call } from 'vuex-pathify'
export default {
  name: 'SetupCompany',
  components: {
    CompanyTable,
    SetupCompanyForm,
    DialogDeleteCompany,
    Success,
    DeleteSuccess

  },
  data: () => ({

  }),
  watch: {

  },
  created() {
    this.getCompanyList()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...sync('company', ['dialogCreateForm', 'keyword', 'id'])
  },

  methods: {
    ...call('company', ['getCompanyList', 'initCompany']),
    createForm() {
      this.dialogCreateForm = true
      this.initCompany()
      this.id = 0
    }
  }
  // eslint-disable-next-line vue/order-in-components

}
</script>
