<template>
  <v-data-table
    :headers="header"
    :items="orders.items"
    class="elevation-1"
    :loading="loading"
    :options.sync="options"
    :server-items-length="orders.totalCount"
    :footer-props="{'items-per-page-options':[10,20, 30, 50, 100]}"
  >
    <template #item.date="{ item }">
      {{ item.date | showDate }}
    </template>
    <template #item.actions="{ item }">

      <v-menu
        bottom
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="editItem(item)">
            <v-icon
              color="teal"
              class="mr-2"
            >
              mdi-file-document
            </v-icon>
            แก้ไขเอกสาร
          </v-list-item>
          <v-list-item @click="duplicate(item)">
            <v-icon
              color="teal"
              class="mr-2"
            >
              mdi-content-duplicate
            </v-icon>
            สร้างเอกสารใหม่(รายการเหมือนเดิม)
          </v-list-item>
          <v-list-item v-if="item.statusCode === 'DRAFTED'" @click="cancelOrder(item)">
            <v-icon
              class="mr-2"
              color="red darken-4"
              align="start"
            >
              mdi-delete
            </v-icon>
            ยกเลิกเอกสาร
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template #item.status="{ item }">
      <v-chip
        :color="getColor(item.statusCode)"
        dark
        outlined
        :style="{'background-color':
          getBgColor(item.statusCode)+
          '!important'
        }"
      >
        {{ item.statusName }}
      </v-chip>
    </template>
    <dialog-delete-order />
  </v-data-table>
</template>
<script>

import { sync, call } from 'vuex-pathify'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import DialogDeleteOrder from '../dialog/DialogDeleteOrder'
import { getColor, getBgColor } from '../../util/index'
export default {
  name: 'OrderTable',
  filters: {
    showDate: function(value) {
      return value !== null ? format(parseISO(value), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  components: {
    DialogDeleteOrder
  },
  data: function(payload) {
    return {
      // options: {
      //   page: null,
      //   itemsPerPage: null,
      //   sortBy: [],
      //   sortDes: [],
      //   groupBy: [],
      //   groupDesc: [],
      //   mustSort: false,
      //   multiSort: false
      // },
      header: [
        {
          text: 'วันที่',
          value: 'date',
          sortable: true
        },
        {
          text: 'เลขที่เอกสาร',
          value: 'documentNumber',
          sortable: true
        },
        {
          text: 'ชื่อผู้เบิก',
          value: 'employeeName',
          sortable: true
        },
        {
          text: 'แผนก',
          value: 'departmentName',
          sortable: true
        },
        {
          text: 'สถานะ',
          value: 'status',
          sortable: false
        },
        {
          text: 'Action',
          width: '8rem',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...sync('order', ['orders', 'keyword', 'id', 'loading', 'dialogDelete', 'payload', 'options'])
  },
  watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getOrderFormApi()
      } else if (value.length === 0) this.getOrderFormApi()
    },
    // options: {
    //   handler() {
    //     this.getOrderFormApi()
    //   },
    //   deep: true
    // },
    options(val) {
      console.log(val)
      this.getOrderFormApi()
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    ...call('order', ['getOrderList', 'deleteOrder']),
    editItem(item) {
      this.id = item.id
      this.$router.push({ path: `/EditOrderForm/${item.id}` })
    },
    duplicate(item) {
      this.id = item.id
      this.$router.push({ path: `/actionOrder/${item.id}` })
    },
    cancelOrder(item) {
      this.id = item.id
      this.dialogDelete = true
    },
    getColor(status) {
      return getColor(status)
    },
    getBgColor(status) {
      return getBgColor(status)
    },
    getOrderFormApi() {
      const { page, itemsPerPage } = this.options
      this.payload = {
        page: page,
        itemsPerPage: itemsPerPage
      }
      this.getOrderList(this.payload).then(() => {
      })
    }

  }

}
</script>
