// Utilities
import { make } from 'vuex-pathify'
import { ls } from '../../util/ls'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
const state = {
  name: 'ระบบจัดการครุภัณฑ์',
  version: '0.0.8',
  dialogLogin: false,
  roleId: '',
  employeeId: '',
  loading: false
}

const mutations = make.mutations(state)

const actions = {
  openLogin({ state }) {
    state.dialogLogin = true
  },
  checkRole({ state }) {
    state.roleId = ls.get('role')
  },
  currentDate({ state }) {
    var expirationDate = ls.get('expiration')
    expirationDate !== null ? format(parseISO(expirationDate), 'dd MMMM yyyy,HH:MM:SS', { locale: th }) : ''
  },
  expiDate({ state }) {
    state.roleId = ls.get('role')
  },
  getColor({ status }) {
    switch (status) {
      case 'DRAFTED':return 'grey'
      case 'SENDED':return '#2A6B63'
      case 'APPROVED':return '#45E1BD'
      case 'FINISHED':return '#9E5FD5'
    }
  }

}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
