import request from '@/util/request'
const api = {
  getStatus: () => {
    return request({
      url: `/Status`,
      method: 'get'
    })
  }
}
export default api
