import request from '@/util/request'
const api = {
  getDepartmentTypes: () => {
    return request({
      url: `/DepartmentTypes`,
      method: 'get'
    })
  }
}
export default api
