<template>
  <v-data-table
    :headers="header"
    :items="checkNumber"
    class="elevation-1"
    sort-by="id"
    hide-default-footer
  >
    <template #item.num="{ index }">
      <div>
        {{ index+1 }}
      </div>
    </template>
    <template #item.status="{ item }">
      <v-icon
        v-if="item.quantityApprove > item.numberDonate && item.type == 1"
        color="#D09344"
        dark
        @click="changeApprove(item)"
      >
        mdi-alert
      </v-icon>
      <v-icon
        v-else-if="item.quantityApprove <= item.numberDonate && item.type == 1"
        color="teal accent-4"
        dark
        @click="changeApprove(item)"
      >
        mdi-check-underline-circle
      </v-icon>
      <v-icon
        v-else-if="item.quantityApprove > item.numberPurchase && item.type == 2"
        color="#D09344"
        dark
        @click="changeApprove(item)"
      >
        mdi-alert
      </v-icon>
      <v-icon
        v-else-if="item.quantityApprove <= item.numberPurchase && item.type == 2"
        color="teal accent-4"
        dark
        @click="changeApprove(item)"
      >
        mdi-check-underline-circle
      </v-icon>
      <v-icon
        v-else-if="item.type == 0 && item.quantityApprove > item.numberPurchase + item.numberDonate"
        color="#D09344"
        dark
        @click="changeApprove(item)"
      >
        mdi-alert
      </v-icon>
      <v-icon
        v-else-if="item.type == 0 && item.quantityApprove <= item.numberPurchase + item.numberDonate"
        color="teal accent-4"
        dark
        @click="changeApprove(item)"
      >
        mdi-check-underline-circle
      </v-icon>
      <v-icon
        v-else
        color="red"
        dark
        @click="changeApprove(item)"
      >
        mdi-alert
      </v-icon>
    </template>
    <template #item.type="{ item }">
      <v-radio-group v-model="item.type">
        <v-radio
          label="ทั้งหมด"
          :value="0"
        />
        <v-radio
          label="ของบริจาค"
          :value="1"
        />
        <v-radio
          label="ของซื้อ"
          :value="2"
        />
      </v-radio-group>
    </template>
  </v-data-table>

</template>
<script>

import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
export default {
  name: 'CheckNumberTable',
  data: () => ({
    header: [
      {
        text: 'ลำดับ',
        value: 'num',
        sortable: true,
        align: 'center'
      },
      {
        text: 'รายการ',
        value: 'productName',
        sortable: true
      },
      {
        text: 'จำนวนที่อนุมัติ',
        value: 'quantityApprove',
        sortable: false,
        align: 'center'
      },
      {
        text: 'ของบริจาค',
        value: 'numberDonate',
        sortable: false,
        align: 'center'
      },
      {
        text: 'ของซื้อ',
        value: 'numberPurchase',
        sortable: false,
        align: 'center'
      },
      {
        text: 'สถาณะ',
        value: 'status',
        sortable: false,
        align: 'center'
      },
      {
        text: 'ประเภท',
        value: 'type',
        sortable: false,
        align: 'center'
      }

    ]
  }),

  computed: {
    ...sync('order', ['checkNumber', 'orderDetails'])

  },
  created() {
    this.onCreate()
  },
  mounted() {
  },
  methods: {
    ...call('company', ['getCompanyList', 'getCompany']),
    ...call('product', ['getProductList']),
    onCreate() {
      this.checkNumber.type = 0
    },
    changeApprove(item) {
      const approve = _.find(this.orderDetails, function(o) { return o.productId === item.productId })
      const approveInItem = _.find(this.checkNumber, function(o) { return o.productId === item.productId })
      if (item.type === 1 && item.quantityApprove > item.numberDonate) {
        approve.quantityApprove = item.numberDonate
        approveInItem.quantityApprove = item.numberDonate
      } else if (item.type === 2 && item.quantityApprove > item.numberPurchase) {
        approve.quantityApprove = item.numberPurchase
        approveInItem.quantityApprove = item.numberPurchase
      } else if (item.type === 0 && item.quantityApprove > (item.numberPurchase + item.numberDonate)) {
        approve.quantityApprove = item.numberPurchase + item.numberDonate
        approveInItem.quantityApprove = item.numberPurchase + item.numberDonate
      }
    },
    onApprove() {
      const details = this.orderDetails
      const checkNumber = this.this.checkNumber
      _.forEach(checkNumber, function(r) {
        const approve = _.find(details, function(o) { return o.productId === r.productId })
        approve.type = r.type
      })
    }
  }

}
</script>
