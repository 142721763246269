<template>
  <div v-if="order">
    <v-row class="pt-3">
      <v-col cols="8">
        <div class="text-h4 ml-15 my-5">
          ใบเบิกวัสดุแพทย์ โรงพยาบาลอุ้มผาง
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn class="ma-1 mr-5" to="/orderView">กลับ</v-btn>
          <v-menu
            v-if="order.statusCode == 'DRAFTED'"
            offset-y
            buttom
          >
            <template #activator="{ on, attrs }">
              <div class="split-btn mr-3">
                <v-btn class="mt-1 px-3 main-btn" color="teal" dark @click="onSend(rows)">ส่งใบเบิก</v-btn>
                <v-btn class="mt-1 px-1 actions-btn" color="teal " dark v-bind="attrs" v-on="on"><v-icon left>mdi-menu-down</v-icon></v-btn>
              </div>
            </template>
            <v-list>
              <v-list-item @click="onSave(rows)">
                <v-list-item-title>บันทึกร่าง</v-list-item-title>
              </v-list-item>
              <v-list-item @click="cancelOrder(order)">
                <v-list-item-title class="ma-1" color="red darken-4" dark>ยกเลิกเอกสาร</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-card class="statusCard ma-5 px-10 pb-6">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <status-send-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-approve-order />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <status-complete-order />
        </v-col>
      </v-row>
    </v-card>
    <v-form
      v-if="order"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>

        <v-col cols="7">
          <v-card class="infomation ma-5 pa-4" height="200px">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="order.departmentId"
                    label="หน่วยงาน"
                    :items="departmentList"
                    item-text="departmentName"
                    item-value="id"
                    color="teal"
                    :rules="[v => !!v || 'กรุณาเลือกหน่วยงาน']"
                    :readonly="checkDisable()"
                  />
                  <v-text-field
                    v-model="order.note"
                    label="หมายเหตุ"
                    :rules="rules"
                    hide-details="auto"
                    color="teal"
                  />
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="6">
                  ชื่อผู้เบิก:
                  {{ order.employeeName }}
                </v-col>
                <!-- <v-col cols="6">
                  <v-text-field
                    v-model="order.position"
                    color="teal"
                    label="ตำแหน่ง"
                    :readonly="checkDisable()"
                  />
                </v-col> -->
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card class="my-5 mr-5 pa-4" height="200px">
            <v-row>
              <div class="col-4 mt-2">เลขที่เอกสาร</div>
              <div class="col-6 mt-2">{{ order.documentNumber }}</div>
              <div class="col-4 mt-2">วันที่เอกสาร</div>
              <div class="col-6 mt-2"> {{ showDate }} </div>
              <div class="col-4 mt-2">สถานะเอกสาร</div>
              <div class="col-6 mt-2">
                <span class="status-box">
                  <v-chip
                    :color="getColor(status)"
                    dark
                    outlined
                    :style="{'background-color':
                      getBgColor(status)+
                      '!important'
                    }"
                    @click="history()"
                  >
                    {{ statusText }}
                  </v-chip>
                </span>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-5">
            <v-simple-table>
              <thead>
                <tr class="header">
                  <th style="width: 50px">
                    ลำดับ
                  </th>
                  <th>
                    รายการ
                  </th>
                  <th style="width: 150px;">
                    หน่วยนับ
                  </th>
                  <th style="width: 150px;">
                    จำนวนคงเหลือ
                  </th>
                  <th style="width: 150px;">
                    จำนวนเบิก
                  </th>
                  <th v-if="order.statusCode == 'APPROVED'" style="width: 150px;">
                    จำนวนที่อนุมัติ
                  </th>
                  <th v-if="order.statusCode == 'FINISHED'" style="width: 150px;">
                    จำนวนที่เบิกได้จริง
                  </th>
                  <th v-if="order.statusCode == 'DRAFTED' " style="width: 100px;">
                    action
                    <v-btn

                      color="teal"
                      dark
                      absolute
                      bottom
                      right
                      fab
                      @click="addRows()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                >
                  <td align="center">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="row.productId"
                      class="pt-6"
                      color="teal"
                      outlined
                      dense
                      :items="productItem"
                      item-text="productName"
                      item-value="id"
                      item-disabled="disable"
                      :rules="[v => !!v || 'กรุณาเลือกรายการ']"
                      :readonly="checkDisable()"
                      @change="choseProduct(row)"
                    >
                      <template #item="data">
                        <v-row>
                          <v-col>
                            <div class="text">
                              {{ data.item.productName }}
                            </div>
                          </v-col>
                        </v-row>

                        <div class="text">
                          {{ data.item.unitName }}
                        </div>

                      </template>
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="row.unitId"
                      class="pt-6"
                      color="teal"
                      outlined
                      dense
                      :items="unitList"
                      item-text="unitName"
                      item-value="id"
                      :rules="[v => !!v || 'กรุณาเลือกหน่วยนับ']"
                      readonly
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="row.remainQty"
                      color="teal"
                      outlined
                      dense
                      class="pt-6"
                      type="number"
                      :rules="[v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) || 'กรุณากรอกจำนวนคงเหลือ']"
                      :readonly="checkDisable()"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="row.requestQty"
                      color="teal"
                      outlined
                      dense
                      class="pt-6"
                      type="number"
                      :rules="[v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) || 'กรุณากรอกจำนวนเบิก']"
                      :readonly="checkDisable()"
                    />
                  </td>
                  <td v-if="order.statusCode == 'APPROVED'">
                    {{ row.approvedQty }}
                  </td>
                  <td v-if="order.statusCode == 'FINISHED'" class="text-center">
                    {{ row.pickStockQty + row.reusedQty }}
                  </td>
                  <td v-if="order.statusCode == 'DRAFTED' ">
                    <v-icon
                      color="red darken-4"
                      @click="deleteRow(row)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

      </v-row>
    </v-form>
    <dialog-history />
    <dialog-delete-order />
    <dialog-edit-and-send-order />

  </div>
</template>
<script>
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
import StatusSendOrder from '../statusOrders/StatusSendOrder'
import StatusCompleteOrder from '../statusOrders/StatusCompleteOrder'
import StatusApproveOrder from '../statusOrders/StatusApproveOrder'
import { formatISO, parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import DialogHistory from '../dialog/DialogHistory'
import { ls } from '../../util/ls'
import DialogDeleteOrder from '../dialog/DialogDeleteOrder'
import { getColor, getBgColor } from '../../util/index'
import DialogEditAndSendOrder from '../dialog/DialogEditAndSendOrder'
export default {
  name: 'EditOrderForm',
  components: {
    StatusSendOrder,
    StatusCompleteOrder,
    StatusApproveOrder,
    DialogHistory,
    DialogDeleteOrder,
    DialogEditAndSendOrder
  },
  data: function() {
    return {
      icon: '',
      valid: true,
      date: null,
      statusText: null,
      rows: [
        {
          productId: null,
          unitId: null,
          remainQty: 0.00,
          requestQty: 0.00,
          approvedQty: 0.00,
          pickStockQty: 0.00,
          reusedQty: 0.00,
          factQty: 0.00
        }
      ],
      rules: {
        remainning: [val => (val || '').length > 0 || 'กรุณากรอกจำนวนคงเหลือ'],
        quantity: [val => (val || '').length > 0 || 'กรุณากรอกจำนวนเบิก']
      }
    }
  },
  computed: {
    dialog: sync('stock/dialogCreateForm'),
    order: sync('order/order'),
    item: sync('order/item'),
    snackbars: sync('product/snackbar'),
    id: sync('order/id'),
    stockList: sync('stock/stocks'),
    detail: sync('order/orderDetails'),
    withdraws: sync('order/withdraws'),
    withdrawId: sync('withdrawDetail/withdrawId'),
    departmentList: sync('department/departments'),
    search: sync('department/search'),
    unitList: sync('unit/units'),
    unitId: sync('product/unitId'),
    productItem: sync('product/productItem'),
    dialogCheck: sync('order/dialogCheck'),
    orderDate: sync('order/orderDate'),
    statusList: sync('status/status'),
    status: sync('order/status'),
    employees: sync('employee/employees'),
    dialogHistory: sync('order/dialogHistory'),
    crBy: sync('order/crBy'),
    historyItem: sync('order/history'),
    dialogDelete: sync('order/dialogDelete'),
    fiscalYear: sync('order/fiscalYear'),
    dialogConfirmSendOrder: sync('order/dialogEditAndSendOrder'),
    showDate() {
      return this.date !== null ? format(parseISO(this.date), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  watch: {
    // 'row.stockId': function(newValue) {
    //   if (newValue !== null) {
    //     this.getUnitId(newValue).then((response) => {
    //       this.row.unitId = response
    //     })
    //   }
    // }
  },

  created() {
    this.onCreate()
  },
  mounted() {

  },
  methods: {
    ...call('product', ['getProductList', 'getUnitId']),
    ...call('order', ['getOrderList', 'editOrder', 'initOrder', 'getOrder', 'updateStatus', 'getHistory', 'pullBackOrder']),
    ...call('department', ['getDepartmentList']),
    ...call('unit', ['getUnitList']),
    ...call('status', ['getStatus']),
    addRows() {
      const rowItem = _.find(this.rows, function(o) { return o.productId === null })
      if (!rowItem) {
        this.rows.push({
          productId: null,
          unitId: null,
          remainQty: 0.00,
          requestQty: 0.00,
          approvedQty: 0.00,
          pickStockQty: 0.00,
          reusedQty: 0.00,
          factQty: 0.00
        })
      }
    },
    deleteRow(item) {
      const indexItem = this.rows.indexOf(item)
      if (item.productId !== null) {
        const Item = _.find(this.productItem, function(o) { return o.id === item.productId })
        Item.disable = false
        this.rows.splice(indexItem, 1)
      } else {
        this.rows.splice(indexItem, 1)
      }
    },
    initDate() {
      var eDate = formatISO(new Date(), { representation: 'date' })
      this.date = eDate
    },
    onBack() {
      this.getProductList(1)
    },
    async onSave(rows) {
      const rowItem = _.find(this.rows, function(o) { return o.productId === null })
      if (rowItem) {
        const indexItem = this.rows.indexOf(rowItem)
        this.rows.splice(indexItem, 1)
        this.detail = rows
        this.crBy = ls.get('id')
        this.editOrder().then((response) => {
          this.$router.push('/orderView')
        })
      }
      if (this.$refs.form.validate() === true) {
        this.detail = rows
        this.crBy = ls.get('id')
        this.editOrder().then((response) => {
          this.$router.push('/orderView')
        })
      }
    },
    onSend(rows) {
      const rowItem = _.find(this.rows, function(o) { return o.productId === null })
      if (rowItem) {
        const indexItem = this.rows.indexOf(rowItem)
        this.rows.splice(indexItem, 1)
        this.dialogConfirmSendOrder = true
        this.orderDate = this.data
        this.detail = rows
        const od = this.order
        this.item = {
          departmentName: _.find(this.departmentList, function(d) { return d.id === od.departmentId }).departmentName,
          employeeName: od.employeeName,
          orderCount: Object.keys(rows).length
        }
        this.crBy = ls.get('id')
      }
      if (this.$refs.form.validate() === true) {
        this.dialogConfirmSendOrder = true
        this.orderDate = this.data
        this.detail = rows
        const od = this.order
        this.item = {
          departmentName: _.find(this.departmentList, function(d) { return d.id === od.departmentId }).departmentName,
          employeeName: od.employeeName,
          orderCount: Object.keys(rows).length
        }
        this.crBy = ls.get('id')
      }
    },
    async onCreate() {
      this.id = this.$route.params.id
      this.getOrder().then((response) => {
        // if (response.statusCode === 'DRAFTED') {
        this.order = response
        this.rows = response.detail
        this.date = response.date
        this.status = response.statusCode
        this.statusText = response.statusName
        // }
        // else if (response.statusCode === 'FINISHED') {
        //   this.$router.push(`/sumaryOrderForm/${this.$route.params.id}`)
        // }
        // else if (response.statusCode === 'APPROVED') {
        //   this.$router.push(`/completeOrderForm/${this.$route.params.id}`)}
        // } else if (response.statusCode === 'SENDED') {
        //   this.$router.push(`/approveOrderForm/${this.$route.params.id}`)
        // }
      })
      this.getDepartmentList()
      this.getUnitList()
      this.getProductList(1).then((response) => {
        this.productItem = response
        const itemProduct = this.productItem
        // _.forEach(itemProduct, function(p) { p.disable = false })
        _.forEach(this.rows, function(r) {
          if (r.productId !== null) {
            const itemInRows = _.find(itemProduct, function(f) { return f.id === r.productId })
            itemInRows.disable = true
          }
        })
      })
    },
    choseProduct(item) {
      // create disable
      const itemProduct = this.productItem
      const rowItem = _.find(this.rows, function(o) { return o.productId === item.productId })
      if (item.productId === rowItem.productId) {
        // set unit
        const unitItem = _.find(itemProduct, function(o) { return o.id === item.productId })
        rowItem.unitId = unitItem.unitId
        // set disable switch
        _.forEach(itemProduct, function(p) { p.disable = false })
        _.forEach(this.rows, function(r) {
          const itemInRows = _.find(itemProduct, function(f) { return f.id === r.productId })
          itemInRows.disable = true
        })
        // const disable = _.find(this.rows, function(o) { return o.productId === item.productId })
      }

      // const rowItem = this.rows.indexOf(item)'
    },
    getColor(status) {
      return getColor(status)
    },
    getBgColor(status) {
      return getBgColor(status)
    },
    checkDisable() {
      if (this.order.statusCode !== 'DRAFTED') {
        return true
      } else return false
    },
    history() {
      this.historyItem = ''
      this.getHistory(this.$route.params.id)
      this.dialogHistory = true
    },
    pullBack() {
      this.pullBackOrder(this.item.id).then(() => {
        this.onCreate()
      })
    },
    cancelOrder(item) {
      this.id = item.id
      this.dialogDelete = true
    }

  }

}

</script>
<style lang="scss">
</style>
