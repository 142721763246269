<template>
  <v-container>
    <v-card-title>
      <div class="text-h5">
        Repack
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="teal"
          class="ma-2 dark--text"
          dark
          to="/repack"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          repack
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-row>
        <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          label="ค้นหา"
          single-line
          hide-details
          color="teal"
          class="pr-5 mr-15"
        />
      </v-row>

      <v-row>
        <v-col
          cols="15"
          lg="4"
          class="d-flex align-center"
        >
          <v-menu
            ref="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="วันที่"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                color="teal"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              color="teal"
              no-title
              locale="th"
              range
              @change="onSearch"
            />
          </v-menu>
        </v-col>
        <v-col
          slot="end"
          class="d-flex align-center justify-end"
        >
          <v-btn
            slot="end"
            color="teal"
            dark
            @click="onInit()"
          >ล้าง</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                ProductName
              </th>
              <th class="text-left">
                ProductName(new)
              </th>
              <th>
                action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in desserts"
              :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.calories }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

  </v-container>

</template>

<script>

export default {
  name: 'RepackList',
  components: {
  },
  data: () => ({

  }),
  watch: {

  },
  created() {
    this.onCreate()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {

  },

  methods: {}

  // eslint-disable-next-line vue/order-in-components

}
</script>

