import { make } from 'vuex-pathify'
import api from '@/api/stockDetail'
const state = {
  keyword: '',
  stocks: [],
  dialogDelete: false,
  dialogCreateForm: false,
  stockDetail: null,
  billId: 0,
  snackbar: false,
  stockNumber: null,
  stockInBill: [],
  loading: false,
  date: [],
  dialogEditStock: false,
  editData: [],
  productId: 0,
  stock: null,
  dialogTypeDonate: false,
  dialogTypePurchase: false,
  inventory: []

}

const mutations = make.mutations(state)

const actions = {
  initStock({ state }) {
    state.stock = {
      id: 0,
      productId: null,
      quantity: null,
      address: null,
      company: null,
      price: null,
      serailNumber: null,
      stockNumber: null
    }
  },
  initEditStock({ state }) {
    state.stockDetail = {
      stockNumber: '',
      price: 0
    }
  },
  getStockList({ state }) {
    state.loading = true
    return new Promise((resolve) => {
      api.getStocks(state.billId).then((response) => {
        resolve(response.data)
        state.loading = false
      }
      )
    })
  },
  getStockType({ state }, params) {
    return new Promise((resolve) => {
      api.getStockType(params).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  deleteStock({ state }) {
    return new Promise((resolve) => {
      api.deleteStock(state.id).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  saveStock({ state }) {
    if (state.id === 0) {
      return new Promise((resolve) => {
        api.postStocks(state.stock).then((response) => {
          resolve(response.data)
        })
      })
    } else {
      return new Promise((resolve) => {
        api.putStocks(state.id, state.stock).then((response) => {
          resolve(response.data)
        })
      })
    }
  },
  getStock({ state }) {
    api.getStock(state.productId, state.stockNumber).then((response) => {
      state.stockDetail = response.data
    })
  },
  getStockInBill({ state }) {
    api.getStockInBill(state.productId, state.stockNumber).then((response) => {
      state.stockInBill = response.data
    })
  },
  saveEditStock({ state }) {
    return new Promise((resolve) => {
      api.saveEditStock(state.stockDetail).then((response) => {
        resolve(response.data)
      })
    })
  },
  getInventory({ state }) {
    api.getInventoryReport(state.keyword).then((response) => {
      state.inventory = response.data
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
