import request from '@/util/request'
const api = {
  getCompanies: (keyword) => {
    return request({
      url: `/Companies?keyword=${keyword}`,
      method: 'get'
    })
  },
  getCompany: (id) => {
    return request({
      url: `/Companies/${id}`,
      method: 'get'

    })
  },
  postCompany: (data) => {
    return request({
      url: '/Companies',
      method: 'post',
      data: data
    })
  },
  putCompany: (id, data) => {
    return request({
      url: `/Companies/${id}`,
      method: 'put',
      data: data
    })
  },
  deleteCompany: (id) => {
    return request({
      url: `/Companies/deleteCompany/${id}`,
      method: 'put'
    })
  }
}
export default api
