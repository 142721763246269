<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">เพิ่มบริษัท</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="12"
            >
              <v-text-field
                v-model="data.departmentName"
                label="ชื่อหน่วยงาน"
                color="teal"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="12"
            >
              <v-select
                v-model="data.departmentTypeId"
                label="ประเภทหน่วยงาน"
                color="teal"
                :items="departmentTypes"
                item-text="departmentTypeName"
                item-value="id"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          dark
          @click="close()"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="teal"
          dark
          @click="Confirm()"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import { sync, call } from 'vuex-pathify'
export default {
  name: 'SetupDepartmentForm',
  computed: {
    dialog: sync('department/dialogCreateForm'),
    data: sync('department/department'),
    snackbars: sync('product/snackbar'),
    id: sync('department/id'),
    departmentTypes: sync('departmentType/departmentTypes')
  },
  created() {
    this.checkinit()
  },
  mounted() {

  },
  methods: {
    ...call('department', ['getDepartmentList', 'initDepartment', 'saveDepartment']),
    Confirm() {
      this.saveDepartment().then((response) => {
        if (response != null) {
          this.getDepartmentList()
          this.dialog = false
          this.snackbars = true
        }
      })
      this.initDepartment()
    },
    close() {
      this.dialog = false
      this.initDepartment()
    },
    checkinit() {
      if (this.id == null) {
        this.initDepartment()
      }
    }
  }
}
</script>
