import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { parseISO, format } from 'date-fns'
import './assets/main.css'
// import '@/permission'
import liff from '@line/liff'

liff.init({
  liffId: String(process.env.VUE_APP_LIFF_ID)
})
Vue.config.productionTip = false
// filter
Vue.filter('displayDateFormat', function(val) {
  return val ? format(parseISO(val), 'dd MM yyyy') : val
})
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
