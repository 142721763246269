<template>
  <v-container>
    <v-card-title>
      <div class="text-h5">
        รายการเบิกวัสดุการแพทย์
      </div>
      <v-spacer />
    </v-card-title>
    <v-card-title>
      <v-row>
        <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          label="ค้นหา"
          single-line
          hide-details
          color="teal"
          class="pr-5 mr-15"
        />
      </v-row>

      <v-row>
        <v-col
          cols="15"
          lg="4"
          class="d-flex align-center"
        >
          <v-menu
            ref="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="วันที่"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                color="teal"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              color="teal"
              no-title
              locale="th"
              range
              @change="onSearch"
            />
          </v-menu>
        </v-col>
        <v-col
          class="d-flex align-center "
          lg="3"
        >
          <v-autocomplete
            v-model="departmentId"
            color="teal"
            :items="departments"
            label="แผนก"
            item-text="departmentName"
            item-value="id"
            @change="onSearch"
          />
        </v-col>
        <v-col
          class="d-flex align-center"
          lg="3"
        >
          <v-select
            v-model="statusId"
            color="teal"
            :items="status"
            label="สถานะ"
            item-text="statusName"
            item-value="id"
            @change="onSearch"
          />
        </v-col>
        <v-col
          class="d-flex align-center justify-end"
          lg="2"
        >
          <v-btn
            color="teal"
            dark
            @click="onInit()"
          >ล้างการค้นหา</v-btn>
        </v-col>
      </v-row>

    </v-card-title>
    <picking-list-table />
    <dialog-delete />
    <create-product-form />
    <success />
    <delete-success />

  </v-container>

</template>

<script>
import CreateProductForm from '../../components/product/CreateProductForm'
import DialogDelete from '../../components/dialog/DialogDelete'
import Success from '../../components/snackbar/Success'
import DeleteSuccess from '../../components/snackbar/DeleteSuccess'
import { sync, call } from 'vuex-pathify'
import PickingListTable from '../tables/PickingListTable'
export default {
  name: 'PickingList',
  components: {
    DialogDelete,
    CreateProductForm,
    Success,
    DeleteSuccess,
    PickingListTable
  },
  data: () => ({

  }),
  watch: {

  },
  created() {
    this.onCreate()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...sync('product', ['dialogCreateForm', 'products', 'type']),
    ...sync('type', ['types']),
    ...sync('status', ['status']),
    ...sync('order', ['statusId', 'departmentId', 'date', 'keyword']),
    ...sync('department', ['departments']),
    dateRangeText() {
      return this.date.join(' ~ ')
    }
  },

  methods: {
    ...call('product', ['initProduct']),
    ...call('status', ['getStatusList']),
    ...call('order', ['getOrderForAdminList', 'initPayload']),
    ...call('department', ['getDepartmentList']),
    createForm() {
      this.dialogCreateForm = true
      this.initProduct()
    },
    initDate() {
      var eDate = null
      var dDate = null
      this.date = [dDate, eDate]
    },
    onCreate() {
      this.getStatusList()
      this.getDepartmentList()
    },
    onInit() {
      this.statusId = 0
      this.departmentId = 0
      this.initDate()
      this.initPayload()
      this.onSearch()
    },
    onSearch() {
      this.getOrderForAdminList(this.payload)
    }
  }
  // eslint-disable-next-line vue/order-in-components

}
</script>
