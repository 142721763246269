<template>
  <v-dialog
    v-model="dialog"
    max-width="1500px"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-toolbar
        color="secondary"
        dark
      >history</v-toolbar>
      <v-card-text>
        <v-simple-table>
          <template #default>
            <tbody>
              <tr
                v-for="item in items"
                :key="item.key"
              >
                <td>{{ item.discription }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.date }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="dialog = false"
        >Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'DialogHistory',
  computed: {
    dialog: sync('order/dialogHistory'),
    snackbar: sync('stock/snackbar'),
    items: sync('order/history')
  },
  methods: {
  }
}
</script>
