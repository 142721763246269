<template>
  <v-container>
    <v-card-title>
      <div class="text-h5">
        จัดการคลัง
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="teal"
          class="ma-2 dark--text"
          dark
          to="/createStockForm"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
          สร้างใบรับเข้า
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-row>
        <v-col
          lg="5"
        >
          <v-text-field
            v-model="keyword"
            label="ค้นหา"
            single-line
            hide-details
            color="teal"
            class="pr-5"
          />
        </v-col>
        <v-col
          lg="4"
        >
          <v-autocomplete
            v-model="status"
            :items="statuss"
            item-value="id"
            item-text="title"
            label="สถาณะ"
            single-line
            color="teal"
            class="pr-5 mr-5"
            @change="onSearch()"
          />
        </v-col>
        <v-col
          lg="3"
          class="d-flex align-center"
        >
          <v-menu
            ref="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="วันที่"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                color="teal"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              color="teal"
              no-title
              locale="th"
              range
            />
          </v-menu>
        </v-col>
        <v-spacer />
        <v-col class="ml--4">
          <v-btn
            class="mr-1"
            color="teal"
            dark
            @click="onSearch()"
          >ค้นหา
          </v-btn>
          <v-btn
            color="grey"
            dark
            @click="onInit()"
          >ล้างการค้นหา
          </v-btn>
        </v-col>
      </v-row>

    </v-card-title>
    <stock-table />
    <dialog-delete-stock />
    <success />
    <delete-success />
  </v-container>

</template>
<script>
import StockTable from '../../components/tables/StockTable'
import { sync, call } from 'vuex-pathify'
import DialogDeleteStock from '../../components/dialog/DialogDeleteStock'
import Success from '../../components/snackbar/Success'
import DeleteSuccess from '@/components/snackbar/DeleteSuccess'
// import { formatISO } from 'date-fns'

export default {
  name: 'ManageStock',
  components: {
    StockTable,
    DialogDeleteStock,
    Success,
    DeleteSuccess
  },
  data: () => ({
    statuss: [{
      id: 1,
      title: 'บันทึกร่าง'
    }, {
      id: 2,
      title: 'ส่งแล้ว'
    }] }),
  computed: {
    ...sync('stock', ['stocks', 'stockDate', 'stockNumber', 'keyword', 'date', 'status']),
    dateRangeText() {
      return this.date.join(' ~ ')
    }
  },
  created() {
    this.onInit()
  },

  methods: {
    ...call('stock', ['getStockList']),
    ...call('product', ['getProductList']),
    ...call('unit', ['getUnitList']),
    createForm() {
      this.dialogCreateForm = true
      this.getProductList(1)
      this.initStock()
      this.getUnitList()
    },
    initDate() {
      var eDate = null
      var dDate = null
      this.date = [dDate, eDate]
      this.keyword = ''
      this.stockNumber = ''
      this.status = 0
    },
    onSearch() {
      const payload = {
        page: 1,
        itemsPerPage: 10,
        status: this.status
      }
      this.getStockList(payload)
    },
    onInit() {
      this.initDate()
      this.onSearch()
    }
  }
}
</script>
