<template>
  <v-card
    max-width="344"
  >
    <v-card-text>
      <!-- <div>ขั้นตอนการเบิก</div> -->
      <p class="text-h4 text--primary">
        ขั้นตอนการเบิก
      </p>
      <!-- <p>adjective</p> -->
      <div class="text--primary">
        1.เลือกเมนูใบเบิกวัสดุแพทย์<br>
        2.กดปุ่มสร้างใบเบิกใหม่<br>
        3.เลือกหน่วยงาน<br>
        4.เลือกรายการวัสดุแพทย์<br>
        5.กรอกจำนวนคงเหลือและจำนวนเบิก<br>
        6.กดบันทึกเพื่อบันทึกไว้แก้ไขต่อ ถ้าต้องการยื่นใบเบิกให้กดบันทึกและยื่นใบเบิก
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="teal accent-5"
        @click="createOrder()"
      >
        <p class="text-h6">
          ไปที่หน้าเบิกวัสดุแพทย์
        </p>
      </v-btn>
    </v-card-actions>
  </v-card>
  <!-- <v-card style="height: 100%;">
    นี่คือหน้าเเรก 556677
  </v-card> -->

</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'Home',
  computed: {
    dialog: sync('app/dialogLogin')
  },
  created() {

  },
  mounted() {
  },
  methods: {
    onInit() {

    },
    op() {
      this.dialog = true
    },
    out() {
      localStorage.clear()
      this.dialog = true
    },
    createOrder() {
      this.$router.push({ path: `/actionOrder` })
    }
  }

}
</script>
