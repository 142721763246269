import _ from 'lodash'
import { formatISO, parseISO, format } from 'date-fns'
export function getMonthCurrent(){
    const now = new Date()
    const month = now.getMonth();
    var monthISO = null
    switch (month) {
        case 0: 
                monthISO = 1;
                break;
        case 1: 
                monthISO = 2;
                break;
        case 2: 
                monthISO = 3;
                break;
        case 3: 
                monthISO = 4;
                break;
        case 4: 
                monthISO = 5;
                break;
        case 5: 
                monthISO = 6;
                break;
        case 6: 
                monthISO = 7;
                break;
        case 7: 
                monthISO = 8;
                break;
        case 8: 
                monthISO = 9;
                break;
        case 9: 
                monthISO = 10;
                break;
        case 10: 
                monthISO = 11;
                break;
        case 11: 
                monthISO = 12;
                break;
    }
    return monthISO
}
export function getYearNow(){
        const now = new Date()
        const year = now.getYear()+ 1900;
        return year
    }
export function getSetYear(){
        const now = new Date()
        const year = now.getFullYear();
        const defaultYear = year - 5;
        const setYear = []
        let years = null
        let returnYear = []
        for(var i=year;i>=defaultYear;i--){
                setYear.push(i)
        }
        _.forEach(setYear, function(item) {
                years = {year: item , yearTh: item +543}
                returnYear.push(years)
        })
        return returnYear
    }
export function formatDate(date) {
        return format(date, 'yyyy-MM-dd,HH:mm:ss')
}

export function checkDateMatch(dateExp) {
        if(dateExp === null){
                 return null
        } 
        let exp = dateExp
        if (typeof dateExp === "string") {
                exp = parseISO(dateExp)
        }
        const now = new Date()
        return formatDate(now) > formatDate(exp) ? true : false
        
        
}

export function getColor(status) {
        switch (status) {
                case 'DRAFTED':return '#374151'
                case 'SENDED':return '#a16207'
                case 'APPROVED':return '#1d4ed8'
                case 'FINISHED':return '#B71C1C'
              }
}
export function getBgColor(status) {
        switch (status) {
                case 'DRAFTED':return '#f9fafb'
                case 'SENDED':return '#fefce8'
                case 'APPROVED':return '#eff6ff'
                case 'FINISHED':return '#FFEBEE'
              }
}