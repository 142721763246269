<template>
  <v-card
    class="mx-10 px-10 pb-10 "
  >
    <v-card-title>
      <div class="text-h4 mr-9">
        สรุปตามหน่วยงาน
      </div>
      <div class="mr-9">
        <v-autocomplete
          v-model="month"
          :items="months"
          item-text="text"
          item-value="value"
        />
      </div>
      <div class="text-h4 mr-9">
        ปี
      </div>
      <div class="mr-9">
        <v-autocomplete
          v-model="year"
          :items="yesrs"
          item-text="yearTh"
          item-value="year"
        />
      </div>
      <div class="mr-2">
        <v-btn
          color="teal"
          dark
          @click="search()"
        >
          ค้นหา
        </v-btn>
      </div>
      <div>
        <v-btn
          color="grey"
          dark
          @click="clear()"
        >
          ล้างการค้นหา
        </v-btn>
      </div>
      <v-spacer />
      <div class="mx-2">
        <v-btn
          color="teal"
          dark
          :loading="loading"
          @click="downloadReport()"
        >
          พิมพ์รายงาน
        </v-btn>
      </div>
      <div>
        <v-btn
          color="grey"
          dark
          to="/report"
        >
          กลับ
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-group
          v-for="item in summarizeDepartments"
          :key="item.departmentId"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >

          <template #activator>
            <v-list-item-content>
              <v-row>
                <v-col cols="10">
                  <v-list-item-title v-text="item.departmentName" />
                </v-col>
                <v-col cols="2">
                  จำนวน  {{ item.orderDetails.length }} รายการ
                </v-col>
              </v-row>
            </v-list-item-content>
          </template>
          <v-list-item-content style="padding-left: 72px;">
            <v-row>
              <v-col cols="2" style="padding: 0;">
                วันที่
              </v-col>
              <v-col cols="3" style="padding: 0;">
                ชื่อผู้เบิก
              </v-col>
              <v-col cols="3" style="padding: 0;">
                วัสดุแพทย์
              </v-col>
              <v-col cols="2" style="padding: 0;">
                จำนวน
              </v-col>
              <v-col cols="2" style="padding: 0;">
                มูลค่า
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item
            v-for="child in item.orderDetails"
            :key="child.name"
          >
            <v-list-item-content>
              <v-row>
                <v-col cols="2" style="padding: 0;">
                  <v-list-item>
                    {{ showDate(child.date) }}
                  </v-list-item>
                </v-col>
                <v-col cols="3" style="padding: 0;">
                  <v-list-item v-text="child.name" />
                </v-col>
                <v-col cols="3" style="padding: 0;">
                  <v-list-item v-text="child.productName" />
                </v-col>
                <v-col cols="2" style="padding: 0;">
                  <v-list-item v-text="child.quantity" />
                </v-col>
                <v-col cols="2" style="padding: 0;">
                  <v-list-item>{{ formatNumber(child.price) }}</v-list-item>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card-text>

  </v-card>
</template>
<script>
// import { formatISO } from 'date-fns'
import { sync, call } from 'vuex-pathify'
import _ from 'lodash'
import { getMonthCurrent, getYearNow, getSetYear } from '../../util/index'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
export default {
  name: 'SummaryReport',
  components: { },
  data() {
    return {
      headers: [
        { text: 'ลำดับ', value: 'number' },
        { text: 'หน่วยงาน', value: 'departmentName' },
        { text: 'มูลค่ารวม', value: 'price', align: 'right' }
      ],
      header: [
        { text: 'รายการ', value: 'list', sortable: false },
        { text: 'มูลค่า', value: 'value', sortable: false, align: 'right' }
      ],
      months: [
        { text: 'มกราคม', value: 1 },
        { text: 'กุมภาพันธ์', value: 2 },
        { text: 'มีนาคม', value: 3 },
        { text: 'เมษายน', value: 4 },
        { text: 'พฤษภาคม', value: 5 },
        { text: 'มิถุนายน', value: 6 },
        { text: 'กรกฎาคม', value: 7 },
        { text: 'สิงหาคม', value: 8 },
        { text: 'กันยายน', value: 9 },
        { text: 'ตุลาคม', value: 10 },
        { text: 'พฤศจิกายน', value: 11 },
        { text: 'ธันวาคม', value: 12 },
        { text: 'ทั้งหมด', value: 13 }
      ],
      yesrs: getSetYear(),
      loading: false

    }
  },
  computed: {
    ...sync('report', ['summarizeDepartments', 'summarize', 'month', 'year', 'onSearch'])
  },
  created() {
    this.initSearch()
    this.search()
  },

  methods: {
    ...call('report', ['getSummarizeInHos', 'getSummarizeOutHos', 'getSummerizeDepartment', 'exportSummaryDepartmentReport']),
    initSearch() {
      this.month = getMonthCurrent()
      this.year = getYearNow()
      getSetYear()
    },
    async clear() {
      this.initSearch()
      this.search()
      this.onSearch = true
    },
    search() {
      this.getSummerizeDepartment().then((response) => {
        console.log(response)
      })
    },
    async downloadReport() {
      this.loading = true
      this.exportSummaryDepartmentReport().then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'exportSummaryDepartmentReport.xlsx')
        document.body.appendChild(link)
        link.click()
        this.loading = false
      })
    },
    showDate(value) {
      return value !== null ? format(parseISO(value), 'dd MMMM yyyy', { locale: th }) : ''
    },
    formatNumber(value) {
      if (_.isNaN(value)) {
        return '0'
      }
      const n = value.toFixed(2)
      const ns = n.split('.')
      const num = ns[1] === '00'
        ? ns[0]
        : n.charAt(n.length - 1) === '0'
          ? n.slice(0, n.length - 1)
          : n
      if (_.isNaN(num)) {
        return '0'
      }
      return (String(num)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}

</script>
