<template>
  <v-data-table
    :headers="header"
    :items="orderForAdmin.items"
    :items-per-page="10"
    class="elevation-1"
    :loading="loading"
    :options.sync="options"
    :server-items-length="orderForAdmin.totalCount"
    :footer-props="{'items-per-page-options':[10,20, 30, 50, 100]}"
  >
    <template #item.date="{ item }">
      {{ item.date | showDate }}
    </template>
    <template #item.actions="{ item }">
      <v-icon
        color="teal"
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-file-document
      </v-icon>
    </template>
    <template #item.status="{ item }">
      <v-chip
        :color="getColor(item.statusCode)"
        dark
        outlined
        :style="{'background-color':
          getBgColor(item.statusCode)+
          '!important'
        }"
      >
        {{ item.statusName }}
      </v-chip>
    </template>

  </v-data-table>
</template>
<script>

import { sync, call } from 'vuex-pathify'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
import { getColor, getBgColor } from '../../util/index'
export default {
  name: 'PickingListTable',
  filters: {
    showDate: function(value) {
      return value !== null ? format(parseISO(value), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  data: () => ({
    // options: {
    //   'page': 1,
    //   'itemsPerPage': 10,
    //   'sortBy': [],
    //   'sortDesc': [],
    //   'groupBy': [],
    //   'groupDesc': [],
    //   'mustSort': false,
    //   'multiSort': false
    // },
    header: [
      {
        text: 'วันที่',
        value: 'date',
        sortable: true
      },
      {
        text: 'เลขที่เอกสาร',
        value: 'documentNumber',
        sortable: true
      },
      {
        text: 'ชื่อ',
        value: 'employeeName',
        sortable: true
      },
      {
        text: 'แผนก',
        value: 'departmentName',
        sortable: true
      },
      {
        text: 'สถานะ',
        value: 'status',
        sortable: false
      },
      {
        text: 'Action',
        width: '8rem',
        align: 'center',
        value: 'actions',
        sortable: false
      }

    ]
  }),

  computed: {
    ...sync('order', ['orders', 'keyword', 'orderForAdmin', 'id', 'loading', 'inspector', 'inspectionDate', 'options', 'payload'])
  }, watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getOrderForAdminFormApi()
      } else if (value.length === 0) this.getOrderForAdminFormApi()
    },
    options: {
      handler() {
        this.getOrderForAdminFormApi()
      },
      deep: true
    }
  },
  created() {
    this.getOrderForAdminFormApi()
  },
  mounted() {

  },
  methods: {
    ...call('order', ['getOrderForAdminList', 'updateStatus', 'checkOrder']),
    editItem(item) {
      this.id = item.id
      if (item.statusCode === 'SENDED') {
        this.$router.push({ path: `/approveOrderForm/${item.id}` })
      } else if (item.statusCode === 'APPROVED') {
        this.$router.push({ path: `/completeOrderForm/${item.id}` })
      } else if (item.statusCode === 'FINISHED') {
        this.$router.push({ path: `/sumaryOrderForm/${item.id}` })
      }
    },
    getColor(status) {
      return getColor(status)
    },
    getBgColor(status) {
      return getBgColor(status)
    },
    getOrderForAdminFormApi() {
      const { page, itemsPerPage } = this.options
      this.payload = {
        page: page,
        itemsPerPage: itemsPerPage
      }
      this.getOrderForAdminList(this.payload).then(() => {

      })
    }
  }
}
</script>
