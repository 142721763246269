<template>
  <v-dialog v-if="item" v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title align="center" class="text-h5">คุณต้องการส่งใบเบิกหรือไม่</v-card-title>
      <v-list-item
        two-line
        style="margin-left: 30px;"
      >
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            หน่วยงาน : {{ item.departmentName }}
          </v-list-item-title>
          <v-list-item-subtitle>ชื่อผู้เบิก {{ item.employeeName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row align="center">
          <v-col
            align="center"
            class="text-h4"
          >
            รายการเบิก : {{ item.orderCount }} รายการ
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn color="grey" dark @click="closeDelete">ยกเลิก</v-btn>
        <v-btn color="teal" dark @click="Confirm">บันทึก</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import { parseISO, format } from 'date-fns'
import { th } from 'date-fns/locale'
export default {
  name: 'DialogEditAndSendOrder',
  components: {
  },
  data: function() {
    return {
      date: null
    }
  },
  computed: {
    dialog: sync('order/dialogEditAndSendOrder'),
    ...sync('order', ['details', 'item']),
    ...sync('department', ['departments']),
    ...sync('employee', ['employees']),
    showDate() {
      return this.order.date !== null ? format(parseISO(this.order.date), 'dd MMMM yyyy', { locale: th }) : ''
    }
  },
  created() {
  },
  methods: {
    ...call('order', ['editAndSendOrder', 'getOrderList']),
    async Confirm() {
      this.editAndSendOrder().then((response) => {
        this.getOrderList()
        this.$router.push('/orderView')
        this.dialog = false
      })
    },
    closeDelete() {
      this.dialog = false
    }
  }
}
</script>

<style>
.card-item{
  margin: 30px;

}
</style>
