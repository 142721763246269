<template>
  <loading-page />
</template>
<script>
import liff from '@line/liff'
import axios from 'axios'
import CryptoES from 'crypto-es'
import LoadingPage from '../components/dialog/loadingPage'
import { sync } from 'vuex-pathify'
export default {
  components: {
    LoadingPage
  },
  data: () => ({
    identityUrl: process.env.VUE_APP_IDENTITY_URL,
    channel_id: process.env.VUE_APP_CHANNEL_ID,
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_REDIRECT_URI,
    scopes: process.env.VUE_APP_SCOPE
  }),
  computed: {
    loading: sync('app/loading')
  },
  mounted() {
    // const vm = this
    this.loading = true
    liff.ready.then(async() => {
      if (!liff.isLoggedIn()) {
        liff.login()
      } else if (
        this.$route.query.code &&
        this.$route.query.state &&
        this.$route.query.state === localStorage.getItem('state')
      ) {
        const { data } = await axios.post(
          `${this.identityUrl}/connect/token`,
          {
            client_id: this.client_id,
            grant_type: 'authorization_code',
            code_verifier: localStorage.getItem('code_verifier'),
            code: this.$route.query.code,
            redirect_uri: this.redirect_uri
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )

        const { access_token, id_token } = data
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('id_token', id_token)
        localStorage.removeItem('state')
        localStorage.removeItem('code_verifier')
        this.loading = false
        this.$router.push({ path: `/` })
      } else {
        this.login()
      }
    })
  },

  methods: {
    login() {
      const code_verifier = CryptoES.lib.WordArray.random(32).toString(
        CryptoES.enc.Base64url
      )
      localStorage.setItem('code_verifier', code_verifier)

      const code_challenge = CryptoES.SHA256(code_verifier).toString(
        CryptoES.enc.Base64url
      )

      const code_challenge_method = 'S256'
      const response_mode = 'query'

      const authority = `${this.identityUrl}/connect/authorize`
      const response_type = 'code'
      const scope = `openid profile ${this.scopes}`
      const nonce = 'N' + Math.random() + '' + Date.now()
      const state = Date.now() + '' + Math.random()
      localStorage.setItem('state', state)

      const url =
        authority +
        '?' +
        'channel_id=' +
        encodeURI(this.channel_id) +
        '&' +
        'client_id=' +
        encodeURI(this.client_id) +
        '&' +
        'redirect_uri=' +
        encodeURI(this.redirect_uri) +
        '&' +
        'response_type=' +
        encodeURI(response_type) +
        '&' +
        'nonce=' +
        encodeURI(nonce) +
        '&' +
        'scope=' +
        encodeURI(scope) +
        '&' +
        'state=' +
        encodeURI(state) +
        '&' +
        'code_challenge=' +
        encodeURI(code_challenge) +
        '&' +
        'code_challenge_method=' +
        encodeURI(code_challenge_method) +
        '&' +
        'response_mode=' +
        encodeURI(response_mode) +
        '&' +
        'id_token=' +
        encodeURI(liff.getIDToken() ?? '')
      window.location.href = url
    }
  }
}
</script>
