<template>
  <v-data-table
    :headers="header"
    :items="units"
    :items-per-page="15"
    class="elevation-1"
    sort-by="id"
  >
    <template #item.actions="{ item }">
      <v-icon
        class="mr-2"
        color="teal"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon

        color="red darken-4"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>

</template>
<script>

import { sync, call } from 'vuex-pathify'
export default {
  name: 'UnitTable',
  data: () => ({
    header: [
      {
        text: 'หน่วยนับ',
        value: 'unitName',
        sortable: true
      },
      //   {
      //     text: 'ประเภทหน่วยงาน',
      //     value: 'departmentType',
      //     sortable: true,
      //     align: 'center'
      //   },
      // {
      //   text: 'สถานะ',
      //   value: 'isActive',
      //   sortable: true,
      //   width: '8rem',
      //   align: 'center'
      // },
      {
        text: 'Action',
        width: '8rem',
        align: 'center',
        value: 'actions'
      }

    ]
  }),

  computed: {
    ...sync('unit', ['units', 'dialogCreateForm', 'id', 'dialogDelete'])
  },
  watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getStockList()
      } else if (value.length === 0) this.getStockList()
    }
  },
  created() {
  },
  mounted() {
    this.getUnitList()
  },
  methods: {
    ...call('unit', ['getUnitList', 'getUnit']),
    ...call('product', ['getProductList']),
    deleteItem(item) {
      this.dialogDelete = true
      this.id = item.id
    },
    editItem(item) {
      this.dialogCreateForm = true
      this.id = item.id
      this.getUnit()
    }
  }

}
</script>
