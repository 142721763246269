<template>
  <v-card>
    <v-card-title>
      <div class="text-h5">
        จัดการข้อมูลหน่วยงาน
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="grey"
          class="ma-2 dark--text"
          dark
          to="/setup"
        >
          <v-icon dark>
            mdi-arrow-left-bold
          </v-icon>
          กลับ
        </v-btn>
        <v-btn
          color="teal"
          class="ma-2"
          dark
          @click="createForm()"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          เพิ่ม
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-text-field
        v-model="keyword"
        append-icon="mdi-magnify"
        label="ค้นหา"
        single-line
        hide-details
        color="teal"
      />
      <v-spacer />
    </v-card-title>
    <department-table />
    <setup-department-form />
    <success />
    <delete-success />
    <dialog-delete-department />
  </v-card>

</template>

<script>
import SetupDepartmentForm from '../../../components/setupForm/SetupDepartmentForm'
import DepartmentTable from '../../../components/tables/DepartmentTable'
import { sync, call } from 'vuex-pathify'
import Success from '../../../components/snackbar/Success'
import DeleteSuccess from '../../../components/snackbar/DeleteSuccess'
import DialogDeleteDepartment from '../../../components/dialog/DialogDeleteDepartment'

export default {
  name: 'SetupDepartment',
  components: {
    DepartmentTable,
    SetupDepartmentForm,
    Success,
    DeleteSuccess,
    DialogDeleteDepartment

  },
  data: () => ({

  }),
  watch: {

  },
  created() {
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...sync('department', ['dialogCreateForm', 'keyword'])
  },

  methods: {
    ...call('department', ['initDepartment', 'getDepartmentList']),
    ...call('departmentType', ['getDepartmentTypeList']),
    createForm() {
      this.dialogCreateForm = true
      this.initDepartment()
      this.getDepartmentTypeList()
    }
  }
  // eslint-disable-next-line vue/order-in-components

}
</script>
