import { make } from 'vuex-pathify'
import api from '@/api/auth'
const state = {
  userName: null,
  password: null,
  keyword: '',
  roleId: '',
  employeeId: '',
  userNames: ''

}

const mutations = make.mutations(state)

const actions = {
  login({ state }) {
    const user = {
      username: state.userName,
      password: state.password
    }
    return new Promise((resolve) => {
      api.login(user).then((response) => {
        resolve(response.data)
        state.employeeId = response.data.id
        state.roleId = response.data.roleId
        state.userNames = state.userName
      })
    })
  },
  logout({ state }) {
    return new Promise((resolve) => {
      state.userName = ''
      state.password = ''
    })
  },
  getRole({ state }) {
    api.getRole().then((response) => {
      state.roleId = response.data
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
