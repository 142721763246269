<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card>
      <v-card-title class="text-h5">คุณต้องการบันทึกข้อมูลหรือไม่</v-card-title>
      <check-number-table />
      <v-card />
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" dark @click="close">ยกเลิก</v-btn>
        <v-btn color="teal" dark @click="onApprove()">บันทึก</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync, call } from 'vuex-pathify'
import CheckNumberTable from '../tables/CheckNumberTable'
import { formatISO } from 'date-fns'
import _ from 'lodash'
import { ls } from '../../util/ls'
// import _ from 'lodash'
export default {
  name: 'DialogCheckApprove',
  components: { CheckNumberTable
  },
  computed: {
    dialog: sync('order/dialogCheck'),
    detail: sync('order/orderDetails'),
    ...sync('order', ['approver', 'approveDate']),
    checkNumber: sync('order/checkNumber')
  },

  methods: {
    ...call('order', ['approveOrder', 'getOrderList', 'updateStatus']),
    // async Confirm() {
    //   await this.approveOrder()
    //   await this.updateStatus()
    //   await this.getOrderList()
    //   this.dialog = false
    // },
    close() {
      this.dialog = false
    },
    async onApprove() {
      const details = this.detail
      const checkNumber = this.checkNumber
      _.forEach(checkNumber, function(r) {
        const approve = _.find(details, function(o) { return o.productId === r.productId })
        approve.typeId = r.type
      })
      this.approver = ls.get('id')
      this.approveDate = formatISO(new Date(), { representation: 'date' })
      await this.approveOrder()
      await this.updateStatus()
      await this.getOrderList()
      this.dialog = false
      await this.$router.push('/pickingList')
    }

  }
}
</script>
