import { make } from 'vuex-pathify'
const state = {
  employees: []
}

const mutations = make.mutations(state)

const actions = {

}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
