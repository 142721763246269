import request from '@/util/request'
const api = {
  getStocks: (keyword, startDate, EndDate, page, itemsPerPage, status) => {
    return request({
      url: `/Stocks?keyword=${keyword}&startDate=${startDate}&endDate=${EndDate}&pageNumber=${page}&pageSize=${itemsPerPage}&status=${status}`,
      method: 'get'
    })
  },
  getStock: (id) => {
    return request({
      url: `/Stocks/${id}`,
      method: 'get'

    })
  },
  postStocks: (data) => {
    return request({
      url: '/Stocks',
      method: 'post',
      data: data
    })
  },
  putStocks: (id, data) => {
    return request({
      url: `/Stocks/${id}`,
      method: 'put',
      data: data
    })
  },
  deleteType: (id) => {
    return request({
      url: `/Types/deleteType/${id}`,
      method: 'put'
    })
  }
}
export default api
