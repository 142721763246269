<template>
  <v-dialog v-model="dialog" max-width="420px">
    <v-card>
      <v-card-title class="text-h5">คุณต้องการยกเลิกใบเบิกหรือไม่</v-card-title>
      <v-card-actions>
        <v-spacer />

        <v-btn color="grey" dark @click="closeDelete">ยกเลิก</v-btn>
        <v-btn color="red darken-4" dark @click="deleteItemConfirm">ลบ</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync, call } from 'vuex-pathify'
// import { ls } from '../../util/ls'
export default {
  name: 'DialogDeleteOrder',
  computed: {
    dialog: sync('order/dialogDelete'),
    snackbar: sync('stock/snackbar')
  },
  methods: {
    ...call('order', ['deleteOrder', 'getOrderList']),
    deleteItemConfirm() {
      this.deleteOrder().then((response) => {
        if (response.id !== 0) {
          var payload = {
            page: 1,
            itemsPerPage: 10
          }
          this.getOrderList(payload)
          this.dialog = false
          this.$router.push({ path: `/orderView` })
          // this.snackbar = true
        }
      })
    },
    closeDelete() {
      this.dialog = false
    }
  }
}
</script>
