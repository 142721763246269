import { make } from 'vuex-pathify'
import api from '@/api/order-detail'

const state = {
  orderDetail: null,
  id: 0,
  crBy: ''
}

const mutations = make.mutations(state)

const actions = {
  checkOrderDetail({ state }) {
    const data = {
      PickStockQty: state.orderDetail.pickStockQty,
      ReusedQty: state.orderDetail.reusedQty,
      IsConfirmed: state.orderDetail.isConfirmed
    }
    return new Promise((resolve) => {
      api.checkOrderDetail(state.id, data).then((response) => {
        resolve(response)
      })
    })
  },
  approveOrderDetail({ state }) {
    const data = {
      ApprovedQty: state.orderDetail.approvedQty,
      IsApproved: state.orderDetail.isApproved
    }
    return new Promise((resolve) => {
      api.approveOrderDetail(state.id, data).then((response) => {
        resolve(response)
      })
    })
  },
  approveAllOrderDetail({ state }, data) {
    return new Promise((resolve) => {
      api.approveAllOrderDetail(data).then((response) => {
        resolve(response)
      })
    })
  }

}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
