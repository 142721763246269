import request from '@/util/request'

const api = {
  getProducts: (keyword, pageNumber, pageSize) => {
    return request({
      url: `/Products/list?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      method: 'get'
    })
  },
  getProductMaster: () => {
    return request({
      url: `/Products`,
      method: 'get'
    })
  },
  getProduct: (id) => {
    return request({
      url: `/Products/${id}`,
      method: 'get'

    })
  },
  getUnitId: (id) => {
    return request({
      url: `/Products/UnitId/${id}`,
      method: 'get'

    })
  },
  postProduct: (data) => {
    return request({
      url: '/Products',
      method: 'post',
      data: data
    })
  },
  putProduct: (id, data) => {
    return request({
      url: `/Products/${id}`,
      method: 'put',
      data: data
    })
  },
  deleteProduct: (id) => {
    return request({
      url: `/Products/deleteProduct/${id}`,
      method: 'put'
    })
  }

}
export default api
