import { make } from 'vuex-pathify'
import api from '@/api/company'
import { ls } from '../../util/ls'
const state = {
  companies: [],
  dialogCreateForm: false,
  id: null,
  dialogDelete: false,
  company: [],
  keyword: ''
}

const mutations = make.mutations(state)

const actions = {
  initCompany({ state }) {
    state.company = {
      id: 0,
      companyName: null,
      address: null,
      contact: null,
      createdBy: ls.get('id')
    }
  },
  getCompanyList({ state }) {
    api.getCompanies(state.keyword).then((response) => {
      state.companies = response.data
    })
  },
  getCompany({ state }) {
    api.getCompany(state.id).then((response) => {
      state.company = response.data
    })
  },
  deleteCompany({ state }) {
    // api.deleteProduct(state.id)
    return new Promise((resolve) => {
      api.deleteCompany(state.id).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  saveCompany({ state }) {
    const data = {
      companyName: state.company.companyName,
      address: state.company.address,
      contact: state.company.contact,
      createdBy: ls.get('id')
    }
    if (state.id === 0) {
      return new Promise((resolve) => {
        api.postCompany(data).then((response) => {
          resolve(response.data)
        })
      })
    } else {
      return new Promise((resolve) => {
        api.putCompany(state.id, state.company).then((response) => {
          resolve(response.data)
        })
      })
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
