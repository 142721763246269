<template>
  <v-card>
    <v-card-title>
      <div class="text-h5">
        จัดการหน่วยนับ
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="grey"
          class="ma-2 dark--text"
          dark
          to="/setup"
        >
          <v-icon dark>
            mdi-arrow-left-bold
          </v-icon>
          กลับ
        </v-btn>
        <v-btn
          color="teal"
          class="ma-2"
          dark
          @click="createForm()"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          เพิ่ม
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-text-field
        v-model="keyword"
        append-icon="mdi-magnify"
        label="ค้นหา"
        single-line
        hide-details
        color="teal"
      />
      <v-spacer />
    </v-card-title>
    <unit-table />
    <setup-unit-form />
    <delete-success />
    <success />
    <dialog-delete-unit />
  </v-card>

</template>

<script>
import UnitTable from '../../../components/tables/UnitTable'
import { sync, call } from 'vuex-pathify'
import SetupUnitForm from '@/components/setupForm/SetupUnitForm'
import DeleteSuccess from '@/components/snackbar/DeleteSuccess'
import Success from '@/components/snackbar/Success'
import DialogDeleteUnit from '@/components/dialog/DialogDeleteUnit'
export default {
  name: 'SetupUnit',
  components: {
    UnitTable,
    SetupUnitForm,
    DeleteSuccess,
    Success,
    DialogDeleteUnit

  },
  data: () => ({

  }),
  watch: {

  },
  created() {
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...sync('unit', ['dialogCreateForm', 'keyword', 'units', 'type', 'id']),
    ...sync('type', ['types']),
    ...sync('company', ['companies'])
  },

  methods: {
    ...call('unit', ['initUnit', 'getUnitList', 'getUnit']),
    ...call('company', ['getCompanyList']),
    ...call('unit', ['getUnitList']),
    createForm() {
      this.dialogCreateForm = true
      this.initUnit()
      this.id = null
    }
  }
  // eslint-disable-next-line vue/order-in-components

}
</script>
