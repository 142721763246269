import { make } from 'vuex-pathify'
import api from '@/api/unit'
const state = {
  units: [],
  search: null,
  dialogCreateForm: false,
  id: null,
  dialogDelete: false,
  unit: null,
  keyword: ''

}

const mutations = make.mutations(state)

const actions = {
  initUnit({ state }) {
    state.unit = {
      unitName: null
    }
  },
  getUnitList({ state }) {
    api.getUnits().then((response) => {
      state.units = response.data
    })
  },
  deleteUnit({ state }) {
    // api.deleteProduct(state.id)
    return new Promise((resolve) => {
      api.deleteUnit(state.id).then((response) => {
        resolve(response.data)
      }
      )
    })
  },
  saveUnit({ state }) {
    if (state.id == null) {
      return new Promise((resolve) => {
        api.postUnit(state.unit).then((response) => {
          resolve(response.data)
        })
      })
    } else {
      return new Promise((resolve) => {
        api.putUnit(state.id, state.unit).then((response) => {
          resolve(response.data)
        })
      })
    }
  },
  getUnit({ state }) {
    api.getUnit(state.id).then((response) => {
      state.unit = response.data
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
