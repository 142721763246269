<template>
  <v-data-table
    :headers="headers"
    :items="productItem.items"
    :loading="loading"
    item-key="id"
    class="elevation-1"
    :options.sync="options"
    :server-items-length="productItem.totalCount"
    :footer-props="{'items-per-page-options':[10,20, 30, 50, 100]}"
  >
    <template #item.isActive="{ item }">
      <v-chip
        v-if="item.isActive != 1"
        class="ma-2"
        outlined
      >
        ปิดใช้งงาน
      </v-chip>
      <v-chip
        v-if="item.isActive == 1"
        class="ma-2"
        color="green"
        outlined
      >
        เปิดใช้งงาน
      </v-chip>
    </template>
    <template #item.actions="{ item }">
      <v-icon

        class="mr-2"
        color="teal"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <!-- <v-icon

        color="red darken-4"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon> -->
    </template>
    <dialog-delete />
  </v-data-table>
</template>
<script>
import DialogDelete from '../dialog/DialogDelete'
import { sync, call } from 'vuex-pathify'
export default {
  name: 'ProductTable',
  components: {
    DialogDelete
  },
  data: () => ({
    options: {
      'page': 1,
      'itemsPerPage': 10,
      'sortBy': [],
      'sortDesc': [],
      'groupBy': [],
      'groupDesc': [],
      'mustSort': false,
      'multiSort': false
    },
    headers: [
      {
        text: 'ชื่อ',
        value: 'productName',
        sortable: true
      },
      {
        text: 'หน่วยนับ',
        value: 'unitName',
        sortable: false
      },
      {
        text: 'ราคา',
        value: 'price',
        width: '8rem',
        align: 'center'
      },
      {
        text: 'สถานะ',
        value: 'isActive',
        width: '8rem',
        align: 'center'
      },
      {
        text: 'Actions',
        width: '8rem',
        value: 'actions',
        align: 'center'
      }

    ]
  }),
  computed: {
    ...sync('product', ['productItem', 'keyword', 'dialogDelete', 'id', 'dialogCreateForm', 'loading'])
  },
  watch: {
    keyword(value) {
      if (value.length >= 3) {
        this.getProductFormApi()
      } else if (value.length === 0) {
        this.getProductFormApi()
      }
    },
    options: {
      handler() {
        this.getProductFormApi()
      },
      deep: true
    }
  },
  created() {
    this.getProductFormApi()
  },
  mounted() {

  },
  methods: {
    ...call('product', ['getProducts', 'getProduct']),
    ...call('company', ['getCompanyList']),
    ...call('unit', ['getUnitList']),
    deleteItem(item) {
      this.dialogDelete = true
      this.id = item.id
    },
    editItem(item) {
      this.dialogCreateForm = true
      this.id = item.id
      this.getProduct()
      this.getUnitList()
    },
    getProductFormApi() {
      const { page, itemsPerPage } = this.options
      const payload = {
        page: page,
        itemsPerPage: itemsPerPage
      }
      this.getProducts(payload).then((response) => {
        this.productItem = response
      })
    }
  }

}
</script>
