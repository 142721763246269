<template>
  <home />
</template>

<script>
import Home from '../components/Home'
import { sync } from 'vuex-pathify'

export default {
  name: 'Homes',

  components: {
    Home
  },
  computed: {
    ...sync('product', ['products', 'keyword'])
  },
  created() {
  },
  methods: {
  }
}
</script>
