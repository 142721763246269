<template>
  <v-card>
    <v-card-title>
      <div class="text-h5">
        จัดการวัสดุการแพทย์
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="grey"
          class="ma-2 dark--text"
          dark
          to="/setup"
          @click="onBack()"
        >
          <v-icon dark>
            mdi-arrow-left-bold
          </v-icon>
          กลับ
        </v-btn>
        <v-btn
          color="teal"
          class="ma-2"
          dark
          @click="createForm()"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          เพิ่ม
        </v-btn>
      </div>
    </v-card-title>
    <v-card-title>
      <v-text-field
        v-model="keyword"
        append-icon="mdi-magnify"
        label="ค้นหา"
        single-line
        hide-details
        color="teal"
      />
      <v-spacer />
      <v-col
        class="d-flex"

        sm="2"
      />
    </v-card-title>
    <product-table />
    <create-product-form />
    <dialog-delete />
    <success />
    <delete-success />

  </v-card>

</template>

<script>
import CreateProductForm from '../../components/product/CreateProductForm'
import ProductTable from '../../components/tables/ProductTable'
import DialogDelete from '../../components/dialog/DialogDelete'
import Success from '../../components/snackbar/Success'
import DeleteSuccess from '../../components/snackbar/DeleteSuccess'
import { sync, call } from 'vuex-pathify'
export default {
  name: 'ManageProduct',
  components: {
    ProductTable,
    DialogDelete,
    CreateProductForm,
    Success,
    DeleteSuccess
  },
  data: () => ({
    items: ['ทั้งหมด', '0', '1']
  }),
  watch: {
  },
  created() {
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...sync('product', ['dialogCreateForm', 'keyword', 'products', 'typeId', 'id', 'productList']),
    ...sync('company', ['companies'])
  },

  methods: {
    ...call('product', ['initProduct', 'getProducts', 'getProductList']),
    ...call('unit', ['getUnitList']),
    createForm() {
      this.id = 0
      this.dialogCreateForm = true
      this.getProductList(2)
      this.initProduct()
      this.getUnitList()
    },
    choseType() {
    },
    onBack() {
      this.keyword = ''
    }
  }
  // eslint-disable-next-line vue/order-in-components

}
</script>
